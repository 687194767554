.loader-overlay {
    top: 0;
    left: 0;
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 100%;
    background-color: var(--theme-clr-loaderoverlay-color);
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    border-radius: 10px 0 0 10px;
    display: flex;
    justify-content: center;
}

    .loader-overlay__positioner {
        height: calc(50% + 20px);
        max-height: 50vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }