.system-settings-reciprocity-fee-thresholds {
    display: flex;
    flex-direction: column;
    width: 100%
}

    .system-settings-reciprocity-fee-thresholds__header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        align-items: center;
        width: 100%;
    }

        .system-settings-reciprocity-fee-thresholds__header__filter {
            display: flex;
            width: 20%;
        }

    .region-section-content {
        margin-bottom: 10px;
    }

        .region-section-content__header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: var(--clr-background);
            padding: 5px 15px 5px 15px;
            border-radius: var(--radius-small);
            cursor: pointer;
            height: 100%;
            font-size: 16px;
            font-weight: bold;
            min-height: 34px;
        }

        .region-section-content.active .region-section-content__header {
            background-color: var(--clr-azami-blue-light);
        }

            .region-section-content__header img {
                margin-right: 5px;
            }

            .region-section-content__header__title {
                flex-grow: 1;
                font-size: 14px;
                font-weight: bold;
                text-transform: uppercase;
                color: var(--clr-azami-blurple);
            }
        
        .region-section-content table {
            padding: 15px 10px;
        }
    
            .region-section-content table td {
                padding: 5px 10px;
            }