.item-card__container {
    width: 100%;
    text-decoration: none;
    background-color: var(--clr-white);
    border: 1px solid var(--clr-midlightgrey);
    border-radius: var(--radius);
    box-shadow: var(--shadow-low);
    color: black;
    display: flex;
    flex-direction: row;
    height: 150px;
}
    .item-card__icon {
        margin-top: 15px;
        width: 122px;
        height: 122px;
    }

    .item-card__main {
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin: 10px 0 10px -10px;
        width: 100%;
        
    }
        .item-card__main-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 10px;
            padding: 10px 20px 0 10px;
            margin-top: 5px;
        }

        .item-card__separator::after {
            content: " ";
            display: block;
            border-bottom: 2px solid var(--clr-lightgrey);
            margin: -3px 12px 0 10px;
        } 
        
            .item-card__main-header__container {
                display: flex;
                align-items: center;
                gap: 8px;
            }

            .item-card__main-header__title {
                color: var(--clr-azami-blurple);
                font-weight: 600;
                font-size: 17px;
                display: inline-block;
                overflow: hidden;
                white-space: nowrap;
            }

            .item-card__main-header__amount {
                height: 24px;
                padding: 3px 7px 7px 7px;
                border-radius: 5px;
                text-align: center;
                color: var(--clr-white);
                font-size: 15px;
                background-color: var(--clr-azami-blurple);
            }

        .item-card__main-content {
            font-size: 14px;
            margin: 0 20px 0 8px;
            font-weight: 300;
            /* line-height: 25px; */
            /* white-space: wrap; */
        }