/* ****************************************************************************************** */
/* *********************************** Documents *************************************** */
/* ****************************************************************************************** */
.documents-header {
    padding: 15px 30px 0 30px;
}

    .documents-header__filters { 
        display: flex;
        gap: 15px;
    }

    .documents-header__actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .documents-main__table {
        margin-top: 15px;
    }
        .documents-main__table tbody tr td {
            vertical-align: baseline
        }

        .documents-main__table-file_size,
        .documents-main__table-file_category,
        .documents-main__table-file_attached_by {
            display: flex;
            flex-direction: column;
        }

        .documents-main__table-file_action {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }

        .documents-header__actions__title {
            font-weight: bold;
        }

        .documents-header__actions__buttons {
            display: flex;
            gap: 20px;
        }

        .documents-header__actions__buttons-upload {
            display: flex;
            flex-direction: column;
            align-items: end;
        }

            .documents-header__actions__buttons-upload-text {
                max-width: 365px;
                font-size: 13px;
                margin-top: 5px;
            }

        .documents-header__actions__upload-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            max-width: 150px;
        }

        .documents-header__actions__upload-btn img {
            margin-right: 8px;
        }

        .documents-main__table-file_action img {
            cursor: pointer;
        }

        .documents-main__table .table-cell img {
            margin-right: 20px;
        }

        .documents-main__table-file_name,
        .documents-main__table-file_size span,
        .documents-main__table-file_category span,
        .documents-main__table-file_attached_by span,
        .documents-main__table-file_action__actions-wrapper,
        .documents-main__table-file_description {
            margin-bottom: 15px;
        }

        .documents-main__table-file_action__actions-wrapper button {
           border: none;
           background: transparent;
           z-index: 100;
           cursor: pointer;
        }

        .documents-main__table-file_action__edit-btn {
            z-index: 1;
            position: relative;
        }
        .documents-main__table-file_action__edit-btn img {
            z-index: -1;
            position: relative;
        }

        .documents-main__table__info  {
            margin-bottom: 15px;
            cursor: pointer;
            position: relative
        }

        .documents-main__table .form-checkbox {
            margin: 0;
        }

        .info__tooltip {
            position: absolute;
            background-color: var(--clr-black);
            color: var(--clr-white);
            right: 50%;
            transform: translate(50%, -50%);
            display: flex;
            flex-direction: column;
            padding: 10px 15px;
            border-radius: 5px;
            bottom: 15%;
            z-index: 2;
            align-items: flex-start;
            width: max-content;
            display: none;
        }

        .info__tooltip::after {
            content: "";
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: black transparent transparent transparent;
        }

        .table-cell .documents-main__table__info  img {
            margin: 0;
        }

        .documents-main__table__info:hover .info__tooltip {
            display: block;
        }        

    /* ****************************************************************************************** */
    /* *********************************** Delete Modal *************************************** */
    /* ****************************************************************************************** */
    .confirmation-modal__body__confirm-delete {
        text-align: center;
    }

    .confirmation-modal__body__confirm-delete span {
        color: var(--clr-darkblue);
    }