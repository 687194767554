.confirmation-modal {
    min-width: 400px;
    background-color: var(--theme-modal-background-color);
    overflow: hidden;
    border-radius: 10px;
    box-shadow: var(--theme-clr-shadow-popup);

    .header {
        /* background-color: var(--theme-modal-header-color); */
        padding: 14px 16px;
        display: flex;
        align-items: center;

        img {
            margin-right: 14px;
        }

        h4 {
            color: var(--theme-modal-header-subheading-color);
        }

        button {
            margin-left: auto;
            background-color: transparent;
            outline: none;
            border: none;
            color: var(--theme-modal-header-button-color);
            font-size: 20px;
            cursor: pointer;
            width: 20px;
        }

        &.primary {
            background-color: var(--theme-clr-primary);
        }

        &.secondary {
            background-color: var(--theme-clr-secondary);
        }
    }

    .body {
        padding: 26px 16px;
        color: var(--theme-modal-body-color);
    }

    .footer {
        background-color: var(--theme-modal-footer-background-color);
        padding: 14px 16px;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        button {
            margin-left: 10px;
            
            &.bordered {
                font-size: 16px;
            }
        }
    }
}