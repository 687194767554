/* ****************************************************************************************** */
/* *********************************** Instruct Modal *************************************** */
/* ****************************************************************************************** */

.instruct__modal-content {
    width: 1000px;
    height: 400px;
}
    .instruct__modal-content__wizard {
        overflow: auto;
        height: 380px;
        padding: 10px;
    }

        .instruct__modal-content__header {
            width: 100%;
            background-color: var(--clr-azami-blue-glass);
            border-radius: 20px;
            display: flex;
            cursor: pointer;
            margin-bottom: 5px;
        }

        .instruct__modal-content__wizard-nav-pill {
            color: var(--clr-azami-blurple);
            border-radius: 20px;
            padding: 10px 15px;
            width: 50%;
            display: flex;
            justify-content: center;
        }

            .instruct__modal-content__wizard-nav-pill-text-wrapper {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                pointer-events: none;
            }

            .instruct__modal-content__wizard-nav-pill.current-active {
                font-weight: 500;
            }

            .instruct__modal-content__wizard-nav-pill-active {
                color: var(--clr-white);
                background-color: var(--clr-azami-blurple);
            }

            .instruct__modal-content__wizard-nav-pill-error-indicator {
                background-color: var(--clr-orange);
                height: 8px;
                width: 8px;
                min-width: 8px;
                border-radius: 50%;
                pointer-events: none;
                margin-left: 2px;
                visibility: hidden;
            }

            .instruct__modal-content__wizard-nav-pill-error-indicator.show-error {
                visibility: visible;
            }

            .instruct__modal-content__wizard-nav-pill-number {
                margin-right: 5px;
                background-color: var(--clr-white);
                border-radius: 50%;
                height: 18px;
                width: 18px;
                display: inline-flex;
                font-size: 14px;
                align-items: center; 
                justify-content: center;
                font-weight: 500;
            }

            .instruct__modal-content__wizard-nav-pill-number.active {
                background-color: var(--clr-azami-dark-blue);
                color: var(--clr-white);

            }

            .instruct__modal-content__wizard-nav-pill-number.current-active {
                background-color: var(--clr-white);
                color: var(--clr-azami-blurple);
            }


    .instruct__modal-content__regions {
        display: none;
    }

    .instruct__modal-content__client-ref {
        display: none;
    }

    .instruct__modal-content__regions.show-instructing-panels {
        display: block;
    }

    .instruct__modal-content__client-ref.show-instructing-panels {
        display: block;
    }

    .instruct__modal-content__client-ref-contact h4 {
        margin-bottom: 5p;
    }

    .instruct__modal-content__client-ref-contact-inputs {
        display: flex;
        padding: 5px;
        gap: 10px;
        flex-wrap: wrap;
    }

        .instruct__modal-content__client-ref-contact-inputs__input .form-input__label {
            text-transform: unset;
        }

    .instruct__modal-content__client-ref-contact-inputs div{
        width: 40%;
    }
    
    .instruct__modal-content__regions h4 {
        margin-bottom: 15px;
    }

        .instruct__modal-content__regions-grid {
            display: flex;
            flex-wrap: wrap;
            gap: 30px;
            
        }

        .instruct__modal-content__regions-grid__label .label {
            display: flex;
            align-items: center;
            width: fit-content;
        }

        .instruct__modal-content__regions-grid label {
            align-items: center;
            width: fit-content;
        }

        .instruct__modal-content__text-info {
            display: flex;
            gap: 15px;
            margin-top: 25px;
        }

        .instruct__modal-content__text-info textarea {
            width: 60%;
            height: 120px;
        }
    
    .instruct__modal-content__add-regions-link {
        color: var(--clr-azami-blue-hover);
        text-decoration: underline;
    }

        .instruct__modal-content__add-regions-link span{
            cursor: pointer;
        }