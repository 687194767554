.assignment-override-requests-main {
    padding: 15px 30px 0 30px;
    width: 100%;
}

.assignment-override-requests__header {
    margin: 10px 0 15px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

    .assignment-override-requests__header__title {
        font-size: 16px;
        font-weight: 500;
        color: var(--clr-darkgrey);
    }

.assignment-override-requests__sidebar {
    width: 300px;
    padding: 0 0 115px 15px;
    height: 100%;
    /* position: fixed; */
    overflow-y: auto;
}

    .assignment-override-requests__sidebar__header {
        color: var(--clr-midgrey);
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 500;
        margin-bottom: 15px;
    }

    .assignment-override-requests__sidebar__header img {
        margin-right: 5px;
    }

    .assignment-override-requests__sidebar .form-checkbox {
        border-bottom: 1px solid var(--clr-midlightgrey);
        padding-bottom: 8px;
    }

    .assignment-override-requests__sidebar .form-tag-select__tag-list:empty {
        margin: 0 0 10px;
    }

    .assignment-override-requests__sidebar .form-tag-select__tag-list {
        margin: 0 0 10px;
    }

    .assignment-override-requests__sidebar_actions {
        padding-top: 25px;
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        border-top: 1px solid var(--clr-midgrey);
    }

.assignment-override-requests__body {
    margin: 0 0 30px;
}

    .assignment-override-requests__body .form-select,
    .assignment-override-requests__body .form-input,
    .assignment-override-requests__body .form-switch {
        display: inline-flex;
        width: 46%;
        margin: 10px 4% 10px 0;
    }

    .assignment-override-requests__body .form-select:first-child {
        margin-right: 54%;
    }

    .assignment-override-requests__body .form-textarea {
        margin: 10px 0;
        padding: 0 4% 0 0;
    }

    .assignment-override-requests__body .form-select__label,
    .assignment-override-requests__body .form-input__label,
    .assignment-override-requests__body .form-textarea__label {
        text-transform: inherit;
    }

    .table-cell-priority .priority-item.priority-weight-1 {
        background-color: var(--clr-priority-1);
    }

    .table-cell-priority .priority-item.priority-weight-2 {
        background-color: var(--clr-priority-2);
    }

    .table-cell-priority .priority-item.priority-weight-3 {
        background-color: var(--clr-priority-3);
    }

    .table-cell-priority .priority-item.priority-weight-4 {
        background-color: var(--clr-priority-4);
    }

    .table-cell-priority .priority-item.priority-weight-5 {
        background-color: var(--clr-priority-5);
    }

    ul li.requested_cases {
        background-color: var(--theme-clr-base-mid5);
    }

    ul li.approved_cases {
        background-color: var(--theme-clr-base-mid4);
    }
    
    ul li.assigned_cases {
        background-color: var(--theme-clr-base-mid3);
    }
    
    ul li.remaining_cases {
        background-color: var(--theme-clr-base-mid2);
    }

    .assignment-override-requests__body-comments {
        display: flex;
        gap: 10px;
    }
 
.assignment-override-requests__footer {
    width: 100%;
    height: 55px;
    background-color: var(--clr-grey);
    border-radius: 0 0 0 var(--radius);
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
}    
 
    .assignment-override-requests__footer .form-button {
        margin: 0 0 0 10px;
    }