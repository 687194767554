.auth-template-ipeer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
}
    .auth-template-ipeer__welcome-container {
        width: 40%;
        background-image: url(../../../assets/images/ipeer_auth_background.svg);
        background-repeat: no-repeat;
        background-color: transparent;
        background-size: cover;
        background-position: center;
        padding: 10px 70px;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

        .auth-template-ipeer__welcome-container-logo {
            width: 100px;
        }

        .auth-template-ipeer__welcome-container-header {
            color: var(--clr-white);
            font-size: 50px;
            font-weight: 400;
            margin: auto 0 20px 0;
        }
        
        .auth-template-ipeer__welcome-container p {
            width: 90%;
        }
        .auth-template-ipeer__welcome-container-message {
            color: var(--clr-white);
            font-size: 18px;
            line-height: 22px;
            font-weight: 300;
            margin: 0 0 auto 0;
        }

.auth-template-ipeer__content {
    width: 60%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x: hidden;
    overflow-y: auto;
  
}
    
    .auth-template-ipeer__content .customer-support {
        position: absolute;
        top: 25px;
        right: 35px;
    }


        

 
