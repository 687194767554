.system-details-content__body {
    flex-grow: 1;
    width: 100%;
    padding: 0 30px 15px 30px;
    display: flex;
}

.system-settings-pricing-levels-main {
    padding: 15px 30px 0 0;
    width: 100%
}

.system-settings-pricing-levels__header {
    padding: 15px 0 0;
    width: 100%;
}

    .system-settings-pricing-levels__header__title {
        font-size: 16px;
        font-weight: 500;
        color: var(--clr-darkgrey);
    }

    .system-settings-pricing-levels__header_actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .system-settings-pricing-levels-main-edit-btn {
        cursor: default;
        background-image: url(../../../assets/images/icons/ico_btn_edit_lightgrey.svg);
        background-repeat: no-repeat;
        background-color: transparent;
        border: none;
        border-radius: 0;
        width: 19px;
        height: 17px;
        display: inline-block;
        cursor: pointer;
        margin-left: 10px
    }
    .system-settings-pricing-levels-main-delete-btn {
        cursor: default;
        background-image: url(../../../assets/images/icons/ico_btn_delete_lightgrey.svg);
        background-repeat: no-repeat;
        background-color: transparent;
        border: none;
        border-radius: 0;
        width: 19px;
        height: 17px;
        display: inline-block;
        cursor: pointer;
        margin-left: 10px
    }

    .system-settings-pricing-levels__body {
        margin: 0 0 30px;
    }

    .system-settings-pricing-levels__body form {
    }

    .system-settings-pricing-levels__body .form-checkbox {
        margin-top: 10px;
    }

    .system-settings-pricing-levels__body .form-textarea {
        margin: 10px 0;
        padding: 0 4% 0 0;
    }

    .system-settings-pricing-levels__body .form-select__label,
    .system-settings-pricing-levels__body .form-input__label,
    .system-settings-pricing-levels__body .form-textarea__label {
        text-transform: inherit;
    }

  
.system-details-content__footer {
    width: 100%;
    height: 55px;
    background-color: var(--clr-grey);
    border-radius: 0 0 0 var(--radius);
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
}

    .system-details-content__footer .form-button{
        margin: 0 0 0 10px
    }