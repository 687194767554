.login-container {
    width: 350px;
    height: 100%;
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.login-container__actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.login-container__actions a {
    display: inline-block;
    margin-right: auto;
    color: var(--clr-midgrey);
    font-size: 14px;
}

.login-container__actions a:hover {
    text-decoration: none;
}

/*************** AZAMI ***************/
.login-container-azami__header {
    font-size: 27px;
    margin-bottom: 20px;
    text-align: left;
    width: 100%;
}

    .login-container-azami__header-underline {
        display: inline-block;
        border-bottom: 4px solid var(--clr-azami-violet);
    }

    .login-container-azami__header-platform {
        color: var(--clr-azami-blurple);
        font-weight: 700;
    }

    .login-container-azami__description {
        font-size: 14px;
        color: var(--clr-darkgrey);
    }

.login-footer-azami {
    display: block;
    width: 100%;
    background-color: var(--clr-lightgrey);
    margin-top: 20px;
    border-radius: var(--radius);
    border: 1px solid rgba(0, 0, 0, .15);
    padding: 15px 20px;
    display: flex;
    text-decoration: none;
    align-items: center;
    transition: all .3s ease-in-out;
}
    
    .login-footer-azami:hover {
        box-shadow: var(--shadow);
    }
    
    .login-footer-azami__message {
        display: flex;
        flex-direction: column;
        padding-left: 10px;
        text-decoration: none;
    }
    
    .login-footer-azami__message__top {
        color: var(--clr-midgrey);
        text-decoration: none;
        font-size: 14px;
        font-weight: 500;
    }
    
    .login-footer-azami__message__bottom {
        color: var(--clr-midgrey);
        text-decoration: none;
        font-size: 14px;
    }

/*************** IPEER ***************/
.login-container-ipeer__header {
    width: 100%;
    text-align: left;
}

    .login-container-ipeer__header span {
        font-size: 20px;
        font-weight: 600;
        color: var(--clr-darkgrey);
    }

    .login-footer-ipeer {
        display: block;
        width: 100%;
        background-color: var(--clr-ipeer-blue);
        margin-top: 20px;
        border-radius: var(--radius);
        border: 1px solid rgba(0, 0, 0, .15);
        padding: 15px 20px;
        display: flex;
        text-decoration: none;
        align-items: center;
        transition: all .3s ease-in-out;
    }
        
        .login-footer-ipeer:hover {
            box-shadow: var(--shadow);
        }
        
        .login-footer-ipeer__message {
            display: flex;
            flex-direction: column;
            padding-left: 10px;
            text-decoration: none;
        }
        
        .login-footer-ipeer__message__top {
            color: var(--clr-white);
            text-decoration: none;
            font-size: 14px;
            font-weight: 500;
        }
        
        .login-footer-ipeer__message__bottom {
            color: var(--clr-white);
            text-decoration: none;
            font-size: 14px;
        }