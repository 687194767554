
.customize-rule-modal__body {
    max-height: 550px;
    display: flex;
    overflow-y: auto;
    gap: 20px;
}

    .customize-rule-modal__body-rules-list {
        font-weight: 500;
        padding-right: 20px;
        max-width: 400px;
        overflow-y: auto;
        border-right: 1px solid #bebebe;
    }

    .customize-rule-modal__body-rules-list__rule-name {
        display: inline-block;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 250px;
    }

    .customize-rule-modal__body-rules-list__header {
        padding: 0 0 30px;
    }

    .customize-rule-modal__body-rules-list-search-input .form-input__label,
    .customize-rule-modal__body-rules-list-search-input .form-select__label {
        margin-left: 0;
        text-transform: capitalize;
        font-size: 14px;
    }

    .customize-rule-modal__body-rules-list-search-input .select-filters {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    .customize-rule-modal__body-rules-list-search-input .select-filters .form-select {
        width: 47%;
    }


.customize-rule-modal__body-rule-content {
    padding-right: 20px;
    width: 850px;
    overflow-y: auto;
}

.customize-rule-modal__body-rule-content-notes-container {
    display: flex;
    gap: 20px;
}

.customize-rule-modal__body-rule-content-notes-container.rule-content-notes-container-edit {
    flex-direction: column-reverse;
}

    .customize-rule-modal__body-rule-content__info {
        display: flex;
        gap: 40px;
    }

        .customize-rule-modal__body-rule-content__info-item {
            display: flex;
            flex-direction: column;
            gap: 4px;
        }

            .customize-rule-modal__body-rule-content__info-item span {
                font-size: 12px;
            }

            .customize-rule-modal__body-rule-content__info-item .title {
                font-weight: 500;
                text-transform: uppercase;
                color: var(--clr-darkgrey);
            }

            .customize-rule-modal__body-rule-content__info-item .last-edited-date {
                color: var(--clr-midgrey);
                font-size: 10px;
            }

    .customize-rule-modal__body-rule-content-notes {
        width: 550px;
        height: 100px;
        padding: 5px;
        margin-bottom: 5px;
    }

    .customize-rule-modal__body-rule-content-notes textarea {
        height: 100%;
    }

    .customize-rule-modal__body-rule-options-header {
        width: 100%;
        background-color: var(--clr-azami-light-blurple);
        color: var(--clr-azami-blurple);
        height: 34px;
        display: flex;
        align-items: center;
        border-radius: var(--radius-small);
        padding: 0 15px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
    }

    .customize-rule-modal__body-rule-options-collapsable {
        margin-bottom: 15px;
    }

        .customize-rule-modal__body-rule-options-collapsable-tabs {
            width: 100%;
            background-color: var(--clr-background);
            display: flex;
            align-items: center;
            border-radius: var(--radius-small);
            overflow: hidden;
        }

            .customize-rule-modal__body-rule-options-collapsable-tabs-content {
                padding-top: 20px;
                padding-bottom: 60px;
            }

            .customize-rule-modal__body-tab-content-container__line {
                display: flex;
                gap: 20px;
                width: 100%;
                align-items: flex-end;
            }
            
                .customize-rule-modal__body-tab-content-container__line .option-dd {
                    width: 250px;
                }
            
                .customize-rule-modal__body-tab-content-container__line .option-btn {
                    width: 130px;
                    height: 37px;
                    margin-left: auto;
                    margin-bottom: 15px;
                }
            
                .customize-rule-modal__body-tab-content-container__line .line-text {
                    margin-bottom: 25px;
                    font-size: 14px;
                }
            
                .customize-rule-modal__body-tab-content-container__line .option-sw {
                    margin-bottom: 23px;
                }
    
            .customize-rule-modal__body-rule-options-collapsable-tabs li {
                color: var(--clr-azami-blurple);
                font-size: 14px;
                font-weight: 500;
                width: 100%;
                text-align: center;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }
    
            .customize-rule-modal__body-rule-options-collapsable-tabs li.active {
                background-color: var(--clr-azami-light-blurple);
            }

    .customize-rule-modal__body-rule-options-body {
        margin-top: 15px;
    }
        .customize-rule-modal__body-rule-options-body .text-input {
            width: 265px;
        }

        .customize-rule-modal__body-rule-options-body-subtitle {
            font-size: 14px;
            font-weight: 500;
            padding: 5px;
        }

        .customize-rule-modal__body-rule-options-body-section {
            display: flex;
            justify-content: flex-start;
            gap: 20px;
            padding: 5px;
        }

            .customize-rule-modal__body-rule-options-body-section .fees-radio {
                width: 150px;
            }

        .customize-rule-modal__body-rule-options-body .switch-list {
            margin: 15px 0;
        }

        .customize-rule-modal__body-rule-options-body blockquote {
            padding-left: 15px;
        }

.customize-rule-modal-levels-input-list {
    width: 250px;
}

.customize-rule-modal-levels-input-list  li {
    display: flex;
    align-items: center;
}

    .customize-rule-modal-levels-input-list  .customize-rule-modal-levels-input-list__label {
        color: var(--clr-blue);
        display: block;
        width: 240px;
    }
    
    .customize-rule-modal-levels-input-list input {
        width: 110px;
    }

    .customize-rule-modal-levels-input-list .form-input {
        width: auto;
    }

    .customize-rule-modal-levels-input-list__separator {
        display: block;
        width: 30px;
        text-align: center;
    }

    .customize-rule-modal-levels-input-list__range.error .error-label {
        display: none;
    }

    .customize-rule-modal-levels-input-list__delete-button {
        background-image: url(../../../assets/images/icons/ico_btn_delete_darkblue.svg);
        background-repeat: no-repeat;
        background-color: transparent;
        border: none;
        width: 19px;
        height: 17px;
        display: inline-block;
        cursor: pointer;
        margin-left: auto;
        margin-bottom: 18px;
    }

.customize-rule-modal__body-tab-content-container__line {
    display: flex;
    gap: 20px;
    width: 100%;
    align-items: flex-end;
}

.customize-rule-modal__body-tab-content-container__line .option-dd {
    width: 250px;
}

.customize-rule-modal__body-tab-content-container__line .option-btn {
    width: 130px;
    height: 37px;
    margin-left: auto;
    margin-bottom: 15px;
}

.customize-rule-modal__body-tab-content-container__line .line-text {
    margin-bottom: 25px;
    font-size: 14px;
}

.customize-rule-modal__body-tab-content-container__line .option-sw {
    margin-bottom: 23px;
}

.customize-rule-modal__body-tab-content-text {
    font-size: 16px;
    font-weight: 500;
    color: var(--clr-darkgrey);
    margin-bottom: 20px;
}

.customize-rule-modal-input-ranges {
    display: flex;
    gap: 40px;
    width: 100%;
    flex-wrap: wrap;
}

.customize-rule-modal__body-rules-list__selected,
.customize-rule-modal__body-rules-list__selected:hover {
    background-color: var(--clr-azami-light-blurple);
}

.customize-rule-modal__body-rules-list::-webkit-scrollbar,
.customize-rule-modal__body-rule-content::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: transparent;
}

/* Add a thumb */
.customize-rule-modal__body-rules-list::-webkit-scrollbar-thumb,
.customize-rule-modal__body-rule-content::-webkit-scrollbar-thumb {
    background: var(--clr-midgrey);
    border-radius: 5px;
    width: 10px;
}

    .customize-rule-modal__body-tab-content-container__line .option-dd.no-label-input span.label {
        margin-top: 14px;
    }