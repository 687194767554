.modal-backdrop {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--theme-clr-backdrop-mid);
    display: flex;
    align-items: center;
    justify-content: center;
    /* backdrop-filter: blur(2px); */
}

.modal-transparent {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 40px;
    backdrop-filter: blur(2px);
}