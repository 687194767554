.estimator-rules-preview  {
    width: 0;
    position: fixed;
    z-index: 2;
    height: 89%;
    top: 70px;
    right: 0;
    background-color: var(--clr-white);
    overflow-x: hidden;
    transition: 0.5s;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    box-shadow: var(--shadow-low);
}

.estimator-rules-preview__open  {
    width: 450px;
    max-width: 450px;
    z-index: 3;
}

.estimator-rules-preview__header {
    position: sticky;
    top: 0;
    width: 100%;
    background-color: var(--clr-lightgrey);
    padding: 1px;
    /* padding: 14px 16px; */
    padding: 20px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    font-size: 16px;
    font-weight: 600;
    color: var(--clr-darkblue);
}
    .estimator-rules-preview__header__close{
        cursor: pointer;
    }

.estimator-rules-preview__footer {
    position: sticky;
    bottom: 0;
    background-color: var(--clr-lightgrey);
    padding: 14px 16px;
    width: 100%;
    display: flex;
    align-items: center;
}
    .estimator-rules-preview__footer button {
        margin-left: 10px;
    }

.estimator-rules-preview__container{
    overflow-y: auto;
    height: 100%;
}

.estimator-rules-preview__container__content-first-row {
    display: flex;
    flex-direction: row;
    padding: 15px;
    justify-content: space-between;
    gap: 15px;
}
    .estimator-rules-preview__container__content-first-row-box {
        display: flex;
        flex-direction: column;
        /* width: 200px; */
        background-color: var(--clr-lightgrey);
        border-radius: var(--radius);
        padding: 10px 15px;
        /* margin-left: 15px; */
        flex-grow: 1;
    }
        .estimator-rules-preview__container__content-first-row-box span {
            font-size: 14px;
            color: var(--clr-darkblue);
            padding: 10px 0;
            font-weight: 600;
        }
        
        .estimator-rules-preview__container__content-first-row-box-value {
            font-weight: 400 !important;
            color: var(--clr-darkgrey) !important;
        }

.estimator-rules-preview__container__content-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 15px;
    justify-content: flex-start;
    border-bottom: 1.5px solid var(--clr-lightgrey);
}  
    .estimator-rules-preview__container__content-row-left {
        font-size: 14px;
        color: var(--clr-darkblue);
        padding: 10px 0;
        font-weight: 600;
        min-width: 100px;
        margin-left: 15px;
    }
    .estimator-rules-preview__container__content-row-right {
        font-weight: 400;
        color: var(--clr-darkgrey);
        padding: 10px 0;
        font-size: 14px;
    }
        .estimator-rules-preview__container__content-row-right.last-edited-by-field {
            display: flex;
            flex-direction: column;
        }

        .estimator-rules-preview__container__content-row-right.last-edited-by-field .last-edited-by-field-date {
            font-size: 12px;
            color: var(--clr-darkgrey);
        }
    .estimator-rules-preview__container__content-row.rule-options {
        border: none;
    } 
    .estimator-rules-preview__container__content-row.rule-options:last-child {
        border-bottom: 1px solid var(--clr-lightgrey);
    }
    .estimator-rules-preview__container__content-row.by-amount-pricing-range {
        border: none;
    }

.estimator-rules-preview__container__content-column {
    display: flex;
    flex-direction: column;
    padding: 5px 15px;
    justify-content: flex-start;
    border-bottom: 1px solid var(--clr-lightgrey);
}  
    .estimator-rules-preview__container__content-column-top {
        font-size: 14px;
        color: var(--clr-darkblue);
        padding: 10px 0;
        font-weight: 600;
        min-width: 100px;
        margin-left: 15px;
    }
    .estimator-rules-preview__container__content-column-bottom {
        font-weight: 400;
        color: var(--clr-darkgrey);
        padding: 10px 0;
        margin-left: 15px;
        font-size: 14px;
    }
.estimator-rules-preview__container__content-row-region-wrapper {
    display: flex;
    /* flex-direction: row; */
    width: 100%;
    align-items: center;
    padding-left: 15px;
    height: 40px;
}
    .estimator-rules-preview__container__content-row-region-wrapper-flag {
        /* width: 40px; */
        /* height: 32px; */
    }
    .estimator-rules-preview__container__content-row-region-wrapper-flag img {
        /* width: 100%; */
        /* height: 100%; */
        max-width: 30px;
    }
    .estimator-rules-preview__container__content-row-region-wrapper-text {
        /* padding: 5px; */
        margin-left: 10px;
        font-size: 14px;
    }

.estimator-rules-preview__container__content-by-amount-range-pricing-level {
    color: var(--clr-midblue);
    font-weight: 600;
}

