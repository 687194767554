.form-switch {
    cursor: pointer;
    display: flex;
    align-items: center;

    &.disabled {
        opacity: .5;
        cursor: not-allowed;
    }

    & > input {
        display: none;
    }

    & img {
        width: 25px;
        margin-right: 10px;
    }

    span.label {
        display: flex;
        align-items: center;
        font-size: 13px;
        font-weight: 500;
        margin-left: 10px;
        color: var(--theme-clr-base-dark);
    }

    span.pill {
        display: block;
        width: 38px;
        height: 20px;
        border-radius: 10px;
        position: relative;
        transition: all .2s ease-in-out;
        background-color: var(--theme-clr-base-mid2);

        span.ball {
            display: block;
            background-color: var(--theme-clr-base-light);
            width: 16px;
            height: 16px;
            border-radius: 8px;
            position: absolute;
            top: 2px;
            left: 2px;
            transition: all .2s ease-in-out;
        }
    }

    input:checked + .pill .ball {
        left: 20px;
    }

    &.primary input:checked + .pill {
        background-color: var(--theme-clr-primary);
    }

    &.secondary input:checked + .pill {
        background-color: var(--theme-clr-secondary);
    }

}
    






