.change-password-container {
    width: 350px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
}

.form-checkbox__label {
    white-space: inherit;
}

/*************** AZAMI ****************/
.change-password-container-azami__actions {
    display: flex;
    justify-content: flex-end;
}

.change-password-container-azami__text {
    color: var(--clr-darkgrey);
    font-size: 16px;
    display: block;
    margin-bottom: 20px;
}

/*************** IPEER ****************/
.change-password-container-ipeer__actions {
    display: flex;
    justify-content: flex-end;
}

.change-password-container-ipeer__text {
    color: var(--clr-darkgrey);
    font-size: 16px;
    display: block;
    margin-bottom: 20px;
}

.change-password-container__checkbox .checkmark {
    line-height: 1;
}