.form-editor {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    position: relative;

    span.label {
        margin-bottom: 8px;
        margin-left: 5px;
        font-size: 12px;
        font-weight: 500;
        text-transform: capitalize;
    }

    .input {
        & p {
            color: inherit !important;
        }
        
        & .ql-toolbar.ql-snow {
            border-width: 1px;
            border-style: solid;
            border-top-left-radius: var(--theme-border-radius-small);
            border-top-right-radius: var(--theme-border-radius-small);
            border-color: var(--clr-midgrey);
        }
        
        & .ql-container.ql-snow {
            height: 200px;
            border-width: 1px;
            border-style: solid;
            border-bottom-left-radius: var(--theme-border-radius-small);
            border-bottom-right-radius: var(--theme-border-radius-small);
            border-color: var(--theme-clr-base-mid1);
        }
    }
}