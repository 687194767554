.form-textarea {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    position: relative;

    span.label {
        margin-bottom: 8px;
        margin-left: 5px;
        font-size: 13px;
        font-weight: 500;
        text-transform: capitalize;
        display: flex;
        align-items: center;
        color: var(--theme-clr-base-dark);        
    }

    .input {
        width: 100%;
        border-width: 1px;
        border-style: solid;
        border-radius: var(--theme-input-border-radius);
        padding: 8px;
        outline: none;
        transition: all .3s ease-in-out;
    
        &:disabled {
            opacity: .5;
            cursor: not-allowed;
        }
    
        &.primary {
            border-color: var(--theme-input-border-color);

            &:focus {
                border-color: var(--theme-clr-primary);
                box-shadow: var(--theme-clr-shadow-primary);
            }
        }
    
        &.secondary {
            border-color: var(--theme-input-border-color);

            &:focus {
                border-color: var(--theme-clr-secondary);
                box-shadow: var(--theme-clr-shadow-secondary);
            }
        }

        &.dark {
            background-color: var(--theme-input-dark-background-color);
            border-color: transparent;

            &:focus {
                border-color: var(--theme-input-dark-border-color);
                box-shadow: var(--theme-clr-shadow-inner);
            }
        }
    }

    &.error {

        span.error-label {
            font-size: 12px;
            color: var(--theme-clr-palette-red);
            text-align: right;
            display: block;
            position: absolute;
            top: 0;
            right: 5px;
        }
        
        .input {
            border-color: var(--theme-clr-palette-red) !important;
            box-shadow: var(--theme-clr-shadow-error);
        }
    }
    
}


