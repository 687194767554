.attachments__upload-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 30px;
    margin: 25px 0 15px 0;
}

        .attachments__upload-actions label {
            background-color: var(--clr-azami-blue);
            color: var(--clr-white);
            border-radius: var(--radius-small);
            cursor: pointer;
            padding: 8px 20px;
        }

        .attachments__upload-actions input[type='file'] {
            display: none;
        }

        .attachments__upload-actions img {
            margin-right: 8px;
        }
        
        .attachments__upload-actions-btn {
            display: flex;
            flex-direction: column;
            align-items: end;
        }

            .attachments__upload-actions-btn-text {
                max-width: 365px;
                font-size: 13px;
                margin-top: 5px;
            }

    .attachments__table {
        margin-bottom: 15px;
    }

        .attachments__table__file-name img {
            margin-right: 8px;
        }

        .attachments__table__action-delete {
            border: none;
            background: transparent;
            cursor: pointer;
            margin-left: 8px;
        }

    .duplicates__modal__content {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
        .duplicates__modal__content span {
            margin-bottom: 5px;
        }