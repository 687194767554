.associate-assignment-sidebar {
    width: 300px;
    padding: 0 0 115px 15px;
    height: 100%;
    /* position: fixed; */
    overflow-y: auto;
}

.associate-assignment-sidebar__header {
    color: var(--clr-midgrey);
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 15px;
}

    .associate-assignment-sidebar__header img {
        margin-right: 5px;
    }

.associate-assignment-sidebar_actions {
    padding-top: 25px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid var(--clr-midgrey);
}

.associate-assignment-sidebar .form-tag-select__tag-list:empty {
    margin: 0 0 10px;
}

.associate-assignment-sidebar .form-tag-select__tag-list {
    margin: 0 0 10px;
}

.associate-assigment-table__checkbox {
    margin: 0;
}

.associate-assigment-table__approve-btn {
    margin-right: 8px;
    align-self: center;
}

.associate-assigment-table__approve-btn,
.associate-assigment-table__manual-select-btn {
    min-width: 150px;
    align-self: center;
}

.associate-assigment-table__pending-approval-label {
    background-color: var(--clr-orange);
    padding: 6px 16px;
    min-width: 100px;
    border-radius: var(--radius-small);
    color: var(--clr-white);
    font-weight: bold;
}

.assign-associate {
    background-color: var(--clr-white);
    overflow: hidden;
    border-radius: 10px;
    box-shadow: var(--shadow);
}

    .assign-associate__body {
        padding: 0 10px 0 10px;
        color: var(--clr-darkgrey);
        max-height: 400px;
        overflow-y: auto;
        font-size: 14px;
        min-width: 900px;
        position: relative;
    }

        .assign-associate__body__intro div {
            display: flex;
            gap: 20px;
        }

            .assign-associate__body__intro-select {
                max-width: 350px;
            }

        .assign-associate__body__applicant-name,
        .assign-associate__body__firm_size,
        .assign-associate__body__technologies
         {
            margin-bottom: 15px;
        }

        .assign-associate__body__technologies {
            display: flex;
        }

        .assign-associate__body__technologies__list-wrapper {
            display: flex;
            width: 50%;
            flex-wrap: wrap;
            margin-left: 10px;
            gap: 5px;
        }

        .assign-associate__body__applicant-name,
        .assign-associate__body__applicant-name span {
            display: flex;
            align-items: center;
        }

        .assign-associate__body__applicant-name img,
        .assign-associate__body__firm_size span {
            margin: 0 10px;
        }

        .assign-associate__body__technologies span {
            background-color: var(--clr-azami-blurple);
            color: var(--clr-white);
            padding: 5px 8px;
            border-radius: 15px;
        }

        .assign-associate__body h5{
            display: inline-block;
            color: var(--clr-darkgrey);
            font-size: 16px;
        }

        .suggested-associates {
            margin: 15px 0 40px;
            width: 100%;
        }

        .suggested-associates__row {
            padding: 10px 0;
            border-bottom: 1px solid var(--clr-midlightgrey);
        }

        .suggested-associates__row-intro {
            display: inline-flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
        }
        
        .suggested-associates__row-intro__associate-wrapper {
            width: 25%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }

        .suggested-associates__row-intro__toggle {
            width: 10px;
            height: 20px;
            cursor: pointer;
            padding: 5px;
            background-image: url(../../../assets/images/icons/ico_sort_down.svg);
            background-repeat: no-repeat;
            background-position: 20% 50%;
        }

        details[open] .suggested-associates__row-intro__toggle {
            background-image: url(../../../assets/images/icons/ico_sort_up.svg);
        }

        .suggested-associates__row-intro__avatar {
            display: block;
            height: 40px;
            background-color: var(--clr-lightgrey);
            border-radius: 50%;
            margin: 0 10px;
            object-fit: cover;
        }

        .suggested-associates__row-intro__avatar img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            object-fit: cover;
        }

        .suggested-associates__row-intro__teaser {
            margin: 5px 0 0 0;
            display: block;
        }

        .suggested-associates__row-intro__attorney-name {
            font-weight: bold;
            line-height: 20px;
        }

        .suggested-associates__row-intro__user-name {
            font-size: 12px;
        }

        .suggested-associates__row-intro__teaser__subheading {
            display: flex;
            flex-wrap: wrap;
            font-size: 11px;
            color: var(--clr-midgrey);
        }

            .suggested-associates__row-intro__points {
                width: 100%;
            }

            .suggested-associates__row-intro__separator {
                padding: 0 5px;
            }

        .suggested-associates__row-intro__region {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20%;
        }

        .suggested-associates__row-intro__region img {
            margin-right: 8px;
        }

        .suggested-associates__row-intro__calculator-points {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            color: var(--clr-midgreen);
            font-weight: bold;
            width: 10%;
        }

        .suggested-associates__row-intro__badges {
            width: 25%;
            text-align: center;
        }

        .suggested-associates__row-intro__badges img {
            margin: 0 7px 0 0;
        }

        .suggested-associates__row-intro__email {
            display: flex;
            align-items: center;
            width: 25%;
        }

        .suggested-associates__row-intro__email img {
            margin-right: 8px;
        }

        .suggested-associates__row-intro__email span {
            color: var(--clr-darkblue);
            border-bottom: 1px solid var(--clr-darkblue);
        }

        .suggested-associates__row-intro__approve {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 20%;
        }

            .suggested-associates__row-intro__approve-btn {
                min-width: 150px;
            }

        .suggested-associates__row-expanded {
            display: block;
            padding: 20px;
        }

        .suggested-associates__row-expanded .firm-info {
            display: flex;
            margin: 0 0 10px;
            width: 100%;
        }

        .suggested-associates__row-expanded__label {
            flex-basis: 20%;
            font-weight: bold;
        }

        .suggested-associates__row-expanded__value {
            flex-basis: 80%;
        }

        .suggested-associates__row-expanded__firm-techs ul li {
            background-color: var(--clr-azami-blurple);
            color: var(--clr-white);
            border-radius: 15px;
            display: inline-block;
            align-items: center;
            margin: 0 7px 7px 0;
            text-align: center;
            padding: 4px 12px;
        }

        .assign-associate__show-more {
            background-color: var(--clr-midlightgrey);
            color: var(--clr-midgrey);
            text-align: center;
            display: block;
            margin: 0 auto;
            position: relative;
            top: -40px;
            cursor: pointer;
            width: fit-content;
            font-size: 16px;
            text-transform: uppercase;
            font-weight: 600;
            padding: 4px 25px;
            border-radius: 0 0 8px 8px;
        }

.main-screen-section__approve-all-btn-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;
    background-color: var(--clr-azami-blue-glass);
    padding: 8px;
    border-radius: 5px;
}