@keyframes toast-in {
    0% {
        opacity: 0;
        margin-right: -200px;
    }
    100% {
        opacity: 1;
        margin-right: 10px;
    }
}

@keyframes toast-out {
    0% {
        margin-right: 10px;
        opacity: 1;
    }
    100% {
        margin-right: -200px;
        opacity: 0;
    }
}

.toast-notification {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    margin: 10px;
    border-radius: var(--theme-toast-border-radius);
    box-shadow: 0px 3px 15px rgba(0, 0, 0, .15);
    animation-name: toast-in;
    animation-duration: .3s;
    animation-fill-mode: forwards;
    opacity: 0;
    max-width: 400px;
    padding: 10px 15px;

    &.unmount {
        animation-name: toast-out;
        display: none;
    }

    &.info {
        background-color: var(--theme-toast-info-color);
    }

    &.error {
        background-color: var(--theme-toast-error-color);
    }

    &.warning {
        background-color: var(--theme-toast-warning-color);
    }

    &.success {
        background-color: var(--theme-toast-success-color);
    }

    .icon {
        width: 27px;
        height: 27px;
        margin-left: -7px;
    }

    .content {
        color: var(--theme-toast-content-color);
        font-size: 14px;
        text-align: left; 
    }

    .actions {
        display: flex;
        flex-direction: row;
        gap: 10px;

        button {
            background: transparent;
            border: none;
            cursor: pointer;
            color: var(--theme-toast-actions-color);
            height: 100%;
            font-size: 14px;
            font-weight: 400;
        }
    }

    .remove {
        background: transparent;
        border: none;
        cursor: pointer;
        color: var(--theme-toast-actions-color);
        font-size: 20px;
    }
}