.dashboard-container {
    padding: 0 80px 10px 80px;
    display: flex;
    flex-direction: column;
}

    .dashboard-header {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

        .dashboard-header__main {
            display: flex;
            gap: 10px;
            font-size: 24px;
        }
            .dashboard-header__main-user {
            }
                .dashboard-header__main-user__name {
                    color: var(--clr-azami-violet);
                    font-weight: bold;
                }

            .dashboard-header__main-omni-patent {
                color: var(--clr-azami-blurple);
            }

            .dashboard-card {
                max-width: calc(100% / 3 - 50px / 3);
                min-width: 400px;
                flex-grow: 1;
            }

        .dashboard-main {
            margin: 20px 0;
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
        }
        
        .dashboard-header__end {
            font-weight: 500;
        }

        .dashboard-card {
            width:610px;
        }

    .dashboard-main {
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
    }

    .dashboard-stats-cards {
        margin-top: 15px;
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        gap: 16px;
        margin-top: 16px;
        margin-right: 10px;
    }

        .dashboard-stats-cards__row {
            height: 68px;
        }

        .dashboard-stats-cards__row:hover {
            background-color: rgba(0, 0, 0, .015);
        }

        .dashboard-stats-cards__button {
            width: 100%;
            max-width: 200px;
            font-size: 16px;
        }

.ref_no {
    font-weight: bold;
}

.dashboard_cases_table__indicator {
    margin: 0 0 0 25px;
}

@media screen and (max-width: 1650px) {
    .dashboard-card {
        max-width: calc(100% / 2 - 50px / 3);
    }
}

@media screen and (max-width: 1200px) {
    .dashboard-card {
        max-width: calc(100% - 50px / 3);
    }
}