.form-ratio-input {
    width: 100%;
    border-width: 1px;
    border-style: solid;
    border-radius: var(--theme-input-border-radius);
    padding: 8px 8px 7px 8px;
    outline: none;
    transition: all .3s ease-in-out;
    display: flex;
    border-color: var(--theme-input-border-color);
    height: 37px;

        &:focus-within {
            border: 1px solid var(--theme-clr-secondary);
            box-shadow: var(--theme-clr-shadow-secondary);
        }

        & input {
            border: none;
            outline: none;
            display: flex;
            width: calc(50% - 8px);
            text-align: center;
            
                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }
        }

        & input:disabled {
            opacity: .5;
            box-shadow: none !important;  
        }

        & .separator {
            color: var(--theme-input-border-color);
            display: inline-block;
            text-align: center;
            width: 16px;        
        }
}
