.tools-list {
    display: flex;
    flex-wrap: wrap;
}

    .tools-list a {
        display: flex;
        background-color: var(--clr-white);
        margin: 10px 20px 10px 0;
        padding: 20px;
        border-radius: var(--radius-small);
        box-shadow: var(--shadow);
        width: 318px;
        text-decoration: none;
        min-height: 100px;
        align-items: flex-start;
    }

.tool-list__icon {
    width: 50px;
    margin-right: 10px;
}

.tool-list__text-box {
    display: flex;
    flex-direction: column;
}

.tool-list__tool-title {
    color: var(--clr-darkblue);
    display: block;
    width: 100%;
    font-weight: 600;
}

.tool-list__tool-description {
    font-size: 14px;
    display: block;
    color: var(--clr-midgrey);
    margin-top: 10px;
}

.tools-details-content {
    display: flex;
    flex-direction: column;
}

    .tools-details-content__header {
        width: 100%;
        padding: 15px;
    }

        .tools-details-content__header nav {
            width: 100%;
            background-color: var(--clr-azami-blue-glass);
            border-radius: var(--radius-small);
            padding: 5px 7px;
            display: flex;
            gap: 5px;
        }

            .tools-details-content__header nav a {
                color: var(--clr-azami-blue);
                text-decoration: none;
                font-size: 14px;
                display: inline-block;
                padding: 5px 8px;
                background-color: transparent;
                border-radius: var(--radius-small);
                text-transform: capitalize;
                transition: all .15s ease-in-out;
                position: relative;
            }

            .tools-details-content__header nav a.active {
                background-color: var(--clr-azami-blue);
                color: var(--clr-lightblue);
            }

            .tools-details-content__header nav a:hover {
                background-color: rgba(255, 255, 255, .5);
            }

            .tools-details-content__header nav a.active:hover {
                background-color: var(--clr-darkblue);
                color: var(--clr-lightblue);
            }

                .tools-details-content__header__update-indicator {
                    display: block;
                    position: absolute;
                    background-color: var(--clr-orange);
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    top: -2px;
                    right: -2px;
                }

    .tools-details-content__body {
        flex-grow: 1;
        width: 100%;
        padding: 0 30px 15px 30px;
        display: flex;
        height: calc(100vh - 280px);
    }

        .tools-details-content__body__title {
            font-size: 16px;
            font-weight: 500;
            color: var(--clr-darkgrey);
            margin-bottom: 20px;
        }

    .tools-details-content__footer {
        width: 100%;
        height: 55px;
        background-color: var(--clr-grey);
        border-radius: 0 0 0 var(--radius);
        padding: 0 15px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-direction: row;
    }
    
        .tools-details-content__footer button {
            bottom: 10px;
            right: 30px;
        }