.auth-box {
    width: 100%;
}

.auth-box__header {
    width: 100%;
    display: flex;
}

.auth-box__header__icon {
    width: 26px;
    height: 20px;
    object-fit: contain;
}

.auth-box__header__title {
    font-size: 26px;
    font-weight: 500;
}

.auth-box__content {
    background: var(--clr-white);
    box-shadow: var(--shadow);
    margin-top: 20px;
    padding: 30px;
    color: var(--clr-darkgrey);
    line-height: 22px;
    width: 100%;
}

.auth-box__header__title.azami-blurple {
    color: var(--clr-azami-blurple);
}

.auth-box__header__title.dark-grey {
    color: var(--clr-darkgrey);
}