.base-pricing-value .form-input__input.light {
    background-color: var(--clr-azami-blue-light);
    color: var(--clr-azami-blurple);
    border-color: var(--clr-azami-blurple);
    font-weight: bold;
}

.calculated-value .form-input__input.light {
    border-style: dashed;
    color: #555;
}