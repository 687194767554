.duplicate-management__container {
    display: flex;
    gap: 40px;
    height: 100%;
}

    .duplicate-management__container__left {
        display: flex;
        flex-direction: column;
        padding-right: 15px;
        border-right: 1px solid var(--clr-lightgrey);
        width: 400px;
        min-width: 400px;
        gap: 10px;
    }

    .duplicate-management__container__middle {
        display: flex;
        flex-direction: column;
        padding-right: 15px;
        gap: 10px;
    }

    .duplicate-management__container__right {
        display: flex;
        flex-direction: column;
        padding-right: 15px;
        gap: 10px;
    }

    /**
     *********** TABS ***********
     */
    .duplicate-management__container__tabs {
        width: 100%;
        background-color: var(--clr-lightgrey);
        display: flex;
        align-items: center;
        border-radius: var(--radius-small);
        overflow: hidden;
    }

        .duplicate-management__container__tabs li {
            color: var(--clr-azami-blurple);
            font-size: 14px;
            font-weight: 600;
            width: 100%;
            text-align: center;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }

        .duplicate-management__container__tabs li.active {
            background-color: var(--clr-azami-blue-light);
        }
    
    /**
     *********** FILTERS ***********
     */
    .duplicate-management__container__filters {
        display: flex;
        gap: 10px;
    }

    /**
     *********** LIST ***********
     */
    .duplicate-management__container__list {
        display: flex;
        flex-direction: column;
        padding: 0 10px;
        height: 100%;
        overflow-y: auto;
    }

        .duplicate-management__container__list-item {
            padding: 10px;
            display: flex;
            flex-direction: column;
            gap: 5px;
            cursor: pointer;
            border-radius: var(--radius-small);
        }

        .duplicate-management__container__list-item.active {
            background-color: var(--clr-lightgrey);
        }

        .duplicate-management__container__list-item:hover {
            background-color: var(--clr-lightgrey);
        }

            .item-title {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .item-title .matches {
                color: var(--clr-midgrey);
                font-size: 12px;
            }

            .item-subtitle {
                display: flex;
                align-items: center;
                gap: 5px;
                color: var(--clr-midgrey);
                font-size: 14px;
            }

                .item-subtitle .address-ellipsis {
                    display: inline-block;
                    width: 200px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

        /**
         *********** EXISTING VARIATIONS CONTAINER ***********
         */
         .variations-selection-container {
            display: flex;
        }

            .existing-variations-list {
                width: 400px;
                min-width: 400px;
                height: 100%;
                overflow-y: auto;
            }

                .selected-variations-list {
                    height: 430px;
                    padding: 10px;
                    overflow: auto;
                    align-content: flex-start;
                    flex-wrap: wrap;
                    display: flex;
                    gap: 7px;
                }

                    .selected-variations-list li {
                        background-color: var(--clr-azami-blurple);
                        border-radius: 15px;
                        display: flex;
                        align-items: center;
                        padding: 5px 10px;
                        color: var(--clr-white);
                    }

                        .selected-variations-list li button {
                            box-sizing: border-box;
                            vertical-align: middle;
                            height: 13px;
                            width: 13px;
                            border: none;
                            outline: none;
                            color: var(--clr-white);
                            border-radius: 50%;
                            font-size: 12px;
                            margin-left: 5px;
                            font-weight: bolder;
                            cursor: pointer;
                            color: var(--clr-azami-blurple);
                        }

            /**
             *********** EXISTING VARIATIONS LIST ITEM ***********
             */
            .existing-variations-item__checkbox {
                padding: 10px;
                display: flex;
                gap: 15px;
                cursor: pointer;
                border-bottom: 1px solid var(--clr-lightgrey);
            }

                .existing-variations-item__checkbox .details {
                    padding: 5px;
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                }

            /**
             *********** UNMATCHED VARIATIONS LIST ITEM ***********
             */
            .unmatched-variations-item {
                display: flex;
                align-items: center;
            }

                .unmatched-variations-item__leader {
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                }

                    .unmatched-variations-item__leader > input {
                        visibility: hidden;
                        margin-right: 11px;
                    }

                .unmatched-variations-item__leader > input:before {
                    display: block;
                    content: "\2606";
                    visibility: visible;
                    color: var(--clr-midgrey);
                    line-height: 10px;
                    font-size: 17px;
                    cursor: pointer;
                }

                .unmatched-variations-item__leader > input:checked:before {
                    content: "\2605";
                    color: var(--clr-orange);
                }

                .unmatched-variations-item__checkbox {
                    padding: 10px;
                    display: flex;
                    gap: 15px;
                    cursor: pointer;
                    border-bottom: 1px solid var(--clr-lightgrey);
                }

                    .unmatched-variations-item__checkbox .details {
                        padding: 5px;
                        display: flex;
                        flex-direction: column;
                        gap: 5px;
                    }

            .specify-firm-id {
                margin-bottom: 20px;
            }  

                .specify-firm-id label span{
                    font-size: 15px;
                    font-weight: normal;
                }   

                .specify-firm-id select{
                    margin: 5px 10px;
                }     
            
/* *************************************************************************************************************** */
/* *********************************** Duplicate Management Applicants ******************************************* */
/* *************************************************************************************************************** */

/**
*********** SCROLLBARS ***********
*/
.duplicate-management__container__list::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: transparent;
}
  
/* Add a thumb */
.duplicate-management__container__list::-webkit-scrollbar-thumb {
    background: var(--clr-midgrey);
    border-radius: 5px;
    width: 10px;
}