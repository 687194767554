.system-settings-pricing-levels__table {
    margin-top: 15px;
}

    .system-details-content__body {
        flex-grow: 1;
        width: 100%;
        padding: 0 30px 15px 30px;
        display: flex;
    }

    .system-settings-pricing-levels-main {
        padding: 15px 30px 0 30px !important;
        /* border: 2px solid red; */
        width: 100%
    }
    
    .system-settings-pricing-levels__table tbody tr td:nth-child(4), 
    .system-settings-pricing-levels__table tbody tr td:nth-child(5), 
    .system-settings-pricing-levels__table tbody tr td:nth-child(6) {
        background-color: var(--clr-azami-blue-light);
    }

    .system-settings-pricing-levels__header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        align-items: center;
        padding: 15px 30px 0 30px;
        width: 100%;
    }
        .system-settings-pricing-levels_header__title {
            font-size: 16px;
            font-weight: 500;
            color: var(--clr-darkgrey);
        }

        .system-settings-pricing-level__highlight-info-wrapper {
            display: flex;
            align-items: center;
        }

        .system-settings-pricing-level__highlight-info-wrapper img{
            margin-right: 8px;
        }

        .system-settings-pricing-level__highlight-info-wrapper span {
            color: var(--clr-darkgrey);
            font-style: italic;
        }

        .system-details-content__body .form {
            display: flex;
            align-items: center;
        }

        .system-settings-pricing-levels__body .form-input {
            display: inline-flex;
            width: 46%;
            margin: 10px 4% 10px 0;
        }

            .system-settings-pricing-levels-table-wrapper .form-textarea {
                margin: 10px 0;
                padding: 0 4% 0 0;
            }

            .system-settings-pricing-levels-table-wrapper .data-table thead th,
            .system-settings-pricing-levels-table-wrapper .data-table tbody td {
                width: 25%;
            }
                .system-settings-pricing-levels__name.regular span {
                    font-weight: 400;
                }

            .system-settings-pricing-levels__action-edit-btn {
                cursor: default;
                background-image: url(../../../assets/images/icons/ico_btn_edit_lightgrey.svg);
                background-repeat: no-repeat;
                background-color: transparent;
                border: none;
                border-radius: 0;
                width: 19px;
                height: 17px;
                display: inline-block;
                cursor: pointer;
                margin-left: 10px
            }

            .system-settings-pricing-levels__action-archive-btn {
                cursor: default;
                /* background-image: url(../../../assets/images/icons/ico_btn_delete_darkblue.svg); */
                background-image: url(../../../assets/images/icons/ico_archive_grey.svg);
                background-repeat: no-repeat;
                background-color: transparent;
                border: none;
                border-radius: 0;
                width: 19px;
                height: 17px;
                display: inline-block;
                cursor: pointer;
                margin-left: 10px
            }

            .system-settings-pricing-levels__action-archive-btn:disabled {
                cursor: default;
                /* background-image: url(../../../assets/images/icons/ico_btn_delete_lightgrey.svg); */
                background-image: url(../../../assets/images/icons/ico_archive_disabled_grey.svg);
                background-repeat: no-repeat;
                background-color: transparent;
                border: none;
                border-radius: 0;
                width: 19px;
                height: 17px;
                display: inline-block;
                /* cursor: pointer; */
                margin-left: 10px
            }

            .system-settings-pricing-levels__action-archive-btn.unarchive {
                background-image: url(../../../assets/images/icons/ico_unarchive_grey.svg);
            }

        .system-settings-pricing-levels__section-header__directions {
            display: flex;
            margin: 0 auto 0 30px;
        }
        
        .system-settings-pricing-levels__section-header__directions .dir-filter {
            margin: 0 5px;
            color: rgb(185,188,182);
            text-decoration: none;
            font-size: 14px;
            cursor: pointer;
        }
        
        .system-settings-pricing-levels__section-header__directions .dir-filter.active {
            color: var(--clr-azami-blurple);
            font-weight: bold;
        }

