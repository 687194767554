.expertise-list {
    width: 100%;
    position: relative;
}

    .expertise-list__title {
        font-size: 16px;
        font-weight: 500;
        color: var(--clr-darkgrey);
        display: block;
        margin-bottom: 10px;
    }

    .expertise-list__header {
        width: 100%;
        border-radius: var(--radius-small);
        background-color: var(--clr-background);
        padding: 1px;
    }

        .expertise-list__header__checkbox {
            margin: 5px 10px;
        }
        
            .expertise-list__header__checkbox .label {
                font-weight: bold;
                text-transform: uppercase;
                color: var(--clr-darkgrey);
            }

    .expertise-list__item {
        border-bottom: 1px solid var(--clr-grey);
        display: flex;
        transition: all .3s ease-in-out;
    }

    .expertise-list__item:hover {
        background-color: var(--clr-lightgrey);
    }

    .expertise-list__items__checkbox {
        margin: 10px 10px;
    }
    
        .expertise-list__items__checkbox .label {
            color: var(--clr-darkgrey);
        }
