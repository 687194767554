.regions-list {
    width: 100%;
    position: relative;
    margin-bottom: 20px;
}

    .regions-list__title {
        font-size: 16px;
        font-weight: 500;
        color: var(--clr-darkgrey);
        display: block;
        margin-bottom: 10px;
    }

    .regions-list__header {
        width: 100%;
        border-radius: var(--radius-small);
        background-color: var(--clr-background);
        padding: 6px 11px;
        font-weight: bold;
        text-transform: uppercase;
        color: var(--clr-darkgrey);
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

        .regions-list__header__filter {
            display: flex;
            align-items: center;
            background-color: var(--clr-white);
            padding: 8px 10px;
            border-radius: var(--radius-small);
        }

        .regions-list__header__filter span {
            color: var(--clr-azami-blue);
            margin-right: 5px;
        }

        .regions-list__header__filter select {
            color: var(--clr-azami-blue);
            border: none;
            font-weight: 500;
        }

    .regions-list__items {

    }

        .regions-list__item {
            padding: 10px 11px;
            border-bottom: 1px solid var(--clr-grey);
            height: 37px;
            display: flex;
            align-items: center;
            transition: all .3s ease-in-out;
            justify-content: space-between;
        }

        .regions-list__item__archived {
            filter: grayscale(1);
        }

        .regions-list__item:hover {
            background-color: var(--clr-lightgrey);
        }

            .regions-list__item__flag {
                width: 20px;
                object-fit: contain;
                margin-right: 10px;
            }

            .regions-list__item__name {
                color: var(--clr-darkgrey);
                font-size: 14px;
                flex-grow: 1;
            }

            .regions-list__item__button {
                background-color: transparent;
                outline: none;
                border: none;
                cursor: pointer;
            }
            .regions-list__item__button:disabled {
                cursor: not-allowed;
            }

                .regions-list__item__button img {

                }

    .regions-list__btn-add {
        margin-top: 10px;
        width: 100%;
    }
    
    .regions-list__form {
        display: flex;
        gap: 10px;
        justify-content: space-between;
        padding-top: 10px;
        align-items: end;
    }

        .regions-list__form__countries-select {
            flex-grow: 1;
            margin-bottom: 0;
        }
            
            .regions-list__form__countries-select .form-select__label {
                display: none;
            }