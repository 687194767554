.copy-to-clipboard-container {
    position: relative;
}

    .copy-to-clipboard-container:hover .copy-to-clipboard-container__tooltip-list {
        display: block;
    }

/*************** COPY TO CLIPOBOARD TOOLTIP ****************/

.copy-to-clipboard-container__tooltip-list {
    position: absolute;
    bottom: 70%;
    left: 10%;
    z-index: 10;
    color: var(--clr-white);
    background-color: rgba(0, 0, 0, 0.865);
    padding: 10px;
    border-radius: var(--radius-small);
    font-size: 14px;
    display: none;
}

    .copy-to-clipboard-container__tooltip-list li {
        display: flex;
        gap: 15px;
        cursor: pointer;
        padding: 5px;
        border-radius: var(--radius-small);
    }

    .copy-to-clipboard-container__tooltip-list li:hover {
        background-color: rgba(60, 60, 60, 0.914);
    }

    .copy-to-clipboard-container__tooltip-list li img {
        width: 14px;
        height: 16px;
    }
    
    .copy-to-clipboard-container__tooltip-list::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 25%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: black transparent transparent transparent;
    }

/*************** SEND EMAIL MODAL ****************/

.send-email-modal-container {
    min-width: 450px;
}

    .send-email-modal-container__email-input {
        display: flex;
        align-items: center;
        gap: 5px;
    }

        .send-email-modal-container__email-input button {
            height: 35px;
            margin-top: 5px;
            outline: none;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 8px;
            border-radius: var(--radius-small);
            background-color: transparent;
            border: 1px solid var(--clr-midgrey);
        }

    .send-email-modal-container__email-list li {
        display: flex;
        align-items: center;
        gap: 5px;
    }

        .send-email-modal-container__email-list li input:disabled {
            border: 1px solid var(--clr-midgrey);
            color: var(--clr-azami-blue);
        }

        .send-email-modal-container__email-list li button {
            height: 35px;
            background-color: transparent;
            border: 1px solid var(--clr-midgrey);
            margin-bottom: 5px;
            outline: none;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 8px;
            border-radius: var(--radius-small);
            z-index: 10;
        }