.region-search-selector {
    width: 450px;
}

    .region-search-selector__regions-list {
        background-color: var(--clr-white);
        overflow: auto;
    }

        .region-search-selector__regions-list li {
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 12px;
            border-radius: var(--radius-small);
            font-size: 14px;
            color: var(--clr-darkgrey);
        }
        
        .region-search-selector__regions-list li:hover {
            background-color: var(--clr-lightgrey)
        }

            .region-search-selector__regions-list-left {
                display: flex;
                align-items: center;
                gap: 5px;
                pointer-events: none;
            }

                .region-search-selector__regions-list-left img {
                    height: 10px;
                }
        
        

