.settings-estimator-rules .system-details-content__body {
    padding-top: 20px;
    flex-direction: column;
}

.settings-estimator-rules-rule-name-input-container {
    display: flex;
}

    .settings-estimator-rules-rule-name-input-container-last-edited {
        display: flex;
        flex-direction: column;
        padding: 5px;
        margin-left: 10px;
    }

        .settings-estimator-rules-rule-name-input-container-last-edited label {
            font-size: 12px;
            font-weight: 500;
            text-transform: uppercase;
            color: var(--clr-darkgrey);
            margin-bottom: 8px;
        }

        .settings-estimator-rules-rule-name-input-container-last-edited span{
            font-size: 12px;
        }

.settings-estimator-rules .text-input {
    width: 265px;
}   

.settings-estimator-rules .textarea-input {
    width: 560px;
}

.settings-estimator-rules__service-list {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 15px;
}

    .settings-estimator-rules__service-list li {
        width: 200px;
    }

    .settings-estimator-rules__service-list li label{
        margin: 0;
    }

.settings-estimator-rules__regions-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

    .settings-estimator-rules__regions-list li {
        display: flex;
        align-items: center;
        padding: 5px 10px;
        border-radius: var(--radius-small);
        background-color: var(--clr-grey);
        color: var(--clr-darkgrey);
        font-size: 14px;
        line-height: 14px;
    }

        .settings-estimator-rules__regions-list li img {
            height: 10px;
            margin-right: 5px;
        }

        .settings-estimator-rules__regions-list li button {
            border: none;
            outline: none;
            background-color: transparent;
            font-size: 20px;
            margin-left: 5px;
            cursor: pointer;
            color: var(--clr-darkblue);
        }

    .settings-colapsible__body__section .fees-radio {
        width: 150px;
    }

    .settings-colapsible__body .switch-list {
        margin-bottom: 10px;
    }

.settings-colapsible__body__tab-content-container {
    padding-top: 20px;
}

    .settings-colapsible__body.estimator-rules-options .form-switch{
        margin-bottom: 10px;
    }

    .settings-colapsible__body.estimator-rules-options .form-switch__pill{
        min-width: 38px
    }


.input-ranges {
    display: flex;
    gap: 40px;
    width: 100%;
    /* overflow-x: scroll; */
    flex-wrap: wrap;
}

.levels-input-list {
    width: 250px;
}

    .levels-input-list li {
        display: flex;
        align-items: center;
    }

        .levels-input-list .levels-input-list__label {
            display: block;
            width: 240px;
        }

        .levels-input-list input {
            width: 110px;
        }

        .levels-input-list .form-input {
            width: auto;
        }

        .levels-input-list__separator {
            display: block;
            width: 30px;
            text-align: center;
        }

        .levels-input-list__delete-button {
            background-image: url(../../../assets/images/icons/ico_btn_delete_blurple.svg);
            background-repeat: no-repeat;
            background-color: transparent;
            border: none;
            width: 19px;
            height: 17px;
            display: inline-block;
            cursor: pointer;
            margin-left: auto;
            margin-bottom: 18px;
        }

.settings-colapsible__body__tab-content-container__line {
    display: flex;
    gap: 20px;
    width: 100%;
    align-items: flex-start;
}

    .settings-colapsible__body__tab-content-container__line .option-dd {
        max-width: 250px;
    }

    .settings-colapsible__body__tab-content-container__line .option-dd.no-label-input span.label {
        margin-top: 14px;
    }

    .settings-colapsible__body__tab-content-container__line .option-btn {
        width: 130px;
        height: 37px;
        margin-left: auto;
        margin-bottom: 15px;
    }

    .settings-colapsible__body__tab-content-container__line .line-text {
        margin-top: 32px;
        font-size: 14px;
    }

    .settings-colapsible__body__tab-content-container__line .option-sw {
        margin-bottom: 23px;
    }

.settings-estimator-rules__list-view {
    /* padding: 20px; */
}

    .settings-estimator-rules__list-view__header {
        width: 100%;
        display: flex;
        gap: 20px;
        padding: 0 20px;
        margin-top: 20px;
        align-items: flex-end;
    }

        .settings-estimator-rules__list-view__header__add-btn {
            margin-left: 150px;
            height: 37px;
            margin-bottom: 16px;
            min-width: 150px;
        }

    .settings-estimator-rules__list-view__body {
        padding: 0 20px 20px 20px;
        width: 100%;
    }

        .settings-estimator-rules__edit-btn {
            background-color: transparent;
            border: none;
            background-image: url(../../../assets/images/icons/ico_btn_edit_lightgrey.svg);
            background-position: center;
            background-repeat: no-repeat;
            width: 20px;
            height: 20px;
            cursor: pointer;
        }

        .settings-estimator-rules__delete-btn {
            background-color: transparent;
            border: none;
            /* background-image: url(../../../assets/images/icons/ico_archive_blurple.svg); */
            background-image: url(../../../assets/images/icons/ico_archive_grey.svg);
            background-position: center;
            background-repeat: no-repeat;
            width: 20px;
            height: 20px;
            cursor: pointer;
        }

        .settings-estimator-rules__unarchive-btn {
            background-color: transparent;
            border: none;
            background-image: url(../../../assets/images/icons/ico_unarchive_grey.svg);
            background-position: center;
            background-repeat: no-repeat;
            width: 20px;
            height: 20px;
            cursor: pointer;
        }

        .settings-estimator-rules__calculation-type-error-indicator {
            background-color: var(--clr-orange);
            height: 8px;
            width: 8px;
            min-width: 8px;
            border-radius: 50%;
            pointer-events: none;
            margin-left: 2px;
            visibility: hidden;
            margin-bottom: 8px;
        }

            .settings-estimator-rules__calculation-type-error-indicator.show-error {
                visibility: visible;
            }
       
.estimator-rules-summary-error {
    background-color: var(--clr-red) !important;
}

.estimator-rules-required-error {
    color: var(--clr-red);
}

.estimator-rules__footer-switch-wrapper {
    flex-grow: 1;
}

.customize-rule-modal-levels-input-list__range.error .error-label{
    display: none;
}



