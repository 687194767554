.update-input.translation-fee {
    margin-left: 10px;
    width: 100px;
}

.update-input.professional-fee {
    width: 150px;
}

.update-button {
    margin-right: 5px;
}

.update-button-gray {
    cursor: pointer;
}

.all-associate-modal-content {
    height: 400px;
    width: 1200px;
}
    
    .all-associate-modal-content-table-container {
        overflow-y: auto;
        max-height: 85%;
    }

    .all-associate-modal-content-table-container tr.inactive td {
        background-color: var(--clr-greyed-out-background);
    }

    .all-associate-modal-content-table-container tr.favorite td {
        background-color: var(--clr-highlight-background);
    }

        .all-associate-modal-content-table-price-cell {
            justify-content: end;
        }