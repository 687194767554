.auth-template {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

    .auth-template__header {
        width: 100%;
        background-color: var(--clr-white);
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 35px;
    }

        .auth-template__header-logo {
            margin: 20px 0 20px 20px;
        }

    .auth-template__header::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 3px;
        background: var(--clr-azami-gradient);
    }

    .auth-template__content {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }