.case-details__sidebar__header h4 {
    margin: 0 0 15px 15px;
    color: var(--clr-midgrey);
    text-transform: uppercase;
}

.case-details__sidebar__header img {
    margin-right: 15px;
}

.case-details__list {
    background-color: var(--clr-grey);
    border-radius: var(--radius);
    padding: 15px;
    width: 90%;
}

    .case-details__list li {
        background-color: var(--clr-white);
        padding: 15px;
        border-radius: 10px;
        margin-bottom: 5px;
        display: flex;
        justify-content: space-between;
        border: 1px solid var(--clr-azami-blurple-glass);
        align-items: center;
    }
    
    .case-details__list li:last-child {
        margin-bottom: 0;
    }

    .case-details__list li span:first-child {
        margin-right: 10px;
    }

    .case-details__list li span:last-child {
        text-align: end;
        font-weight: 500;
        word-break: break-word;
    }

    .case-details__list li span {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: var(--clr-darkgrey);
    }

    .case-details__list li span img {
        margin-right: 5px;
    }

    .case-details__list__status-indicator {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: red;
        margin: 0 0 0 5px;
    }

.case-details-content__header {
    width: 100%;
    padding: 15px;
}

    .case-details-content__header nav {
        width: 100%;
        background-color: var(--clr-azami-blue-glass);
        border-radius: var(--radius-small);
        padding: 5px 7px;
        display: flex;
        gap: 5px;
    }

    .case-details-content__header nav a {
        color: var(--clr-azami-blurple);
        text-decoration: none;
        font-size: 14px;
        padding: 5px 8px;
        background-color: transparent;
        border-radius: var(--radius-small);
        text-transform: capitalize;
        transition: all 0.15s ease-in-out;
        font-weight: unset;
    }

    .case-details-content__header nav a.active {
        background-color: var(--clr-azami-blurple);
        color: var(--clr-white);
    }

    .case-details-content__header nav a:hover{
        background-color: rgba(255, 255, 255, .5);
    }

    .case-details-content__header nav a.active:hover{
        background-color: var(--clr-azami-blurple);
        color: var(--clr-white);
    }

    .case-details-content__header nav a span {
        background-color: var(--clr-azami-blurple);
        color: var(--clr-azami-lightgrey);
        margin-left: 10px;
        padding: 2px 7px;
        border-radius: 5px;
        font-size: 14px;
        font-weight: bold;
    }

    .case-details-content__header nav a.active span {
        background-color: var(--clr-white);
        color: var(--clr-azami-blurple);
    }

    .case-details-not-instructed__header-add-region-btn {
        margin-right: 8px;
    }

   

.case-details-content__body {
    padding: 0 30px 15px 30px;
}

.case-details-not-instructed__header {
    margin-bottom: 15px;
}

.case-details-instructed__header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
}

    .case-details-instructed__header a.form-button.azami-blue{
        font-weight: normal;
    }

.case-details-not-instructed__header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

    .case-details-instructed__table thead th:nth-child(1), 
    .case-details-instructed__table thead th:nth-child(2) {
        width: 10%;
    }

    .case-details-not-instructed__table thead th:nth-child(2), 
    .case-details-not-instructed__table thead th:nth-child(3) {
        width: 10%;
    }

    .case-details-not-instructed__table thead th:nth-child(1)  {
        width: 0;
    }

    .case-details-not-instructed__table thead th:nth-child(1) .form-checkbox,
    .case-details-not-instructed__table tbody tr .form-checkbox  {
        margin: 0;
    }

    .case-details-not-instructed__table tbody td:nth-child(4) {
        width: 15%;
    }

        .case-details-instructed__table-update-status-cell {
            justify-content: end;
        }

            .case-details-instructed__table-update-status-cell button {
                min-width: 180px;
            }

    .case-details-not-instructed__header__add-region__btn {
        margin-right: 15px;
    }

.case-details-send-email__title {
    font-weight: bold;
}    

.case-details-send-email__header__selects {
    width: 100%;
    display: flex;
    margin: 10px 0;
    gap: 20px;
}      

.case-details-send-email__header__actions {
    width: 100%;
    text-align: right;
}   

.case-details-send-email__header__actions button {
    margin: 0 0 0 10px;
}

.case-details-send-email {
    margin-top: 20px;
}

.case-details-send-email__header .form-multiselect__label {
    text-transform: inherit;
}

.case-details-send-email__header .form-multiselect__input  img {
    margin-right: 10px;
    width: 30px;
}

.case-details-send-email__contnet-row {
    width: 100%;
    display: flex;
    gap: 20px;
}

.case-details-send-email__footer {
    text-align: right;
    margin: 10px 0;
}

.case-details-send-email__footer button {
    margin: 0 5px;
}

.case-details-colapsible {
    width: 100%;
    margin: 15px 0;
}

    .case-details-colapsible__header {
        width: 100%;
        background-color: var(--clr-midlightgrey);
        color: var(--clr-azami-blurple);
        height: 34px;
        display: flex;
        align-items: center;
        border-radius: var(--radius-small);
        padding: 0 15px;
        cursor: pointer;
        font-size: 14px;
        text-transform: uppercase;
        outline:none;
        font-weight: bold;
    }
        .case-details-colapsible__header.open {
            background-color: var(--clr-azami-blue-light);
        }

        .case-details-colapsible__header i {
            display: flex;
            padding: 5px;
            margin-right: 5px;
        }

        .case-details-colapsible__header .region-email {
            margin: 0 5px 0 0;
        }

            .case-details-colapsible__header .region-email img {
                width: 25px;
                margin: 0 5px 0 0;
                vertical-align: text-top;
            }

    .case-details-colapsible__body {
        margin-top: 15px;
        padding: 5px 15px 20px 15px;
        border-bottom: 1px solid var(--clr-midlightgrey);
    }

.case-details-content__footer {
    width: 100%;
    height: 55px;
    background-color: var(--clr-grey);
    border-radius: 0 0 0 var(--radius);
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
}

    .case-details-content__footer > label {
        margin: 0;
    }
 
    .case-details-content__footer__checkbox-label {
        color: var(--clr-midgrey);
    }

    .case-details-content__footer__checkbox-label a {
        color: var(--clr-darkgrey);
    }

    .case-details-content__footer__checkbox-label a:hover {
        text-decoration: none;
    }

    /* ****************************************************************************************** */
    /* *********************************** Delete Modal *************************************** */
    /* ****************************************************************************************** */
    .confirmation-modal__body__confirm-delete {
        text-align: center;
    }

    .confirmation-modal__body__confirm-delete span {
        color: var(--clr-darkblue);
    }