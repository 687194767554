.form-radio {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    margin-top: 10px;
    cursor: pointer;

    &.disabled {
        .input:checked + .checkmark,
        .input + .checkmark {
            filter: grayscale(1);
            opacity: 0.5;
            cursor: not-allowed;
        }
    }

    input {
        display: none;
    }

    .checkmark {
        width: 14px;
        min-width: 14px;
        height: 14px;
        margin: 0;
        border-radius: 100%;
        border-width: 1px;
        border-style: solid;
        position: relative;
        margin-right: 7px;

    }

    &.primary {
        input + .checkmark {
            border-color: var(--theme-clr-primary);
        }

        input:checked + .checkmark {
            &::after {
                content: '';
                position: absolute;
                width: 9px;
                height: 9px;
                background-color: var(--theme-clr-primary);
                border-radius: 100%;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    &.secondary {
        input + .checkmark {
            border-color: var(--theme-clr-secondary);
        }

        input:checked + .checkmark {
            &::after {
                content: '';
                position: absolute;
                width: 9px;
                height: 9px;
                background-color: var(--theme-clr-secondary);
                border-radius: 100%;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    .label {
        font-size: 14px;
        color: var(--theme-clr-base-dark);
    }

    &.no-margin {
        margin: 0;
    }

}