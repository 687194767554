.reports-sidebar {
    width: 300px;
    padding: 0 0 115px 15px;
    height: 100%;
    /* top: 0px; */
    /* position: fixed; */
    overflow-y: auto;
}

.reports-sidebar__header {
    color: var(--clr-midgrey);
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 15px;
}

    .reports-sidebar__header img {
        margin-right: 5px;
    }

.reports-sidebar_actions {
    padding-top: 25px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid var(--clr-midgrey);
}

.reports-sidebar_hgroup {
    display: flex;
    justify-content: space-between;
}

.reports-sidebar_hgroup label:first-child, 
.reports-sidebar_hgroup label:last-child {
    width: 48%;
}

.drawer {
    background-color: var(--clr-lightblue);
}

.main-screen-section__header-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 0 20px;
    gap: 20px;
}

.nested-table {
    width: 100%;
    border: 1px solid var(--clr-grey);
    border-radius: var(--radius-small);
    box-shadow: var(--shadow);
    background-color: var(--clr-white);
}
    .nested-table th, .nested-table td {
        font-size: 14px;
    }

    .nested-table th {
        padding: 15px;
        color: var(--clr-darkblue);
        text-transform: uppercase;
    }

   