.tooltip-wrapper {
  display: inline-block;
  position: relative;
  overflow: unset;

  .tooltip {
    container-name: tooltip;
    position: absolute;
    border-radius: 4px;
    padding: 20px;
    color: var(--theme-clr-base-light);
    background: var(--theme-clr-base-dark);
    font-size: 13px;
    z-index: 100;
    width: max-content;
    display: block;
    box-shadow: 0px 3px 5px #aaa;

    &.long {
      width: 350px;
    }
      
    &::before {
      content: '';
      right: 10%;
      border: solid transparent;
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-width: 10px;
      margin-left: calc(10px * -1);
    }
    
    &.top {
      bottom: 40px;
      right: -15px;
    }

    &.top::before {
      top: 100%;
      right: 15px;
      border-top-color: var(--theme-clr-base-dark);
    }
    
    &.right {
      left: 40px;
      transform: translateY(calc(-50% - 15px));
    }

    &.right::before {
      left: -10px;
      top: calc(50% - 10px);
      border-right-color: var(--theme-clr-base-dark);
    }
    
    &.bottom {
      top: 40px;
      right: -15px;
    }

    &.bottom::before {
      bottom: 100%;
      right: 15px;
      border-bottom-color: var(--theme-clr-base-dark);
    }

    &.left {
      right: 40px;
      transform: translateY(calc(-50% - 15px));
    }

    &.left::before {
      right: -20px;
      top: calc(50% - 10px);
      border-left-color: var(--theme-clr-base-dark);
    }
  }
  
  .icon {
    width: 22px;
    height: 22px;
    display: inline-block;
    mask-repeat: no-repeat;

    &.primary {
      background-color: var(--theme-clr-primary);
    }

    &.secondary {
      background-color: var(--theme-clr-secondary);
    }

    &.dark {
      background-color: var(--theme-clr-base-dark);
    }
  }
}
