.public-template {
    width: 100%;
    height: 100%;
    padding-top: 64px;
}

.public-template__sidenav {
    width: 100%;
    height: 60px;
    background: var(--clr-white);
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    z-index: 4;
    box-shadow: var(--shadow);
}

.public-template__sidenav::after {
    display: block;
    content: '';
    width: 100%;
    height: 3px;
    position: absolute;
    left: 0;
    bottom: -3px;
    background: rgb(2,110,179);
    background: linear-gradient(90deg, rgba(20, 0, 255, 1) 0%, rgba(216, 0, 255, 1) 100%);

}

.public-template__btn-sidebar-hide {
    height: 28px;
    outline: none;
    background-color: var(--clr-azami-blue-light);
    color: var(--clr-azami-blue);
    font-weight: 500;
    padding: 3px 8px;
    font-size: 16px;
    border-radius: var(--radius-small);
    position: absolute;
    left: 28px;
    top: 52px;
    cursor: pointer;
    box-shadow: var(--shadow-lightblue);
    border: none;
    display: flex;
    align-items: center;
    transition: all .3s ease-in-out;
    z-index: 3;
}

    .public-template__btn-sidebar-hide.hide {
        opacity: 0;
        left: 65px;
    }

    .public-template__btn-sidebar-hide img {
        margin-right: 8px;
    }

.public-template__btn-sidebar-show {
    height: 28px;
    outline: none;
    background-color: var(--clr-lightblue);
    color: var(--clr-blue);
    font-weight: 500;
    padding: 3px 0px 3px 8px;
    font-size: 16px;
    border-radius: var(--radius-small);
    position: absolute;
    left: 28px;
    top: 52px;
    cursor: pointer;
    box-shadow: var(--shadow-lightblue);
    border: 1px solid var(--clr-midblue);
    display: flex;
    align-items: center;
    transition: all .3s ease-in-out;
    z-index: 3;
}

    .public-template__btn-sidebar-show.hide {
        opacity: 0;
        left: 55px;
    }

    .public-template__btn-sidebar-show img {
        margin-right: 8px;
    }

.public-template__sidenav__logo {
    width: 110px;
    height: 38px;
}

    .public-template__sidenav a {
        transition: all .15s ease-in-out;
        opacity: .9;
        color: var(--clr-darkgrey);
        display: inline-block;margin: 0 10px;
        text-decoration: none;
        font-weight: 400;
        position: relative;
        font-size: 16px;;
    }

    .public-template__sidenav a::after {
        content: '';
        display: block;
        width: 0;
        height: 4px;
        background-color: var(--clr-azami-violet);
        position: absolute;
        border-radius: 2px;
        top: 29px;
        transition: all .3s ease-in-out;
    }

    .public-template__sidenav a:hover::after {
        width: 100%;
    }

    .public-template__sidenav a.active {
        color: var(--clr-azami-violet);
        font-weight: 600;
    }

    .public-template__sidenav a.nohover::after {
        display: none;
    }

    .public-template__sidenav a.external-link {
        background: transparent url(../../../assets/images/icons/link.svg) no-repeat right top;
        padding-right: 25px;
    }

.public-template__sidenav__icon {
    width: 20px;
    margin: 10px 12px 0 12px;   
}

.public-template__main-screen {
    display: flex;
    flex-grow: 1;
    height: 100%;
}

.public-template__main-screen__left {
    min-width: 300px;
    width: 300px;
    overflow-x: hidden;
    position: relative;
    transition: all .3s ease-in-out;
    padding-top: 25px;
}

    .public-template__main-screen__left.hide {
        min-width: 0px;
        width: 0px;
        opacity: 0;
    }

    .public-template__main-screen__left  .form-button {
        min-width: 130px;
       font-size: 14px;
       font-weight: 500;
    }

    .public-template__main-screen__left .form-button.light {
        background: var(--clr-azami-blue);
    }

    .public-template__main-screen__left .form-button.ghost {
            border: none;
            background-color: var(--clr-azami-blue-light);
            color: var(--clr-azami-blue);
    }


.public-template__main-screen__right {
    display: flex;
    flex-grow: 1;
    height: 100%;
    flex-direction: column;
    z-index: 2;
    padding-top: 12px;
}

.public-template__main-screen__header {
    min-height: 55px;
    border-radius: 0 0 0 10px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    margin-bottom: 15px;
    transition: all .3s ease-in-out;
    width: 100%;
    z-index: 1;
}

    .public-template__main-screen__header.hide {
        width: 100%;
        border-radius: 0;
    }

    .public-template__main-screen__header__icon {
        margin-right: 10px;
    }

    .public-template__main-screen__header__count-badge {
        display: block;
        background-color: var(--clr-azami-violet);
        color: var(--clr-white);
        padding: 4px 5px;
        border-radius: var(--radius-small);
        margin-left: 10px;
        font-size: 14px;
        font-weight: 600;
    }

    .public-template__main-screen__header h2 {
        font-size: 24px;
        font-weight: 400;
        color: var(--clr-azami-blue);
    }

    .public-template__main-screen__header__content {
        display: flex;
        justify-content: flex-end;
        flex-grow: 1;
    }

    .public-template__main-content {
        min-height: calc(100% - 40px); 
        padding: 0 0 0 20px;
    }
