.reciprocity-override-modal__hgroup {
    display: flex;
    justify-content: space-between;
}

    .reciprocity-override-modal__hgroup.column {
        flex-direction: column;
    }

    .reciprocity-override-modal__hgroup label:nth-child(n) {
        width: 49%;
    }