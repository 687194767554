.form-tag-select {
    width: 100%;
    margin-bottom: 15px;

    ul.tag-list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
        margin-top: 5px;
        margin-bottom: 5px;
    }
        li.tag {
            background-color: var(--theme-clr-primary);
            color: var(--theme-clr-base-light);
            font-size: 14px;
            font-weight: 500;
            height: 26px;
            border-radius: 15px;
            display: flex;
            align-items: center;
            padding: 5px;
        }
            span.tag-label {
                margin: auto 10px;
            }

            button.tag-close {
                display: block;
                width: 16px;
                height: 16px;
                border: none;
                background-color: var(--theme-clr-base-light);
                color: var(--theme-clr-primary-dark);
                border-radius: 8px;
                margin: 0 2px 0 0;
                cursor: pointer;
            }
}







