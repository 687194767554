.terms-and-conditions__content-wrapper {
    padding: 25px;
    background-color: var(--clr-white);
    border-radius: var(--radius-small);
}
    .terms-and-conditions__content-wrapper h5{
        margin-bottom: 5px;
        color: var(--clr-darkgrey);
    }

    .terms-and-conditions__content-wrapper p{
        color: var(--clr-darkgrey);
        line-height: 24px;
        font-size: 14px;
        margin-bottom: 25px;
        font-family: 'Roboto';
    }   