.quote-details {
    
}

.quote-details-view-content__body {
    padding: 20px;
}

.quote-details-view-estimator-rules {
    display: flex;
    width: 100%;
    flex-direction: column;
}

    .quote-details-view-estimator-rules__section-header {
        width: 100%;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
    }

    .quote-details-view-estimator-rules__section-header.filters {
        margin-top: 20px;
        padding: 15px;
    }

    .quote-details-view-estimator-rules__section-body {
        margin-top: 15px;
        width: 100%;
        padding: 15px;
        /* max-width: 50%; */
    }

    .quote-details-view-estimator-rules__table-edit-btn {
        background-color: transparent;
        border: none;
        background-image: url(../../../assets/images/icons/ico_btn_edit_lightgrey.svg);
        background-position: center;
        background-repeat: no-repeat;
        width: 20px;
        height: 20px;
        cursor: pointer;
    }

.quote-details-view-content__header nav {
    width: 100%;
    background-color: var(--clr-azami-blue-glass);
    border-radius: var(--radius-small);
    padding: 5px 7px;
    display: flex;
    gap: 5px;
}

.quote-details-view-content__header {
    width: 100%;
    padding: 15px;
}

    .quote-details-view-content__header nav a {
        color: var(--clr-azami-blue);
        text-decoration: none;
        font-size: 14px;
        display: inline-block;
        padding: 5px 8px;
        background-color: transparent;
        border-radius: var(--radius-small);
        text-transform: capitalize;
        transition: all .15s ease-in-out;
        position: relative;
    }

    .quote-details-view-content__header nav a.active {
        background-color: var(--clr-azami-blue);
        color: var(--clr-lightblue);
    }

    .quote-details-view-content__header nav a:hover {
        background-color: rgba(255, 255, 255, .5);
    }

    .quote-details-view-content__header nav a.active:hover {
        background-color: var(--clr-darkblue);
        color: var(--clr-lightblue);
    }

.override-fees-modal {
    background-color: var(--clr-white);
    overflow: hidden;
    border-radius: 10px;
    box-shadow: var(--shadow);
}

    .override-fees-modal__body {
        padding: 0 10px 0 10px;
        color: var(--clr-darkgrey);
        max-height: 400px;
        overflow-y: auto;
        font-size: 14px;
        width: 700px;
        position: relative;
    }

        .override-fees-modal__body table td{
            padding: 5px 10px;
        }

        .override-fees-modal__body table td input{
            margin-bottom: 5px;
            width: 70%;
        }

        .override-fees-modal__table-currency-symbol{
            display: flex;
            align-items: center;
            padding: 8px;
            height: 37px;
            margin-top: 8px;
            font-weight: 500;
        }
    
    .override-fees-modal__footer{
        margin: 15px 0 15px 0;
        padding: 0 10px 0 10px;
        width: 700px;
    }

        .override-fees-modal__footer-note{
            display: flex;
        }

        .override-fees-modal__footer-note-text{
            font-size: 14px;
            padding: 5px;
        }
        
        .override-fees-modal__footer-note-asterisk {
            font-size: 20px;
            padding-top: 2px;
            color: var(--clr-azami-violet);
        }

    .quotes-sidebar{
        display: flex;
        flex-direction: column;
        width: 300px;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
    }

        .quotes-sidebar__image-container-status-indicator {
            position: absolute;
            border: 4px solid var(--clr-background);
            right: -5px;
            top: -5px;
            background-repeat: no-repeat;
            background-position: 6px 4px;
            width: 30px;
            height: 30px;
            border-radius: 50%;
        }

        .quotes-sidebar__status-indicator {
            margin: 3px 7px 0 0;
            width: 12px;
            height: 12px;
            border-radius: 50%;
        }

        span.quotes-sidebar__status-indicator-published {
            color: var(--clr-green);
        }

        span.quotes-sidebar__status-indicator-draft {
            color: var(--clr-darkyellow);
        }

        div.quotes-sidebar__status-indicator-published {
            background-color: var(--clr-green);
        }

        div.quotes-sidebar__status-indicator-draft {
            background-color: var(--clr-darkyellow);
        }

        .quote-sidebar__quote-data-status-indicator-right{
            display: flex;
        }

        .quotes-sidebar__image-container {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--clr-grey);
            border-radius: 50%;
            width: 90px;
            height: 90px;
            position: relative;
            margin-top: 3px;
        }

            .quotes-sidebar__image-container__avatar {
                width: 50px;
                height: 50px;
                border: none;
                outline: none;
            }

        .quotes-sidebar__firm-info {
            display: flex;
            flex-direction: column;
            padding-top: 15px;
        }

        .quotes-sidebar__firm-info__name {
            display: block;
            color: var(--clr-darkgrey);
            font-weight: bold;
            text-align: center;
        }

        .quotes-sidebar__firm-info_positon-company {
            font-size: 14px;
            color: var(--clr-midgrey);
            margin-top: 5px;
            text-align: center;
        }

        .quotes-sidebar__section {
            margin-top: 30px;
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: 0 0 0 15px;
        }
    
            .quotes-sidebar__section__title {
                font-size: 14px;
                color: var(--clr-midgrey);
                font-weight: bold;
                text-transform: uppercase;
                line-height: 14px;
                display: flex;
                width: 100%;
                margin-bottom: 15px;
            }
    
            .quotes-sidebar__section__title img {
                margin-right: 10px;
            }

            .quotes-sidebar__section__body__quote-data{
                background-color: var(--clr-grey);
                border-radius: var(--radius);
                padding: 10px;
            }

            .quotes-sidebar__section__body__quote-data li {
                background-color: white;
                display: flex;
                justify-content: space-between;
                padding: 9px;
                border-radius: 6px;
                margin: 7px 0 7px 0;
            }

            .quote-sidebar__quote-data-left{
                display: block;
                color: var(--clr-darkgrey);
                text-align: center;
            }

            .quote-sidebar__quote-data{
                display: block;
                color: var(--clr-darkgrey);
                font-weight: bold;
                text-align: center;
            }

            .quote-sidebar__wipo-link {
                cursor: pointer;
            }

            .quote-sidebar__wipo-link-disabled {
                pointer-events: none;
            }

            .quote-sidebar_quote-data-date-ico {
                padding-right: 5px;
            }

.quote-details-view-revisions {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding-bottom: 50px;
}

    .quote-details-view-revisions.single-quote-external {
        overflow-y: overlay;
    }

    .quote-details-view-revisions__qute-info {
        padding: 0 30px;
        display: flex;
        font-style: italic;
        font-size: 14px;
        gap: 15px;
    }

    .quote-details-view-revisions__qute-info-container {
        display: flex;
        gap: 2px;
    }

    .quote-info-image-container {
        width: 16px;
        height: 16px;
    }

    .quote-details-view-revisions__header {
        width: 100%;
        display: flex;
        gap: 20px;
        margin-top: 15px;
        padding: 0 30px;
    }

    .quote-details-view-revisions__header__select-container {
        width: 40%;
        display: flex;
        gap: 20px;
    }

    .quote-details-view-revisions__header__checkbox-container {
        display: flex;
        align-items: end;
        min-width: 200px;
    }

    .quote-details-view-revisions__collapsible {
        margin-bottom: 20px;
        position: relative;
    }

        .quote-details-view-revisions__collapsible-summary {
            background-color: var(--clr-grey);
            display: flex;
            font-size: 14px;
            line-height: 14px;
            padding:5px 10px 5px 10px;
            border-radius: var(--radius-small);
            position: relative;
        }

            .quote-details-view-revisions__collapsible-summary-status-indicator {
                position: absolute;
                left: 0;
                height: 100%;
                width: 10px;
                top: 0;
                border-top-left-radius: var(--radius-small);
                border-bottom-left-radius: var(--radius-small);
            }

            .quote-details-view-revisions__collapsible-summary-status-indicator.published-status {
                background-color: var(--clr-blue);
            }

            .quote-details-view-revisions__collapsible-summary.active {
                background-color: var(--clr-azami-blue-light);
                color: var(--clr-azami-blurple);
            }

                .summary-pricing-level {
                    border-radius: var(--radius-small);
                }

            .quote-details-view-revisions__collapsible-summary.active .summary-pricing-level {
                background-color: var(--clr-white);
            }
            

            .quote-details-view-revisions__collapsible-summary span {
                display: flex;
                align-content: center;
                padding: 5px;
                margin-right: 10px;
            }

            .quote-details-view-revisions__collapsible-summary img {
                height: 14px;
                margin-right: 5px;
            }

            .quote-details-view-revisions__collapsible-summary .summary-arrows {
                min-width: 20px;
                margin-right: 0;
            }

            .quote-details-view-revisions__collapsible-summary .summary-arrows img{
                height: 100%;
                width: 100%;
            }

            span.quote-summary__icons {
                padding: 0;
                margin-right: 0;
                margin-left: auto;
                display: flex;
                align-items: center;
                cursor: pointer;
                gap: 10px;
            }

            .quote-summary__actions {
                cursor: pointer;
                position: relative;
                display: flex;
            }

                .quote-summary__button {
                    width: 15px;
                    height: 100%;
                    border: none;
                    background-color: transparent;
                    align-items: center;
                    display: flex;
                    padding: 0 10px;
                }

                .quote-summary__actions-items {
                    position: absolute;
                    display: none;
                    z-index: 2;
                    width: 210px;
                    right: -5px;
                    top: 24px;
                    background-color: var(--clr-white);
                }

                .quote-summary__actions:hover .quote-summary__actions-items {
                    display: block;
                }
                        .quote-summary__actions-list-item {
                            padding: 5px 10px;
                            cursor: pointer;
                            display: flex;
                            color: var(--clr-black);
                        }
                    
                        .quote-summary__actions-list-item:hover{
                            background-color: var(--clr-lightblue);
                        }

                            .quote-summary__actions-list-item div{
                                height: 15px;
                                width: 15px;
                                margin-right: 5px;
                                padding-top: 5px;
                            }

        .quote-details-view-revisions__collapsible-body {
            padding: 15px 0;
        }

            .quote-details-view-revisions-associate-changed {
                color: var(--clr-azami-blurple);
                font-weight: 500;
            }

            .quote-details__failed-rules-container {
                width: 100%;
                background-color: var(--clr-lightred);
                border-radius: var(--radius-small);
                border: 1px solid var(--clr-red);
                font-size: 14px;
                padding: 20px;
                margin-bottom: 15px;
            }
            
                .quote-details__failed-rules-container p {
                    margin: 0 0 15px 0 !important;
                    color: var(--clr-red) !important;
                }

                .quote-details__failed-rules-container p:last-of-type {
                    margin-bottom: 0 !important;
                }

                .quote-details__failed-rules-container ul {
                    margin-left: 20px;
                    margin-bottom: 15px;
                }

                    .quote-details__failed-rules-container ul li {
                        margin: 5px 0;
                    }

            .quote-revisions__rule-filter {
                width: 100%;
                display: flex;
                margin-top: 40px;
                margin-bottom: 0px;
                gap: 20px;
            }

            .grayed-out__region__toggle__btn {
                background: transparent;
                border: none;
                cursor: pointer;
            }

            .grayed-out__region__toggle__btn.hide-region .tooltip-wrapper {
                left: 15px;
                top: 10px;
            }

            .grayed-out__region__toggle__btn.hide-region .tooltip-wrapper .icon {
                visibility: hidden;
            }

                .quote-revisions__rule-level-label {
                    text-transform: capitalize;
                }

            .grayed-out__region__toggle__btn:hover .info__tooltip {
                display: block;
            }

            .quote-details-view-revisions__toggle__region-wrapper > :nth-child(2){
                margin-top: 7px;
            }
               
            .quote-details-view-revisions__toggle__region {
                position: relative;
                margin-left: 5px;
            }

            .data-table.rules-table tr.stop-rule td {
                background-color: var(--clr-lightyellow);
            }

            .data-table.rules-table tr.failed-rule td {
                background-color: var(--clr-lightred);
            }

            .quote-details__custom-rules-container {
                width: 100%;
                background-color: var(--clr-lightyellow);
                border-radius: var(--radius-small);
                border: 1px solid var(--clr-darkyellow);
                font-size: 14px;
                padding: 10px 20px 10px 20px;
                margin-bottom: 15px;
            }

                .quote-details__custom-rules-container p {
                    color: var(--clr-black) !important;
                }

            .data-table.rules-table tr td div.custom-rule {
                font-weight: 800;
            }

    .form-checkbox:has(.table-row-checkbox) {
        margin: 0;
    }

    .quote-details-admin-panel-revision__content-stripe-body-header-button{
        display: flex;
        align-items: end;
        margin-left: auto;
        margin-bottom: 15px;
        gap: 15px;
    }

        .quote-settings {
            background-color: var(--clr-azami-blurple);
            border-radius: var(--radius-small);
            color: var(--clr-white);
            padding: 8px 5px;
            font-size: 16px;
            position: relative;
            display: flex;
            font-weight: 500;
        }

            .quote-settings-icon-container {
                width: 15px;
                height: 15px;
                margin: 0 5px;
                padding: 1px;
            }

            .quote-settings-icon-container img {
                width: 100%;
                height: 100%;
            }
            
            .quote-settings-icon-container.icon-sort-down img {
                padding: 2px
            }
        
        .quote-settings:hover .quote-settings-button-container {
            display: block;
        }
        
    
        .quote-settings-button-container {
            position: absolute;
            right: 0px;
            top: 31px;
            display: none;
            z-index: 2;
            width: 200px;
            background-color: var(--clr-white);
        }
    
        .quote-settings-button-container .form-checkbox {
            margin: 0;
        }
    
        .quote-settings-button-container li {
            padding: 5px 10px;
            cursor: pointer;
            display: flex;
        }
    
        .quote-settings-button-container li:hover{
            background-color: var(--clr-lightblue);
        }
    
        .quote-settings-actions-menu__list-item span{
            font-size: 14px;
            color: var(--clr-black);
            font-weight: 400;
        }

        .quote-settings-actions-menu__list-item div, .quote-settings-actions-menu__list-item .form-checkbox__checkmark {
            height: 15px;
            width: 15px;
            margin-right: 5px;
        }
    
        .quote-settings-actions-menu__list-item{
            text-align: left;
        }

.modify-currency-modal {
    width: 300px;
}

    .modify-currency-modal__body {
        padding-left: 20px;
        padding-left: 20px;
    }

    .modify-currency-modal__body-select {
        width: 180px;
        margin: 30px 0 20px 0;
    }

    .modify-currency-modal__body-select .form-select__label{
        margin-left: 0;
    }

.publish-estimate-modal {
    width: 300px;
}

    .publish-estimate-modal__body {
        padding-left: 20px;
        padding-left: 20px;
    }

    .modify-currency-modal__body-switch {
        width: 200px;
        margin: 30px 0 20px 0;
    }

        .modify-currency-modal__body-switch ul li{
            margin: 10px;           
        }

.quote-details-view-exteral-estimate__body {
    padding: 20px;
    padding-top: 0
}

.quote-details-view-exteral-estimate__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 7px;
} 

    .quote-details-view-revisions__header-date {
        font-size: 14px;
    }

    .quote-details-view-revisions__header-date img {
        margin-right: 5px;
    }

.quote-details-view-revisions__header-exports-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}

    .quote-details-view-revisions__header-exports {
        height: 31px;
        width: 100px;
        margin-right: 2px;
        cursor: pointer;
        border: none;
        border-radius: 5px;
        background-color: transparent;
    }

        .quote-details-view-revisions__header-exports img{
            height: 100%;
            width: 100%;
        }

    .quote-details-view-revisions__official-fee {
        display: flex;
        flex-direction: column;
    }

.exchange-rates-modal {
    background-color: var(--clr-white);
    border-radius: 10px;
    box-shadow: var(--shadow);
    width: 450px;
}
    .exchange-rates-modal__body{
        padding: 0 10px 0 10px;
        color: var(--clr-darkgrey);
        max-height: 400px;
        overflow-y: auto;
        font-size: 14px;
        position: relative;
    }

    .exchange-rates-modal__body-datepicker {
        width: 200px;
        margin-top: 10px;
    }

    .exchange-rates-modal__body-rates {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 15px;
    }

        .exchange-rates-modal__body-rates-field {
            max-width: 190px;
            margin: 4px 7px 0px 7px;
        }

            .exchange-rates-modal__body-rates-field-header {
                font-size: 16px;
                padding: 5px;
                font-weight: 500;
                display: flex;
            }

    .exchange-rates-modal__body-input {
        margin-top: 15px;
    }
    
    .exchange-rates-modal__footer {
        width: 80%;
        padding: 15px;
    }
   
/*
/*
.quote-details-admin-panel-revision__content{
    padding: 5px 30px;
}

    .quote-footer-a{
        position: fixed;
        bottom: 0px;
        height: 70px !important;
        width: 100% !important;
        padding-right: 50px !important;
        padding-bottom: 10px !important;
        background-color: var(--clr-background) !important;
        z-index: 3;
    }
    .quote-details-admin-panel-revision__content-stripe-header {
        display: flex;
        position: relative;
        flex-direction: row;
        background-color: var(--clr-grey);
        padding: 5px 10px;
        border-radius: var(--radius-small);
        font-size: 14px;
    }
    .quote-details-admin-panel-revision__content-stripe-header .action-menu {
        cursor: pointer;
        position: absolute;
        right: 15px;
    }
    .quote-details-admin-panel-revision__content-stripe-header div {
        margin-right: 15px;
    }
    .quote-details-admin-panel-revision__content-stripe-body {
        padding: 10px 20px;
        display: none;
    }
    .quote-details-admin-panel-revision__content-stripe-body.active {
        display: block;
    }
    .quote-details-admin-panel-revision__content-stripe-body table tr td {
        padding-top: 5px;
        padding-bottom: 5px;
        height: 46px;
    }
    .quote-details-admin-panel-revision__content-stripe-body table tr td .form-checkbox{
        margin-bottom: 10px;
    }

    .quote-details-admin-panel-revision__content-stripe-body-rules {
        width: 100%;
        display: flex;
        gap: 20px;
        margin-top: 15px;
    }

    .quote-details-admin-panel-revision__content-stripe-date-icon {
        padding-right: 10px;
    }

    .quote-details-admin-panel-revision__content-stripe-pricing-level-icon {
        display: flex;
        flex-direction: row;
    }

    .quote-details-admin-panel-revision__content-stripe-pricing-level-icon span{
        height: 20px;
        width: 24px;
        margin-right: 10px;
    }

    .quote-details-admin-panel-revision__content-stripe-pricing-level-icon span img{
        height: 100%;
        width: 100%;
    }
*/




