.legal-info-sidebar {
    height:calc(100% - 100px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 0 0 10px;
    position: fixed;
    width: inherit;
    overflow-y: auto;
}

    .legal-info-sidebar__contact__info span{
        color: var(--clr-darkgrey);
        margin-left: 8px;
    }

    .legal-info-sidebar__contact__info img{
        width: 13px;
    }

    .legal-info-sidebar__contact__info__item {
        margin-bottom: 15px;
    }

        .legal-info-sidebar__contact__info__item span a {
            color: var(--clr-darkgrey);
            font-size: 16px;
            font-weight: 400;
        }
        
.legal-info__footer {
    padding: 50px 0 50px 25px;
}
    .legal-info__footer p{
        font-size: 16px;
        color: var(--clr-midgrey);
    }