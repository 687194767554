.points-adjustment {
    display: flex;
    flex-direction: column;
    height: 440px;
}

    .points-adjustment__filter {
        display: flex;
        justify-content: space-between;
        gap: 15px;
    }

    .points-adjustment__body {
        min-width: 800px;
        overflow-y: auto;
        position: relative;
        width: 100%;
    }

        .points-adjustment__body__table {
            width: 100%;
        }

            .points-adjustment__body__table .fixed-header > th {
                top: 0px !important;
            }

            .points-adjustment__body__table .fixed-header th .form-checkbox,
            .points-adjustment__body__table .table-cell .form-checkbox {
                margin: 0;
            }

            .points-adjustment__body__table tr.active td {
                background-color: var(--clr-lightestblue);
            }

    .points-adjustment__multiedit-form {
        width: 100%;
        display: flex;
        background-color: var(--clr-lightgrey);
        border-radius: var(--radius);
        padding: 15px;
        margin-top: 15px;
        justify-content: flex-start;
        gap: 15px;
        box-shadow: 0 0 20px rgb(0 0 0 / 10%);
	    border: 1px solid var(--clr-grey);
    }

        .points-adjustment__multiedit-form__input {
            width: 180px;
            margin-bottom: 0;
        }
        
        .points-adjustment__multiedit-form__select {
            width: 250px;
            margin-bottom: 0;
        }

        .points-adjustment__multiedit-form__reason {
            width: 350px;
            margin-bottom: 0;
        }

        .points-adjustment__multiedit-form__actions {
            margin-left: auto;
            display: flex;
            justify-content: space-between;
            gap: 5px;
            align-items: flex-end;
        }

            .points-adjustment__multiedit-form__save {
                width: 28px;
                height: 28px;
                border-radius: 50%;
                background-color: var(--clr-green);
                border: none;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .points-adjustment__multiedit-form__cancel {
                width: 28px;
                height: 28px;
                border-radius: 50%;
                background-color: var(--clr-red);
                border: none;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            
            .points-adjustment__btn-rollback {
                background-image: url(../../../assets/images/icons/ico_back_white.svg);
                background-repeat: no-repeat;
                background-color: var(--clr-darkblue);
                background-size: 60%;
                background-position: center 5px;
                border: none;
                border-radius: 2px;
                width: 17px;
                height: 17px;
                display: inline-block;
                cursor: pointer;
                opacity: .2;
            }

            .points-adjustment__btn-rollback.active {
                opacity: 1;
            }

            .points-adjustment__row-reason-dropdown {
                width: 100%;
                margin: 0;
            }
            
            .form-select.points-adjustment__row-reason-dropdown .label {
                margin: 0;
            }
            
            .points-adjustment__row-reason-dropdown .form-select__error {
                display: none;
            }

            .points-adjustment__row-reason-input {
                width: 100%;
                margin: 0;
                display: flex;
            }
            
                .points-adjustment__row-reason-input .form-input,
                .points-adjustment__row-reason-input .form-input__label {
                    margin: 0;
                }

                .points-adjustment__row-reason-input button {
                    margin: 0;
                    border: none;
                    width: 25px;
                    margin-left: 5px;
                    border-radius: var(--radius-small);
                    background-color: var(--clr-darkblue);
                    color: var(--clr-white);
                    cursor: pointer;
                }

.adjustment-calculator {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}
    
    .adjustment-calculator__total {
        display: block;
        width: 55px;
    }

    .adjustment-calculator__operator {
        padding: 0 5px;
    }

    .adjustment-calculator__points-input {
        width: 100px;
        margin: 0;
    }

        .adjustment-calculator__points-input .form-input__label {
            margin: 0;
        }

        .form-input.adjustment-calculator__points-input .label {
            margin-bottom: 0;
        }

    .adjustment-calculator__result {
        font-weight: bold;
        display: block;
        width: 55px;
    }



    .points-adjustment__regions-filter .dropdown {
        z-index: 5;
    }