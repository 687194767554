.system-settings__preferences__header {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
    
    .system-settings__preferences__header span {
        font-weight: bold;
    }

.system-settings__preferences__body {
    width: 100%;
}   

    .system-settings__preferences__body form {
        display: flex;
        gap: 40px;
        margin: 10px auto 40px;
    }

    .system-settings__preferences__body form .form-input{
        width: 30%;
    }

    .system-settings__preferences__body .form-button {
        float: right;
    }
