.validation-rules__sidebar{
    padding: 0 15px;
}

.validation-rules__sidebar__header {
    color: var(--clr-midgrey);
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 15px;
}
 
    .validation-rules__sidebar__header img {
        margin-right: 5px;
    }

    .validation-rules__sidebar_actions {
        display: flex;
        justify-content: space-between;
        padding: 20px 0 0;
    }

.validation-rules__table {

}

    .validation-rules__table__separators span{
        margin-right: 10px;
    }

    .validation-rules__actions button {
        border: none;
        outline: none;
        background: transparent;
        cursor: pointer;
    }

        .validation-rules__actions button:first-child img {
            margin-right: 20px;
        }
  
  


/* ---------------------- Create Rule Modal ------------------------ */

.create-rule-modal__form__elements_wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    max-width: 600px;
}

.create-rule-modal__alert-message textarea {
    min-height: 100px;
}

.create-rule-modal__description textarea {
    min-height: 100px;
}

.validation-rules__regions-select {
    margin-bottom: 15px;
}

.create-rule-modal__content-wrapper {
    height: 600px;
    overflow-y: auto;
}

/* ---------------------- Delete Rule Modal ------------------------ */

.validation-rules__delete-modal__confirm-msg span{
    color: var(--clr-red);
}
