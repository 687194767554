.system-settings__integrations {

}

    .salesforce__link {
        cursor: pointer;
    }

    .system-settings__integrations__list li{
        list-style: auto;
    }