.form-icon-button {
    border-radius: 50%;
    outline: none;
    cursor: pointer;
    transition: all .3s ease-in-out;
    /* margin-left: 20px; */
    display: flex;
    align-items: center;
    justify-content: center;

        & img {
            height: 50%;
            width: 50%;
            margin: 25% auto;
            pointer-events: none;
        }

        &.primary {
            background-color: var(--theme-clr-primary);
            color: var(--theme-clr-base-light);
            border: none;
        }

        &.primary:hover {
            background-color: var(--theme-clr-primary-dark);
        }

        &.secondary {
            color: var(--theme-clr-base-light);
            border: none;
            background-color: var(--theme-clr-secondary);
        }

        &.secondary:hover {
            background-color: var(--theme-clr-secondary-dark);
        }

        &.ghost-primary {
            border: none;
            background-color: transparent;
            border: 1px solid var(--theme-clr-primary);
            color: var(--theme-clr-primary);
        }
        
        &.ghost-secondary {
            border: none;
            background-color: transparent;
            border: 1px solid var(--theme-clr-secondary);
            color: var(--theme-clr-secondary);
        }

        &.small {
            width: 30px;
            height: 30px;         
        }

        &.large {
            width: 40px;
            height: 40px;         
        }

        &:disabled {
            background-color: var(--theme-clr-base-mid1);
            cursor: not-allowed;
        }

        &:disabled:hover {
            background-color: var(--theme-clr-base-mid2);
        }
}
