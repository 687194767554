.add-regions-modal {
    width: 800px;
    overflow-y: auto;
}

    .add-regions-modal h4 {
        color: var(--clr-azami-violet);
    }

    .add-regions-modal-header {
        margin-bottom: 15px;
    }

    .add-regions-modal-new-regions {
        padding: 10px;
        margin-bottom: 10px;
        align-content: flex-start;
        flex-wrap: wrap;
        display: flex;
        gap: 15px;
    }

        .add-regions-modal-new-regions li {
            height: 30px;
            background-color: var(--clr-azami-blurple);
            border-radius: 15px;
            display: flex;
            align-items: center;
            padding: 5px 10px;
            color: var(--clr-white);
        }

        .add-regions-modal-new-regions li span {
            margin-bottom: 0;
        }

        .add-regions-modal-new-regions li img {
            height: 10px;
            margin-right: 5px;
        }
    
        .add-regions-modal-new-regions li button {
            box-sizing: border-box;
            vertical-align:middle;
            height: 13px;
            width: 13px;
            border: none;
            outline: none;
            color: var(--clr-white);
            border-radius: 50%;
            font-size: 12px;
            margin-left: 5px;
            font-weight: bolder;
            cursor: pointer;
            color: var(--clr-azami-blurple);
        }

    .add-regions-modal-attachements {
        margin-bottom: 15px;
        display: flex;
        flex-direction: column;
    }

        .add-regions-modal-attachements-header {
            margin-bottom: 10px;
            font-weight: bold;
            display: flex;
        }

        .add-regions-modal-attachements-header span {
            margin-right: 5px;
            cursor: pointer;
        }

        .add-regions-modal-attachements-header div {
            cursor: pointer;
        }
        .add-regions-modal-attachements-no-attachements {
            color: var(--clr-midgrey);
            font-size: 14px;
        }

    .add-regions-modal-bottom {
        display: flex;
        flex-direction: row;
        gap: 10px;
    }

        .add-regions-modal-bottom-additional-information {
            width: 55%;
        }

            .add-regions-modal-bottom-additional-information label, .add-regions-modal-bottom-additional-information label textarea{
                height: 80%;
            }

        .add-regions-modal-bottom-checkbox {
            width: 45%;
        }

            .add-regions-modal-bottom-checkbox label span.form-checkbox__label.error {
                color: var(--clr-red);
                width: calc(100% - 30px);
            }
        