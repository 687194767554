.user-sidebar {
    display: flex;
    flex-direction: column;
    width: 300px;
    justify-content: center;
    align-items: center;
}

.user-add-content {
    display: flex;
    flex-direction: column;
    height: 100%;
}

    .user-add-content__header {
        width: 100%;
        padding: 15px;
    }

    .user-add-content__body {
        flex-grow: 1;
        width: 100%;
        padding: 0 30px 15px 30px;
        display: flex;
        /* gap: 44px; */
    }

    .user-add-content__footer {
        width: 100%;
        height: 55px;
        background-color: var(--clr-grey);
        border-radius: 0 0 0 var(--radius);
        padding: 0 15px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-direction: row;
    }    
