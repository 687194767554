.reports-list {
    display: flex;
    flex-wrap: wrap;
}

    .reports-list a {
        display: flex;
        background-color: var(--clr-white);
        margin: 10px 20px 10px 0;
        padding: 20px;
        border-radius: var(--radius-small);
        box-shadow: var(--shadow);
        width: 300px;
        text-decoration: none;
        min-height: 115px;
        align-items: flex-start;
    }

.report-list__icon {
    width: 50px;
    margin-right: 10px;
}

.report-list__text-box {
    display: flex;
    flex-direction: column;
}

.report-list__report-title {
    /* color: var(--clr-darkblue); */
    color: var(--clr-azami-blue);
    display: block;
    width: 100%;
    font-weight: 600;
}

.report-list__report-description {
    font-size: 14px;
    display: block;
    color: var(--clr-midgrey);
    margin-top: 10px;
}
