.notification-layer {
    bottom: 0;
    right: 0;
    z-index: 2000;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    transition: all .2s ease-in-out;

    .remove-all {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background-color: var(--theme-clr-base-light);
        padding: 0 15px;
        border-radius: 15px;
        border: none;
        box-shadow: 0px 3px 15px rgba(0, 0, 0, .15);
        cursor: pointer;
        margin: 10px;
        width: 300px;
        height: 30px;
        color: var(--theme-clr-base-mid6);

        .close {
            font-size: 20px;
            margin-right: auto;
        }
    }
}