.thankyou-container {
    width: 600px;
}

.resend-email-container {
    padding-top: 20px;
    display: flex;
    gap: 20px;
    align-items: center;
}

.thankyou-container .thankyou-container__footer {
    background: var(--clr-green-background);
    box-shadow: var(--shadow);
    margin-top: 20px;
    padding: 30px;
    color: var(--clr-darkgrey);
    line-height: 22px;
    width: 100%;
    display: flex;
    flex-direction: row;
}

.thankyou-container__footer .thankyou-container__footer__icon {
    width: 22px;
    height: 22px;
    margin-left: -7px;
    margin-right: 5px;
}