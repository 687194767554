.form-multiselect {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    position: relative;

    .label:first-of-type {
        margin-bottom: 8px;
        margin-left: 5px;
        font-size: 13px;
        font-weight: 500;
        text-transform: capitalize;
        display: flex;
        align-items: center;
        color: var(--theme-clr-base-dark);
    }

    .input {
        position: relative;

        .togglable {
            width: 100%;
            border-width: 1px;
            border-style: solid;
            border-radius: var(--theme-input-border-radius);
            outline: none;
            height: 37px;
            transition: all .3s ease-in-out;
            color: var(--theme-clr-base-mid6);
            padding: 8px 30px 8px 8px;
            -moz-appearance: none;
            -webkit-appearance: none;
            appearance: none;
            background-image: url(../../assets/images/icons/ico_dropdown_arrow.svg);
            background-repeat: no-repeat;
            background-position-y: center;
            background-position-x: calc(100% - 12px);
            user-select: none;
            line-height: 18px;
            border-color: var(--theme-input-border-color);
            background-color: var(--theme-clr-base-light);
            
            .title {
                font-size: var(--theme-font-size-mid);
            }
        }

        .dropdown {
            position: absolute;
            width: 100%;
            max-height: 250px;
            background-color: var(--theme-clr-base-light);
            z-index: 3;
            display: flex;
            flex-direction: column;
            top: 42px;
            box-shadow: var(--theme-clr-shadow-popup);
            padding: 5px 0;
            border-radius: var(--theme-border-radius-small);

            .selected {
                display: flex;
                flex-wrap: wrap;
                padding: 0 5px;
                justify-content: flex-start;
                gap: 5px;
                margin-bottom: 5px;

                .item {
                    display: flex;
                    border-radius: var(--theme-input-border-radius);
                    /* background-color: var(--theme-clr-primary); */
                    color: var(--theme-clr-base-light);
                    font-size: var(--theme-font-size-small);
                    font-weight: 700;
                    padding: 3px 5px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .rest {
                    display: flex;
                    border-radius: var(--theme-input-border-radius);
                    background-color: var(--theme-clr-base-mid1);
                    color: var(--theme-clr-primary-dark);
                    font-size: var(--theme-font-size-small);
                    font-weight: 700;
                    padding: 3px 5px;
                }
            }

            .select-list {
                flex-grow: 1;
                width: 100%;
                overflow-y: auto;
                padding: 5px;

                li {
                    display: flex;
                    font-size: var(--theme-font-size-mid);
                    padding: 6px 0 0 10px;
                    border-radius: var(--theme-border-radius-small);
                    transition: all .15s ease-in-out;

                    &:hover {
                        background-color: var(--theme-clr-base-mid1);
                    }
                }

                li .form-checkbox {
                    width: 100%;
                    margin: 0;

                }
            }
        }
    }

    &.error {        
        .togglable {
            border: 1px solid var(--theme-clr-palette-red);
            border-color: var(--theme-clr-palette-red) !important;
            box-shadow: var(--theme-clr-shadow-red);
        }
        
        .error-label {
            font-size: var(--theme-font-size-small);
            color: var(--theme-clr-palette-red);
            display: block;
            text-align: right;
            position: absolute;
            top: -15px;
            right: 5px;
        }
    }

    &.primary {
        .item {
            background-color: var(--theme-clr-primary);
        }
        &:focus-within {
            .togglable {
                border-color: var(--theme-clr-primary);
                box-shadow: var(--theme-clr-shadow-primary);
            }
        }
    }
    
    &.secondary {
        .item {
            background-color: var(--theme-clr-secondary);
        }
        &:focus-within {
            .togglable {
                border-color: var(--theme-clr-secondary);
                box-shadow: var(--theme-clr-shadow-secondary);
            }
        }
    }
    

    &.dark {
        .togglable {
            background-color: var(--theme-input-dark-background-color);
            border-color: transparent;
            color: var(--theme-clr-base-dark);
        }

        &:focus-within {
            .togglable {
                border-color: var(--theme-input-dark-border-color);
                box-shadow: var(--theme-clr-shadow-inner);
            }
        }

        .item {
            background-color: var(--theme-input-dark-background-color);
            color: var(--theme-clr-base-mid6) !important;
        }
    }
}
