.quote-actions-header {
    width: 100%;
    height: 55px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
}

.quote-actions-header .form-button img {
    vertical-align: bottom;
    margin: 0 5px 0 0;
}

.quote-actions-footer {
    justify-content: flex-end;
    display: flex;
}

.quote-actions-footer.fixed {
    right: 30px;
    bottom: 10%;    
    display: inline;
    position: fixed;
}

.quote-actions-footer .form-button {
    min-width: 110px;
    min-height: 33px;
}

.new-quote__footer__btn-margin {
    margin-right: 15px;
}