.system-settings-sidebar {
    display: flex;
    flex-direction: column;
    width: 300px;
    justify-content: center;
    align-items: center;
}

    .system-settings-sidebar__navigation {
        width: 90%;
        margin-top: 10px;
    }

        .system-settings-sidebar__navigation__link {
            display: flex;
            width: 100%;
            height: 34px;
            align-items: center;
            text-decoration: none;
            color: var(--clr-midgrey);
            font-size: 14px;
            border-radius: var(--radius-small);
            padding-left: 10px;
            margin-bottom: 3px;
        }

        .system-settings-sidebar__navigation__link:hover {
            background-color: var(--clr-lightgrey);
        }

        .system-settings-sidebar__navigation__link.active {
            background-color: var(--clr-midlightgrey);
            color: var(--clr-azami-blurple);
        }

            .system-settings-sidebar__navigation__link__icon {
                margin-right: 10px;
                width: 20px;
                height: 20px;
                object-fit:scale-down;
            }
            .system-settings-sidebar__navigation__link.active .firm-settings-sidebar__navigation__link__icon {
                filter: brightness(0.7) sepia(1) hue-rotate(158deg) saturate(2)
            }
            
            .system-settings-sidebar__navigation__link__label {
                text-transform: capitalize;
            }

.system-details-content {
    display: flex;
    flex-direction: column;
}

    .system-details-content__header {
        width: 100%;
        padding: 15px;
    }

        .system-details-content__header nav {
            width: 100%;
            background-color: var(--clr-azami-blue-glass);
            border-radius: var(--radius-small);
            padding: 5px 7px;
            display: flex;
            gap: 5px;
        }

            .system-details-content__header nav a {
                color: var(--clr-azami-blue);
                text-decoration: none;
                font-size: 14px;
                display: inline-block;
                padding: 5px 8px;
                background-color: transparent;
                border-radius: var(--radius-small);
                text-transform: capitalize;
                transition: all .15s ease-in-out;
                position: relative;
            }

            .system-details-content__header nav a.active {
                background-color: var(--clr-azami-blue);
                color: var(--clr-lightblue);
            }

            .system-details-content__header nav a:hover {
                background-color: rgba(255, 255, 255, .5);
            }

            .system-details-content__header nav a.active:hover {
                background-color: var(--clr-darkblue);
                color: var(--clr-lightblue);
            }

                .system-details-content__header__update-indicator {
                    display: block;
                    position: absolute;
                    background-color: var(--clr-orange);
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    top: -2px;
                    right: -2px;
                } 

    .system-details-content__body {
        flex-grow: 1;
        width: 100%;
        padding: 0 30px 15px 30px;
        display: flex;
        /* gap: 44px; */
    }

        .system-details-content__body__title {
            font-size: 16px;
            font-weight: 500;
            color: var(--clr-darkgrey);
            margin-bottom: 20px;
        }

    .system-details-content__footer {
        width: 100%;
        height: 55px;
        background-color: var(--clr-grey);
        border-radius: 0 0 0 var(--radius);
        padding: 0 15px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-direction: row;
    }
    
        .system-details-content__footer  button {
            position: fixed;
            bottom: 10px;
            right: 30px;
            z-index: 1;
        }


.settings-colapsible {
    width: 100%;
    margin-bottom: 15px;
}

    .settings-colapsible__header {
        width: 100%;
        background-color: var(--clr-midlightgrey);
        color: var(--clr-azami-blurple);
        height: 34px;
        display: flex;
        align-items: center;
        border-radius: var(--radius-small);
        padding: 0 15px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 700px;
        text-transform: uppercase;
        outline:none;
    }
        .settings-colapsible__header.open {
            background-color: var(--clr-azami-blue-light);
        }

        .settings-colapsible__header i {
            display: flex;
            padding: 5px;
            margin-right: 5px;
        }

        .settings-colapsible__header span {
            background-color: var(--clr-azami-blurple);
            color: var(--clr-white);
            font-weight: bold;
            border-radius: var(--radius-small);
            padding: 2px;

            min-width: 25px;
            text-align: center;
            display: inline-block;
            margin-left: 15px;
        }

    .settings-colapsible__body {
        margin-top: 15px;
        padding: 5px 15px 20px 15px;
        border-bottom: 1px solid var(--clr-midlightgrey);
    }

        .settings-colapsible__body__subtitle {
            font-size: 14px;
            font-weight: 500;
        }

        .settings-colapsible__body__section {
            display: flex;
            justify-content: flex-start;
            gap: 20px;
        }

        .settings-colapsible__body blockquote {
            padding-left: 15px;
        }

    .settings-colapsible__body__tabs {
        width: 100%;
        background-color: var(--clr-lightgrey);
        display: flex;
        align-items: center;
        border-radius: var(--radius-small);
        overflow: hidden;
    }

        .settings-colapsible__body__tabs li {
            color: var(--clr-azami-blurple);
            font-size: 14px;
            font-weight: 600;
            width: 100%;
            text-align: center;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }

        .settings-colapsible__body__tabs li.active {
            background-color: var(--clr-azami-blue-light);
        }

    .settings-colapsible__body__info {
        display: flex;
        align-items: center;
        gap: 5px;
    }

        .settings-colapsible__body__info span {
            font-style: italic;
            font-size: 12px;
        }