.status-progress-bar__wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

    .status-progress-bar {
        display: flex;
        align-items: center;
        justify-content: center;
    }

        .status-progress-bar__circle {
            display: flex;
            align-items: center;
            justify-content: center; 
            font-size: 11px;       
            cursor: pointer;
            position: relative;
        }

        .status-progress-bar__line {
            width: 15px;
            height: 2px;
            background-color: var(--clr-azami-blue);
        }

        .status-progress-bar__line:last-child {
            display: none;
        }

    .status-progress-bar__wrapper__current_status {
        font-size: 11px;
        color: var(--clr-azami-blue);
        margin-top: 2px;
    }

    .status-progress-bar__tooltip {
        width: 300px;
        background-color: rgb(0, 0, 0);
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px 10px;
        position: absolute;
        z-index: 2;
        top: -30px;
    }

        .status-progress-bar__tooltip::after {
            content: "";
            position: absolute;
            top: 100%;
            left: 80%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: rgb(0, 0, 0) transparent transparent transparent;
            left: 50%;
        }