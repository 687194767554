.letter-badge {
    display: block;
    width: 26px;
    height: 26px;
    line-height: 26px;
    text-transform: uppercase;
    text-align: center;

        &.round {
            font-weight: bold;
            font-size: 16px;
            color: var(--theme-clr-primary-light);
            border-radius: 50%;
        }

        &.inverse {
            font-size: 20px;
            font-weight: 800;
        }
}
