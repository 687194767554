.form-button {
    border-radius: var(--theme-button-border-radius);
    min-width: 100px;
    outline: none;
    cursor: pointer;
    transition: all .15s ease-in-out;
    text-decoration: none;
    text-align: center;

    &:disabled {
        cursor: not-allowed;
        opacity: .3;
    }

    &.small {
        padding: 6px 16px;
        font-size: 14px;
    }

    &.medium {
        padding: 8px 22px;
        font-size: 16px;
    }

    &.large {
        padding: 10px 28px;
        font-size: 18px;
    }

    &.confirm {
        background-color: var(--theme-clr-palette-green);
        color: var(--theme-clr-base-light);
        border: none;
    }

    &.destructive {
        background-color: var(--theme-clr-palette-red);
        color: var(--theme-clr-base-light);
        border: none;
    }

    &.fullwidth {
        width: 100%;
    }

    &.ghost-primary {
        border: none;
        background-color: transparent;
        border: 1px solid var(--theme-clr-primary);
        color: var(--theme-clr-primary);
    }
    
    &.ghost-secondary {
        border: none;
        background-color: transparent;
        border: 1px solid var(--theme-clr-secondary);
        color: var(--theme-clr-secondary);
    }

    &.primary {
        background-color: var(--theme-clr-primary);
        color: var(--theme-clr-base-light);
        border: none;
    }

    &.secondary {
        color: var(--theme-clr-base-light);
        border: none;
        background-color: var(--theme-clr-secondary);
    }

    &.special {
        color: var(--theme-clr-base-light);
        border: none;
        background: var(--theme-clr-gradient);
    }

    &.light-primary {
        color: var(--theme-clr-primary-dark);
        border: none;
        background: var(--theme-clr-primary-light);
    }

    &.light-secondary {
        color: var(--theme-clr-secondary-dark);
        border: none;
        background: var(--theme-clr-secondary-light);
    }

    &:hover {
        box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.15);
    }

    &:active {
        box-shadow: 
        inset 0 0 100px 100px rgba(255, 255, 255, 0.15),
        inset 0 0 20px rgba(0, 0, 0, .35);
    }

    &.has-spinner {
        background: var(--theme-clr-primary);
    }

}