.client-onboarding {
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 100%;
    gap: 20px;
}

    .client-onboarding__group {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

        .client-onboarding__group-main {
            display: flex;
            justify-content: space-between;
        }

        .client-onboarding__group-main.columns {
            flex-direction: column;
        }

        .client-onboarding__group-info {
            color: var(--clr-darkgrey);
        }

        .client-onboarding__group-info.italic {
            display: flex;
            align-items: flex-start;
            font-style: italic;
            gap: 4px;
        }

            .client-onboarding__group-info-icon {
                margin-top: 2px;
            }

            .client-onboarding__group-main__label {
                font-size: 18px;
                text-transform: capitalize;
                color: var(--clr-darkgrey);
                font-weight: bold;
            }

            .client-onboarding__group-main__download {
                color: var(--clr-azami-blurple);
                display: flex;
                gap: 5px;
                align-items: center;
                border-bottom: 3px solid var(--clr-azami-blurple);
                font-size: 18px;
                text-decoration: none;
            }