.user-sidebar {
    display: flex;
    flex-direction: column;
    width: 300px;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

    .user-sidebar__image-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 90px;
        height: 90px;
        position: relative;
        margin-top: 3px;
    }

        .user-sidebar__image-container__avatar {
            width: 90px;
            height: 90px;
            background-color: var(--clr-grey);
            border: none;
            border-radius: 50%;
            outline: none;
            object-fit: cover;
        }

        .user-sidebar__image-container__btn-change-avatar {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: var(--clr-azami-blue);
            position: absolute;
            /* border: 4px solid var(--clr-azami-violet); */
            border: none;
            right: -5px;
            top: -5px;
            cursor: pointer;
            background-image: url(../../../assets/images/icons/ico_upload_avatar.svg);
            background-repeat: no-repeat;
            /* background-position: 6px 4px; */
            background-position: center center;
        }

    .user-sidebar__user-info {
        display: flex;
        flex-direction: column;
        padding-top: 15px;
        margin-bottom: 50px;
    }

        .user-sidebar__user-info__name {
            display: block;
            color: var(--clr-darkgrey);
            font-weight: bold;
            text-align: center;
        }

        .user-sidebar__user-info__positon-company {
            font-size: 14px;
            color: var(--clr-midgrey);
            margin-top: 5px;
            text-align: center;
        }

        .user-sidebar__user-info__company-website {
            font-size: 14px;
            color: var(--clr-blue);
            margin-top: 5px;
            display: inline-block;
            width: 100%;
            text-align: center;
        }

        .user-sidebar__user-info__company-website:hover {
            text-decoration: none;
        }

    .user-sidebar__section {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0 0 0 15px;
    }

        .user-sidebar__section__title {
            font-size: 14px;
            color: var(--clr-midgrey);
            font-weight: bold;
            text-transform: uppercase;
            line-height: 14px;
            display: flex;
            width: 100%;
            margin-bottom: 15px;
        }

            .user-sidebar__section__title img {
                margin-right: 10px;
            }

        .user-sidebar__section__body__social-links {
            background-color: var(--clr-grey);
            border-radius: var(--radius);
            padding: 15px;
        }

            .user-sidebar__section__body__social-links li {
                margin-bottom: 10px;
            }

            .user-sidebar__section__body__social-links li:last-of-type {
                margin-bottom: 0;
            }
        
        .user-sidebar__uploader {
            width: 100%;
            padding: 30px 0 0 15px;
        }

        .user-sidebar__modal__message span {
            color: var(--clr-red)
        }

    .user-sidebar__section__body__social-links .twitter {
        color: #00ACEE;
    }

    .user-sidebar__section__body__social-links .linkedin {
        color: #0E76A8;
    }

    .user-sidebar__section__body__social-links .facebook {
        color: #3B5998;
    }

.user-details-content {
    display: flex;
    flex-direction: column;
    height: 100%;
}

    .user-details-content__header {
        width: 100%;
        padding: 15px 15px 0 15px;
        margin-bottom: 15px;
    }

        .user-details-content__header nav {
            width: 100%;
            /* background-color: var(--clr-lightblue); */
            background-color: var(--clr-azami-blue-glass);
            border-radius: var(--radius-small);
            padding: 5px 7px;
            display: flex;
            gap: 5px;
        }

            .user-details-content__header nav a {
                /* color: var(--clr-darkblue); */
                color: var(--clr-azami-blue);
                text-decoration: none;
                font-size: 14px;
                display: inline-block;
                padding: 5px 8px;
                background-color: transparent;
                border-radius: var(--radius-small);
                text-transform: capitalize;
                transition: all .15s ease-in-out;
                position: relative;
            }

            .user-details-content__header nav a.active {
                /* background-color: var(--clr-darkblue); */
                background-color: var(--clr-azami-blue);
                color: var(--clr-lightblue);
            }

            .user-details-content__header nav a:hover {
                background-color: rgba(255, 255, 255, .5);
            }

            .user-details-content__header nav a.active:hover {
                background-color: var(--clr-azami-blue-hover);
                color: var(--clr-lightblue);
            }

                .user-details-content__header__update-indicator {
                    display: block;
                    position: absolute;
                    background-color: var(--clr-orange);
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    top: -2px;
                    right: -2px;
                }
                
                .user-details-content__header__error-indicator {
                    display: block;
                    position: absolute;
                    background-color: var(--clr-red);
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    top: -2px;
                    right: -2px;
                }

    .user-details-content__body {
        flex-grow: 1;
        width: 100%;
        /* padding: 0 10px 15px 10px; */
        padding: 0 15px 0px 30px;
        display: flex;
        /* gap: 44px; */
        margin-bottom: 20px;
    }

    .user-details-content__footer {
        width: 100%;
        height: 55px;
        background-color: var(--clr-grey);
        border-radius: 0 0 0 var(--radius);
        padding: 0 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
    }    

        .user-details-content__footer > label { /* THIS IS AN OVERRIDE OF THE STANDARD CHECKBOX STYLE */
            margin: 0;
        }

        .user-details-content__footer__checkbox-label {
            color: var(--clr-midgrey);
        }

            .user-details-content__footer__checkbox-label .label a {
                color: var(--clr-darkgrey);
            }

            .user-details-content__footer__checkbox-label a:hover {
                text-decoration: none;
            }

/* *************************************************************************************************************** */
/* ****************************************** UserDetails Main *************************************************** */
/* *************************************************************************************************************** */

.user-details-main {
    gap: 44px;
    display: flex;
    width: 100%;
}

    .user-details-main__section-title {
        font-size: 16px;
        color: var(--clr-darkgrey);
    }

    .user-details-main__left {
        width: 560px;

    }

    .user-details-main__right {
        padding-top: 19px;
        width: 265px;
    }

    .user-details-main__input-list {
        padding-top: 15px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .user-details-main__input-list__half {
        width: 265px;
    }

    .user-details-main__input-list__textarea textarea {
        min-height: 120px;
    }

    .user-details-main__input-list__firm-link {
        display: block;
        width: 100%;
        padding-top: 27px;
        padding-bottom: 24px;
        /* color: var(--clr-darkblue); */
        color: var(--clr-azami-blue);
        position: relative;
        font-weight: bold;
    }
    
    .user-details-main__input-list__firm-link:hover {
        text-decoration: none;
    }

    .user-details-main__input-list__firm-link::after {
        display: block;
        content: '';
        background-image: url(../../../assets/images/icons/link.svg);
        background-repeat: no-repeat;
        background-size: cover;
        width: 15px;
        height: 15px;
        position: absolute;
        top: 29px;
        right: 0;
    }

/* *************************************************************************************************************** */
/* *********************************** UserDetails Expertise & Regions ******************************************* */
/* *************************************************************************************************************** */

.user-details-expertise {
    gap: 44px;
    display: flex;
    width: 100%;
}

/* *************************************************************************************************************** */
/* ****************************************** UserDetails Rates ************************************************** */
/* *************************************************************************************************************** */

.user-details-rates {
    gap: 44px;
    display: flex;
    width: 100%;
    flex-direction: column;
}

/* *************************************************************************************************************** */
/* ************************************* UserDetails Points ********************************************** */
/* *************************************************************************************************************** */

.user-details-points {
    /* gap: 44px; */
    display: flex;
    width: 100%;
    flex-direction: column;
}

.user-details-points__section-header {
    width: 100%;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0;
}

    .user-details-points__section-header__title {
        display: flex;
        color: var(--clr-darkgrey);
        font-size: 16px;
        font-weight: bold;
    }

    .user-details-points__section-body {
        margin-top: 15px;
    }
    
    .user-details-points__section-header__directions {
        display: flex;
        margin: 0 auto 0 30px;
    }

    .user-details-points__section-header__directions .dir-filter {
        margin: 0 5px;
        color: rgb(185,188,182);
        text-decoration: none;
        font-size: 14px;
        cursor: pointer;
    }

    .user-details-points__section-header__directions .dir-filter.active {
        color: var(--clr-darkblue);
        font-weight: bold;
    }

    .user-details-points__section-body {
        margin-top: 15px;
    }

    /* .user-details-points__section-body .is-adjusted-row .points {
        position: relative;
    }

    .user-details-points__section-body .is-adjusted-row .points::before {
        content: "A";
        color: #fff;
        background-color: var(--clr-darkblue);
        border-radius: 10px;
        box-shadow: 0px 2px 3px #aaa;
        width: 17px;
        height: 17px;
        line-height: 18px;
        display: inline-block;
        text-align: center;
        font-size: 12px;
        position: absolute;
        right: -10px;
        top: -10px;
    } */

    .user-details-points__section-body span.plus {
        color: green;
    }

    .user-details-points__section-body span.minus {
        color: red;
    }

    .user-details-points__section-body .adjusted-cell {
        display: flex;
        flex-direction: column;
    }

    .user-details-points__section-body span.adjusted-date {
        font-size: 12px;
        color: rgb(185,188,182);
        margin-bottom: 2px;
    }
    
    .user-details-points__section-body .adjust,
    .user-details-points__section-body .adjust:hover {
        cursor: default;
        background-image: url(../../../assets/images/icons/ico_btn_edit_lightgrey.svg);
        background-repeat: no-repeat;
        background-color: transparent;
        border: none;
        border-radius: 0;
        width: 19px;
        height: 17px;
        display: inline-block;
        cursor: pointer;
    }
    
    .user-details-points__section-footer {
       text-align: center;
    }

    .user-details-points__section-footer a.client-report {
        background-color: rgb(229,229,229);
        color: rgb(150,150,150);
        text-decoration: none;
        padding: 5px 25px;
        font-size: 12px;
        margin: 10px auto;
        border-radius: 5px;
        text-transform: uppercase;
    }

    .adjustment-row{
        background-color: var(--clr-adjustment-table-row);
    }




    .adjust-user-points-modal {
        width: 80%;
        background-color: var(--clr-white);
        overflow: hidden;
        border-radius: 10px;
        box-shadow: var(--shadow);
        /* padding: 20px; */
    }
    
        .adjust-user-points-modal__header {
            background-color: var(--clr-darkblue);
            padding: 14px 16px;
            display: flex;
            align-items: center;
        }
    
            .adjust-user-points-modal__header img {
                margin-right: 14px;
            }
    
            .adjust-user-points-modal__header h4 {
                color: var(--clr-white);
            }
            
            .adjust-user-points-modal__header button {
                margin-left: auto;
                background-color: transparent;
                outline: none;
                border: none;
                color: var(--clr-white);
                font-size: 20px;
                cursor: pointer;
                width: 20px;
            }
    
        .adjust-user-points-modal__body {
            padding: 26px 16px;
            color: var(--clr-darkgrey);
            /* min-height: 150px; */
            max-height: 400px;
            overflow-y: auto;
        }
    
            .adjust-user-points-modal__body__filters {
                margin: 0 0 20px;
            }
    
            .adjust-user-points-modal__body__filters .react-datepicker-wrapper,
            .adjust-user-points-modal__body__filters .form-input,
            .adjust-user-points-modal__body__filters .form-select,
            .adjust-user-points-modal__body__filters .form-select-checkboxes  {
                display: inline-table;
                width: 15%;
                margin: 0 2% 0 0;
                box-sizing: border-box;
            }
    
            .adjust-user-points-modal__body__filters .form-select:last-child {
                margin: 0;
            }
    
            .adjust-user-points-modal__body__filters .form-input__label,
            .adjust-user-points-modal__body__filters .form-select-checkboxes__label ,
            .adjust-user-points-modal__body__filters .form-select__label {
                display: block;
            }
    
            .adjust-user-points-modal__body .data-table .form-select-checkboxes  {
                margin: 0;
            }
    
            .adjust-user-points-modal__body .data-table  .form-input {
                width: 100px;
                margin: 0;
            }
    
            .adjust-user-points-modal__body .data-table  .form-input input {
                width: 100%;
            }
    
            .adjust-user-points-modal__body .data-table .form-select {
                margin: 0;
            }
     
            .adjust-user-points-modal__body .data-table td:last-child .table-cell {
                flex-direction: column;
            }

            .adjust-user-points-modal__body .data-table td:last-child .table-cell .form-input {
                width: 100%;
            }

            .adjust-user-points-modal__body .data-table .custom-reason-link {
                color: var(--clr-darkblue);
                margin: 5px 0;
                font-size: 12px;
                cursor: pointer;
            }
    
        .adjust-user-points-modal__footer {
            background-color: var(--clr-lightgrey);
            padding: 14px 16px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
    
            .adjust-user-points-modal__footer button {
                margin-left: 10px;
            }

/* ****************************************************************************************** */
/* ********************************* USER RECIPROCITY MAIN ********************************** */
/* ****************************************************************************************** */

.firm-details-reciprocity {
    width: 100%;
}

    .firm-details-reciprocity__ratio-points-switch {
        margin-bottom: 15px;
        display: flex;
        align-items: center;
    }

        .firm-details-reciprocity__ratio-points-switch__label {
            font-size: 14px;
            display: block;
            padding-left: 8px;
        }


.user-details-prefs_assoc {
    /* gap: 44px; */
    display: flex;
    width: 100%;
    flex-direction: column;
}

.user-details-prefs_assoc__section-header {
    width: 100%;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0;
}

    .user-details-prefs_assoc__section-header__title {
        display: flex;
        color: var(--clr-darkgrey);
        font-size: 16px;
        font-weight: bold;
    }

    .user-details-prefs_assoc__section-body {
        margin-top: 15px;
    }

    .user-details-prefs_assoc__section-body .data-table thead th,
    .user-details-prefs_assoc__section-body .data-table tbody td {
        width: 22%;
    }

    .user-details-prefs_assoc__section-body .data-table thead th:last-child,
    .user-details-prefs_assoc__section-body .data-table tbody td:last-child {
        width: 12%;
        padding-right: 10px;
    }

    .user-details-prefs_assoc__section-body .data-table tbody td:last-child .table-cell {
        justify-content: right;
    }

    .data-table tbody tr.user-details-prefs_assoc__section-body__add-form,
    .data-table tbody tr.user-details-prefs_assoc__section-body__add-form:hover {
        background-color: var(--clr-lightblue);
    }

    .data-table tbody tr.user-details-prefs_assoc__section-body__add-form td {
        padding: 7px 15px 0;
    }

    .user-details-prefs_assoc__section-body__add-form-submit.form-button {
        min-width: 70px;
    }

    .user-details-prefs_assoc__section-body__add-form-cancel.form-button,
    .user-details-prefs_assoc__section-body__add-form-cancel.form-button:hover {
        background: none transparent;
        border: none;
        min-width: 20px;
        padding: 0;
        margin-left: 10px;
    }    

    .user-details-prefs_assoc__section-body__button-delete {
        border: none;
        background: none transparent;
        cursor: pointer;
    }
/* ****************************************************************************************** */
/* ********************************* USER COMMUNICATION PREFERENCES ************************* */
/* ****************************************************************************************** */

.user-details-comm_preferences {
    display: flex;
    width: 100%;
    flex-direction: column;
}
    .user-details-comm_preferences__section-header__title {
        display: flex;
        color: var(--clr-darkgrey);
        font-size: 16px;
        font-weight: bold;
    }

    .user-details-comm_preferences__section-header__subtitle {
        margin-top: 20px;
        font-size: 14px;
    }
   .user-details-comm_preferences__documents {
    display: flex;
    flex-direction: row;
   }

   .user-details-comm_preferences__reports {
    display: flex;
    flex-direction: row;
   }

   .user-details-comm-preferences__submit-button {
    width: 70px;
   }

   .user-details-comm_preferences__auto-approve-associate {
    margin-top: 10px;
   }





