.user-menu {
    position: relative;
    background-color: transparent;
    border: none;
    display: block;

    .avatar {
        width: 38px;
        height: 38px;
        border-radius: 50%;
        border: 2px solid var(--theme-clr-base-light);
        margin-top: 10px;
        object-fit: cover;
        cursor: pointer;
        object-fit: cover;
    }

    .items-container {
        background-color: var(--theme-clr-base-light);
        padding: 10px 0;
        border-radius: var(--theme-border-radius-small);
        position: absolute;
        top: 70px;
        right: -100px;
        min-width: 300px;
        box-shadow: var(--theme-clr-shadow-primary);

        .arrow {
            display: block;
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid var(--theme-clr-base-light);
            position: absolute;
            top: -9px;
            left: 171px;
        }

        .items {
            display: flex;
            flex-direction: column;

            .link {
                margin: 0;
            }

            li {
                color: var(--theme-clr-base-mid6);
                padding: 10px 15px;
                transition: all .2s ease-in-out;
                cursor:pointer;
                text-align: left;
                display: flex;
                align-items: center;
                width: 100%;
                font-size: 15px;

                img {
                    margin-right: 10px;
                }

                a {
                    color: var(--theme-clr-base-mid6);
                    opacity: 1;
                    text-decoration: none;

                    &:nohover::after {
                        display: none;
                    }

                    &.user-details {
                        margin-left: 0px;
                    }
                }
            }

            li:hover {
                background-color: var(--theme-clr-primary-light);
            }
        }

        a:hover::after {
            display: none;
        }
    }
}