.main-screen-section.settings-email-templates__list-view {
    padding: 20px;
}

.settings-email-templates__filters {
    width: 100%;
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    align-items: flex-end;
} 
    .settings-email-templates__filters > label {
        width: 25%;
    }

    .form-input.text-search input {
        background-image: url(../../../assets/images/icons/ico_btn_search_lightgrey.svg);
        background-repeat: no-repeat;
        background-position: 10px 10px;
        padding: 0 0 0 30px;
    }

.system-settings-email-templates__body {
    padding: 0 15px;
    width: 100%;
}

.system-settings-email-templates__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px 20px;
} 

    .system-settings-email-templates__header__title {
        font-weight: bold;
    }

.system-settings-email-templates-main-edit-btn,
.system-settings-email-templates-main-duplicate-btn,
.system-settings-email-templates-main-delete-btn {
    cursor: default;
    background-repeat: no-repeat;
    background-color: transparent;
    border: none;
    border-radius: 0;
    width: 19px;
    height: 17px;
    display: inline-block;
    cursor: pointer;
    margin-left: 10px
}

.system-settings-email-templates-main-edit-btn {
    background-image: url(../../../assets/images/icons/ico_btn_edit_lightgrey.svg);
}  

.system-settings-email-templates-main-duplicate-btn {
    background-image: url(../../../assets/images/icons/ico_btn_duplicate_lightgrey.svg);
}    

.system-settings-email-templates-main-delete-btn {
    background-image: url(../../../assets/images/icons/ico_btn_delete_lightgrey.svg);
    min-width: auto;
}    

.settings-email-templates .system-details-content__body {
    padding-top: 20px;
    flex-direction: column;
}

.settings-email-templates-template-flex-row {
    display: flex;
}
    .settings-email-templates-template-flex-row > div:not(:last-child) {
        width: 25%;
        padding: 0 3% 0 0;
        box-sizing: content-box;
    }

.settings-email-templates-template-subject-input-container {
    width: 65% !important;
}

.settings-email-templates-template-insert-tag-input-container {
    width: 32% !important;
}
