.form-select {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    position: relative;

    .label {
        margin-bottom: 8px;
        margin-left: 5px;
        font-size: 13px;
        font-weight: 500;
        text-transform: capitalize;
        display: flex;
        align-items: center;
        color: var(--theme-clr-base-dark);

        .required {
            padding-left: 5px;
            vertical-align: middle;
            color: var(--theme-clr-secondary);
            cursor: pointer;
            line-height: 0px;
            font-size: 18px;
        }
    }

    .input {
        width: 100%;
        border-width: 1px;
        border-style: solid;
        border-radius: var(--theme-input-border-radius);
        outline: none;
        height: 37px;
        transition: all .3s ease-in-out;
        color: var(--theme-clr-base-mid6);
        padding: 8px 26px 8px 8px;
        transition: all .3s ease-in-out;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        background-image: url(../../../src/assets/images/icons/ico_dropdown_arrow.svg);
        background-repeat: no-repeat;
        background-position-y: center;
        background-position-x: calc(100% - 12px);
        z-index: 1;

        &.primary {
            border-color: var(--theme-input-border-color);

            &:focus {
                border-color: var(--theme-clr-primary);
                box-shadow: var(--theme-clr-shadow-primary);
            }
        }

        &.secondary {
            border-color: var(--theme-input-border-color);

            &:focus {
                border-color: var(--theme-clr-secondary);
                box-shadow: var(--theme-clr-shadow-secondary);
            }
        }
    
        &.dark {
            background-color: var(--theme-input-dark-background-color);
            border-color: transparent;
            /* color: var(--theme-clr-base-dark); */

            &:focus {
                border-color: var(--theme-input-dark-border-color);
                box-shadow: var(--theme-clr-shadow-inner);
            }
        }
    
        &:disabled {
            opacity: .5;
            box-shadow: none !important;
        }
    }

    &.error {
        .input {
            border-color: var(--theme-clr-palette-red) !important;
            box-shadow: var(--theme-clr-shadow-error);
        }

        .error-label {
            font-size: 12px;
            color: var(--theme-clr-palette-red);
            text-align: right;
            display: block;
            position: absolute;
            top: 0;
            right: 5px;
        }
    }

    .span.preview {
        cursor: pointer;
        font-size: 12px;
        color: var(--clr-midgrey);
        display: block;
        text-align: right;
        position: absolute;
        top: 0;
        right: 5px;
    }

    span.info-hint {
        display: flex;
        flex-direction: row;
        font-size: 14px;
        font-style: italic;
        gap: 5px;
        opacity: 0;
        transition: 0.5s;
        max-height: 0;

        &.show-info-hint {
            opacity: 1;
            max-height: 100px;
            margin-bottom: 8px;
        }
    }
}