.forgot-password-container {
    width: 350px;
    height: 100%;
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/*************** AZAMI ***************/

.forgot-password-container-azami__actions {
    display: flex;
    justify-content: flex-end;
}

.forgot-password-container-azami__text {
    color: var(--clr-darkgrey);
    font-size: 16px;
    display: block;
    margin-bottom: 20px;
}

/*************** IPEER ***************/

.forgot-password-container-ipeer__actions {
    display: flex;
    justify-content: flex-end;
}

.forgot-password-container-ipeer__text {
    color: var(--clr-darkgrey);
    font-size: 14px;
    display: block;
    margin-bottom: 20px;
}