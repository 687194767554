.boolean-badge {
    display: block;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    
        &.true {
            background-color: var(--theme-clr-palette-green-light);

            &::after {
                display: block;
                content: 'Г';
                font-size: 14px;
                font-weight: 900;
                color: var(--theme-clr-palette-green);
                position: absolute;
                left: 9px;
                top: 4px;
                rotate: 215deg;
            }
        }

        &.false {
            background-color: var(--theme-clr-palette-red-light);
            
            &::after {
                display: block;
                content: '×';
                font-size: 17px;
                font-weight: 500; 
                color: var(--theme-clr-palette-red);
                position: absolute;
                left: 9px;
                top: 3px;
            }
        }
}

