.case-configuration-settings__modal {
    min-width: 700px;
}

    .case-configuration-settings__modal-header {
        width: 100%;
    }

        .case-configuration-settings__modal-header ul {
            background: var(--clr-azami-blue-glass);
            border-radius: var(--radius-small);
            display: flex;
            gap: 5px;
            padding: 5px 7px;
        }

            .case-configuration-settings__modal-header ul li {
                font-size: 14px;
                padding: 5px 8px;
                border-radius: var(--radius-small);
                text-transform: capitalize;
                font-weight: 500;
                color: var(--clr-azami-blue);
                cursor: pointer;
                text-decoration: none;
            }

            .case-configuration-settings__modal-header ul li:hover {
                background-color: rgba(255, 255, 255, .5);
            }

            .case-configuration-settings__modal-header ul li.active {
                background-color: var(--clr-azami-blurple);
                color: var(--clr-white);
            }

/* CASE SETTINGS CLIENT */

.case-settings-client {
    padding: 15px;
}

    .case-settings-client__input-group {
        display: flex;
        gap: 30px;
    }

        .case-settings-client__input-group__input {
            width: 300px;
        }

        .case-settings-client__input-group__input .form-input__label {
            text-transform: unset;
        }

    .case-settings-client__switch-group {
        
    }

        .case-settings-client__switch-group__switch {
            max-width: 650px;
        }

            .case-settings-client__switch-group__switch .form-switch__label {
                order: 1;
            }
            
            .case-settings-client__switch-group__switch .form-switch__pill {
                order: 2;
                margin-left: auto;
            }