.quotes-list__sidebar{
    width: 300px;
    height: 100%;
    padding: 0 5px 15px 15px;
    overflow-y: auto;
}

    .quotes-list__sidebar-header {
        color: var(--clr-midgrey);
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 500;
        margin-bottom: 15px;
    }

    .quotes-list__sidebar-switch-group {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 20px;
        border-bottom: 1px solid var(--clr-midlightgrey);
        padding-bottom: 10px;
    }
 
        .quotes-list__sidebar-header img {
            margin-right: 5px;
        }

    .quotes-list__sidebar-actions {
        padding-top: 25px;
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        border-top: 1px solid var(--clr-midgrey);
    }

.quotes-list__data-table tr {
    cursor: pointer;
}


    .quotes-list__data-table tr td ul.table-cell-actions-menu__list{
        padding: 5px;
        width: 120px;
    }

    .quotes-list__data-table tr td li.table-cell-actions-menu__list-item{
        margin: 0;
    }

    .quotes-list__data-table tr td li.table-cell-actions-menu__list-item:hover{
        background-color: var(--clr-lightblue);
    }

    .quotes-list__data-table tr td li.table-cell-actions-menu__list-item button{
        padding: 5px;
        width: 100%;
        text-align: left;
    }

    .quotes-list__data-table-globe-icon{
        height: 25px;
        width: 25px;
    }

    .quotes-list__button {
        width: 100%;
        max-width: 200px;
        font-size: 13px !important;
    }

    .quotes-list__status-DRAFT {
        text-transform: capitalize;
        color: var(--clr-azami-dark-blue);
    }

    .quotes-list__status-PENDING {
        text-transform: capitalize;
        color: var(--clr-darkyellow);
    }

    .quotes-list__status-PUBLISHED {
        text-transform: capitalize;
        color: var(--clr-green);
    }

    .quotes-list__action:hover .table-cell-actions-menu__list {
        display: none;
    }

    .quotes-list__action.active .table-cell-actions-menu__list {
        display: block;
    }

