.data-table {
    width: 100%;

    &.primary {
        thead {
            th {
                background: var(--theme-clr-primary-light);
                color: var(--theme-clr-primary-dark);
            }
        }
    }

    &.secondary {
        thead {
            th {
                background: var(--theme-clr-secondary-light);
                color: var(--theme-clr-secondary-dark);
            }
        }
    }

    &.dark {
        thead {
            th {
                background: var(--theme-clr-base-mid2);
                color: var(--theme-clr-base-mid6);
            }
        }
    }

    thead {
        width: 100%;
        border-radius: var(--theme-border-radius-small);
        position: relative;        

        th {
            text-align: left;
            padding: 5px 10px;
            backdrop-filter: blur(5px);
            font-size: var(--theme-font-size-mid);
            text-transform: uppercase;
            font-weight: 600;
            cursor: pointer;

            &:hover {
                opacity: .85;        
            }

            &:first-of-type {
                border-radius: 
                    var(--theme-border-radius-small) 
                    0 
                    0 
                    var(--theme-border-radius-small);
            }

            &:last-of-type {
                border-radius: 
                    0 
                    var(--theme-border-radius-small) 
                    var(--theme-border-radius-small) 
                    0;
            }

            .header-cell {
                display: flex;
                align-items: center;

                .sorter-flags {
                    display: flex;
                    flex-direction: column;
                    margin-right: 5px;
                
                    img {
                        display: block;
                        margin: 1px;
                    }
                
                    img.sort-dir-inactive {
                        opacity: .4;
                    }
                }
            }
        }

        tr.fixed-header {
            > th {
                position: sticky;       
                top: 80px;
                z-index: 2; 
            }
        }
    }
    
    tbody {

        tr {

            &:hover {
                /* background-color: rgba(0, 0, 0, .015); */
            }

            &.grayed-out .table-cell{
                filter: grayscale(1);
                color: var(--theme-clr-base-mid4);
            }
        }

        td {
            text-align: left;
            padding: 15px 15px;
            color: var(--theme-clr-base-mid1);
            border-bottom: 1px solid var(--theme-clr-base-mid1);
        }
    }

    .table-cell {
        display: flex;
        font-size: 14px;
        color: var(--clr-darkgrey);
    
        a {
            text-decoration: none;
            font-weight: bold;
            color: var(--clr-darkblue);
        }
    
        &.table-cell-status img {
            margin: 0 10px 0 0;
        }
    }

    
    /***********************************************************/
    /************************** CELLS **************************/
    /***********************************************************/
    
        /************************* ACCOUNT *************************/
        .table-cell-account {
            display: flex;
            align-items: center;
            max-width: 200px;

            .account__icon {
                margin: 0 10px 0 0;
            }

            .account__info {
                display:flex;
                flex-direction: column;
            }

            .account__info__name {
                font-size: var(--theme-font-size-mid);
                font-weight: 600;
            }

            .account__info__subtext {
                font-size: var(--theme-font-size-small);
                color: var(--theme-clr-base-dark);
            }
        }

        /************************* ACTIONS *************************/
        .table-cell-actions {
            display: flex;
            justify-content: flex-end;
        }
        
        /********************** ADVANCED TAGS **********************/
        &.table-cell-advanced-tags {
            display: block;
    
            span {
                display: inline-block;
                background-color: var(--theme-clr-base-mid1);
                padding: 5px 10px;
                margin: 5px 5px 0 0;
                border-radius: var(--theme-border-radius-small);
            }
            
            span.dark {
                background-color: var(--theme-clr-tertiary-dark);
                color: var(--theme-clr-base-light);
            }
        }
        
        /********************* CELL WITH BADGE *********************/
        .table-cell-with-badge {
            align-items: center;
            
            img {
                margin-right: 5px;
            }
        }
        
        /**************** CONTENT WITH ACTIONS CELL ****************/
        .table-content-with-action-cell {
            justify-content: space-between;
        }
        
        /********************** COUNTRY CELL ***********************/
        .table-cell-country {
            align-items: center;
    
            .country__flag {
                width: 20px;
                margin-right: 5px;    
            }
        }
        
        /************************* DATE CELL ***********************/
        .table-cell-date {
            .date-icon {
                margin-right: 10px;
            }
        }
        
        /********************* DATE RANGE CELL *********************/
        .table-cell-date-range {
            display: flex;
            flex-direction: column;
    
            .range-title {
                font-size: var(--theme-font-size-small);
                margin-bottom: 2px;
                color: var(--theme-clr-base-mid2);    
            }
        }
        
        /********************** DATE TIME CELL *********************/
        .table-cell-date-time {
            img {
                margin-right: 5px;
            }
    
            span.date {
                margin-right: 5px;        
            }
        }
        
        /********************* EDITED BY CELL **********************/
        .table-cell-edited-by {
            display: flex;
            flex-direction: column;
            max-width: 200px;
    
            .user {
                font-size: var(--theme-font-size-mid);
            }
    
            .date {
                font-size: var(--theme-font-size-small);
                color: var(--theme-clr-base-dark);    
            }
        }
        
        /************************ LIST GROUP ***********************/
        .table-cell-list-group {
            ul {
                display: inherit;    
    
                li.list-group-item {
                    color: var(--theme-clr-base-light);
                    padding: 6px 5px;
                    font-size: var(--theme-font-size-small);     
                }
    
                li.list-group-item:first-child {
                    border-radius: var(--theme-border-radius-small) 0 0 var(--theme-border-radius-small);
                }
    
                li.list-group-item:last-child {
                    border-radius: 0 var(--theme-border-radius-small) var(--theme-border-radius-small) 0;  
                }
            }
        }

        /*********************** MARKER CELL ***********************/
        .table-cell-marker {
            
            .marker {
                display: inline-block;
                background-color: var(--theme-clr-base-mid3);
                color: var(--theme-clr-base-light);
                white-space: nowrap;
                padding: 4px;
                border-radius: var(--theme-border-radius-small);
                font-size: var(--theme-font-size-small);
                font-weight: 600;
            }
        }

        /*********************** NULLABLE CELL *********************/
        .table-cell-nullable {
            span {
                font-size: var(--theme-font-size-mid); 
            }
        }

        /********************** PRIORITY CELL **********************/
        .table-cell-priority {
            display: block;

            .priority-item {
                display: inline-block;
                background-color: var(--theme-clr-tertiary-dark);
                color: var(--theme-clr-base-light);
                padding: 4px 6px;
                margin: 2px 2px 0 0;
                font-size: var(--theme-font-size-small);
                text-transform: uppercase;
                border-radius: var(--theme-border-radius-small);
                font-weight: 600;
            }
        }

        /*********************** STATUS CELL ***********************/
        .table-cell-status {
            .info {
                display: flex;
                flex-direction: column;
                margin: 0 0 0 10px;

                .info-title {
                    font-size: var(--theme-font-size-mid);
                    line-height: 22px;
                }

                .info-date {
                    font-size: var(--theme-font-size-small);
                    color: var(--theme-clr-base-dark);
                }
            }
        }

        /******************** STATUS COLOR CELL ********************/
        .table-cell-status-color {
            
            .status-color-indicator {
                position: relative;
                width: 15px;
                height: 15px;
                border-radius: 50%;
                margin-left: 15px;
            }

            .tooltip-text {
                visibility: hidden;
                min-width: 200px;
                background-color: var(--theme-clr-base-dark);
                color: var(--theme-clr-base-light);
                text-align: center;
                border-radius: var(--theme-border-radius-small);
                padding: 5px 0;
                position: absolute;
                z-index: 2;
                bottom: 150%;
                right: -93px;
    
                &::after{
                    content: "";
                    position: absolute;
                    top: 100%;
                    left: 50%;
                    margin-left: -5px;
                    border-width: 5px;
                    border-style: solid;
                    border-color: 
                        var(--theme-clr-base-dark) 
                        transparent 
                        transparent 
                        transparent;
                }
            }

            .status-color__no-tooltip {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }

            .status-color-tooltip:hover {
                .tooltip-text{
                    visibility: visible;
                }
            } 
                
        }

        /************************ TAGS CELL ************************/
        .table-cell-tags {
            display: block;
            
            span {
                display: inline-block;
                background-color: var(--theme-clr-tertiary-dark);
                color: var(--theme-clr-base-light);
                padding: 4px 6px;
                margin: 5px 5px 0 0;
                font-size: var(--theme-font-size-small);
                text-transform: uppercase;
                border-radius: var(--theme-border-radius-small);
                font-weight: 600;
            }   
        }

        /******************* OVERRIDE PRICE CELL *******************/
        .table-cell-override-price {
    
            &.override-price-cell-icon-container {
                display: flex;
            }

            &.color-red {
                color: var(--theme-clr-palette-red);
            }

            &.color-green {
                color: var(--theme-clr-palette-green);
            }

            span.icon {
                position: relative;
                height: 16px;
                width: 16px;
                top: -5px;
                background-color: var(--theme-clr-base-light);
        
                img {
                    position: absolute;
                    height: 100%;
                    width: 100%;
                }
            }
        }

        /******************** TABLE CELL POINTS ********************/
        .table-cell-points {
    
            .points-adjusted {
                position: relative;
    
                &::before {
                    content: "A";
                    color: var(--theme-clr-base-light);
                    background-color: var(--theme-clr-tertiary-dark);
                    border-radius: 10px;
                    box-shadow: 0px 2px 3px var(--theme-clr-base-mid3);
                    width: 17px;
                    height: 17px;
                    line-height: 18px;
                    display: inline-block;
                    text-align: center;
                    font-size: var(--theme-font-size-small);
                    position: absolute;
                    right: -10px;
                    top: -10px;
                }
            }

            .points-text-italic {
                font-style: italic;
            }
    
            .points-color-red {
                color: var(--theme-clr-palette-red);
                font-weight: 600;
            }
    
            .points-color-green {
                color: var(--theme-clr-palette-green);
                font-weight: 600;
            }
        }
    
        /*********************** PERSON CELL ***********************/
        .table-cell-person {
            display: flex;
            align-items: center;
            max-width: 200px;

            .avatar-default {
                display: block;
                width: 40px;
                height: 40px;
                background-color: var(--theme-clr-base-mid1);
                border-radius: 50%;
                margin-right: 10px;
                object-fit: cover;
    
                img {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    object-fit: cover;
                }
            }

            .avatar-clear {
                display: block;
                width: 30px;
                height: 30px;
                background-color: var(--theme-clr-base-light);
                border-radius: 50%;
                margin-right: 10px;
                object-fit: cover;
    
                img {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    object-fit: cover;
                }
            }

            .person-info {
                display:flex;
                flex-direction: column;

                .full-name {
                    font-size: var(--theme-font-size-mid);
                    font-weight: 600;
                }

                .subtext {
                    font-size: var(--theme-font-size-small);
                    color: var(--theme-clr-base-dar);
                }
            }
        }

        /*********************** RATIOS CELL ***********************/
        .table-cell-ratios {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 20px;

            .ratio  {
                display: flex;
                flex-direction: column;
                gap: 9px;
                width: 150px;
            }

            .service  {
                font-weight: bold;
                color: var(--clr-azami-blue);
            }
            
            .points  {
                display: flex;
                align-items: center;
                gap: 5px;
            }

            .points-overriden {
                position: relative;
                
                &::before {
                    content: "O";
                    color: #fff;
                    background-color: var(--clr-darkblue);
                    border-radius: 10px;
                    box-shadow: 0px 2px 3px #aaa;
                    width: 16px;
                    height: 16px;
                    line-height: 17px;
                    display: inline-block;
                    text-align: center;
                    font-size: 12px;
                    position: absolute;
                    right: -14px;
                    top: -10px;
                }
            }

            .points-color-red {
                color: var(--theme-clr-palette-red);
            }

            .points-color-green {
                color: var(--theme-clr-palette-green);
            }
        }
        
        /*********************** LOADER CELL ***********************/
        td {
            .table-cell-loader__animated-fake-content {
                display: block;
                width: 100%;
                height: 18px; 
                margin: 7px 0;
                cursor: progress; 
                background: 
                linear-gradient(0.25turn, transparent, #fff, transparent),
                linear-gradient(#eee, #eee),
                radial-gradient(38px circle at 19px 19px, #eee 50%, transparent 51%),
                linear-gradient(#eee, #eee);  
                background-repeat: no-repeat;
                background-size: 315px 250px, 315px 180px, 100px 100px, 225px 30px; 
                background-position: -315px 0, 0 0, 0px 190px, 50px 195px; 
                animation: table-loading-cell 1.5s infinite;
            }
        }
    
        /*********************** RATES CELL ***********************/
            .table-cell-rates {
                ul.rates-list {
                    width: auto;
                    min-width: auto;
                    display: block;
                    li.rates__list-item {
                        display: flex;
                        border-radius: var(--theme-border-radius-small);
                        color: var(--theme-clr-base-light);
                        font-size: var(--theme-font-size-small);
                        margin-bottom: 5px;
    
                        .type { 
                            display: block;
                            padding: 3px;
                            font-weight: bold;
                            text-transform: uppercase;
                            background-color: var(--theme-clr-tertiary-dark);
                            border-top-left-radius: var(--theme-border-radius-small);
                            border-bottom-left-radius: var(--theme-border-radius-small);
                        }
    
                        .rate {
                            display: block;
                            background-color: var(--theme-clr-tertiary-dark);
                            opacity: .8;
                            padding: 3px 5px 3px 10px;
                            flex-grow: 1;
                            text-align: right;
                        }
    
                        .currency {
                            display: block;
                            background-color: rgba(255, 255, 255, .9);
                            background-color: var(--theme-clr-tertiary-light);
                            color: var(--theme-clr-tertiary-dark);
                            padding: 3px;
                            font-weight: bold;
                            border-top-right-radius: var(--theme-border-radius-small);
                            border-bottom-right-radius: var(--theme-border-radius-small);
                        }
                    }
                }
    
                .rates-info {
                    display: block;
                    width: 18px;
                    height: 18px;
                    border-radius: var(--theme-border-radius-big);
                    background-color: var(--theme-clr-tertiary);
                    color: var(--theme-clr-base-light);
                    font-weight: 900;
                    text-align: center;
                    line-height: 18px;
                    cursor: help;
                    margin: 0 auto;
                }
    
            }
    
            /*********************** ROW ***********************/
            .table-row-background-red {
                background-color: var(--theme-clr-palette-red-light); 
                transition: all 0.25s ease-in-out;
            }
    
            .table-row-background-red:hover {
                background-color: var(--theme-clr-palette-red);
                transition: all 0.25s ease-in-out;
            }
    
            .table-row-background-yellow {
                background-color: var(--theme-clr-palette-yellow-light); 
                transition: all 0.25s ease-in-out;
            }
            .table-row-background-yellow:hover {
                background-color: var(--theme-clr-palette-yellow);
                transition: all 0.25s ease-in-out;
            }
    
            .table-row-background-green {
                background-color: var(--theme-clr-palette-green-light); 
                transition: all 0.25s ease-in-out;
            }
            
            .table-row-background-green:hover {
                background-color: var(--theme-clr-palette-green);
                transition: all 0.25s ease-in-out;
            }
    
             /*********************** RULE REGION CELL ***********************/
            .table-cell-rule-region {
                cursor: pointer;
    
                .flag-container {
                    display: flex;
                    align-items: center;
                    margin-right: 7px;
                }
    
                .flag-img-container {
                   height: 16px;
                   width: 20px;
    
                   img {
                       width: 100%;
                       height: 100%;
                    }
                }
    
                .counter {
                    margin-left: 7px;
                    font-weight: 500;
                }
            }
    
            
            /*********************** ACTIONS MENU CELL ***********************/
            .table-cell-actions-menu {
                position: relative;
                padding: 5px 10px;
    
                button.toggle-menu-btn {
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: none;
                    outline: none;
                    width: 15px;
                    background: transparent;
                }
    
                ul.actions-menu-list {
                    position: absolute;
                    top: 25px;
                    border-radius: var(--theme-border-radius-small);
                    transform: translateX(-75%);
                    box-shadow: 0px 0px 11px var(--theme-clr-base-mid1);
                    font-family: sans-serif;
                    line-height: 20px;
                    z-index: 120;
                    width: max-content;
                    background-color: var(--theme-clr-base-light);
                    padding: 8px;
            
                    &::before {
                        content: " ";
                        right: 15%;
                        border: solid transparent;
                        height: 0;
                        width: 0;
                        position: absolute;
                        pointer-events: none;
                        border-width: 8px;
                        margin-left: calc(10px * -1);
                        bottom: 100%;
                        border-bottom-color: var(--theme-clr-base-light);
                    }
            
                    li {
                        
                        button {
                            cursor: pointer;
                            width: 100%;
                            height: 100%;
                            border: none;
                            background: none;
                            display: flex;
                            align-items: center;
                            padding: 8px;
    
                            &:hover {
                                background-color: var(--theme-clr-base-mid1);
                                opacity: .7;
                            }
    
                            transition: all 0.3s ease-in-out;
    
                            img {
                                margin-right: 10px;
                            }
                        }
        
                        &.disabled button {
                            color: var(--theme-clr-base-mid1);
                            cursor: not-allowed;    
                        }
                        
                        &.disabled:hover button {
                            background: var(--theme-clr-base-light);
                        }
                    }
                }
            }
}

.table-paging {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 20px;

    .right {
        display: flex;
        flex-direction: row;
        align-items: center;

        span {
            font-size: 13px;
            color: var(--theme-clr-base-mid2);
        }

        .select {
            margin: 0 5px;
            width: auto;

            &> span {
                margin: 0;
            }
        }
    }

    .left {
        display: flex;
        flex-direction: row;

        .paging-button {
            border: none;
            padding: 8px 12px;
            margin: 3px;
            border-radius: var(--theme-border-radius-small);
            cursor: pointer;
            text-align: center;
            min-width: 44px;
            color: var(--theme-clr-base-mid6);
            background-color: var(--theme-clr-base-mid1);
            transition: all .3s ease-in-out;
            border: 1px solid transparent;

            &.active {
                border-color: var(--theme-clr-base-mid2);
            }

            &:hover {
                background-color: var(--theme-clr-base-mid2);
                color: white;
            }

            &.dark {
                background-color: var(--theme-clr-base-mid3);
                color: var(--clr-white);
                text-transform: capitalize;

                &:hover {
                    background-color: var(--theme-clr-base-mid4);
                }
            }
        }
    }
}

@keyframes table-loading-cell {  
    to {
        background-position: 315px 0, 0 0, 0 190px, 50px 195px;
    }
}
