/* ****************************************************************************************** */
/* *********************************** Documents *************************************** */
/* ****************************************************************************************** */
.documents-picker_header_description {
    padding: 0 0 15px;
}
    .documents-main__table {
        margin-top: 15px;
    }
        .documents-main__table tbody tr td {
            vertical-align: baseline
        }

        .documents-header__actions__title {
            font-weight: bold;
        }

        .documents-header__actions__buttons {
            display: flex;
            gap: 20px;
        }

        .documents-header__actions__upload-btn {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .documents-header__actions__upload-btn img {
            margin-right: 8px;
        }


        .documents-main__table-file_action__actions-wrapper button {
           border: none;
           background: transparent;
           z-index: 100;
           cursor: pointer;
        }

        .documents-main__table__info  {
            margin-bottom: 15px;
            cursor: pointer;
            position: relative
        }

        .info__tooltip {
            position: absolute;
            background-color: var(--clr-black);
            color: var(--clr-white);
            right: 50%;
            transform: translate(50%, -50%);
            display: flex;
            flex-direction: column;
            padding: 10px 15px;
            border-radius: 5px;
            bottom: 15%;
            z-index: 1;
            align-items: flex-start;
            width: max-content;
            display: none;
        }

        .info__tooltip::after {
            content: "";
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: black transparent transparent transparent;
        }

        .table-cell .documents-main__table__info  img {
            margin: 0;
        }

        .documents-main__table__info:hover .info__tooltip {
            display: block;
        }        