.dashboard-map {
    background-color: var(--clr-white);
    box-shadow: var(--shadow);
    border-radius: 5px;
    width: 100%;
    padding: 0 12px 10px 12px;
    margin-right: 16px;
}

    .region-search-selector.dashboard-map-regions {
        position: absolute;
        top: 5px;
        left: 80px;
        z-index: 1000;
        height: 0;
    }
    
    .dashboard-map__header {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px 0;
        width: 100%;
    }

        .dashboard-map__header__title {
            display: flex;
            width: 50%;
            font-size: 17px;
            align-items: center;
            flex-grow: 1;
        }

            .dashboard-map__header-icon {
                width: 15px;
                height: 15px;
                margin-right: 8px;
            }
        
        .map__search-input {
            width: 300px;
        }

        .map__search-input, .map__search-input span {
            margin: 0;
        }

    .dashboard-map__map-container {
        width: 100%;
        height: 400px;   
        background-color: var(--clr-azami-blurple);
        border-radius: 5px;
        position: relative;
    }

    .leaflet-container {
        width: 100%; 
        height: 400px;
    }

    /* .leaflet-control { z-index: 0 !important}
    .leaflet-pane { z-index: 0 !important}
    .leaflet-top, .leaflet-bottom {z-index: 0 !important}
    .leaflet-zoom-animated {z-index: 600 !important} */
    .leaflet-control-attribution {display: none !important}

.dashboard-map__map-marker {
    width: 25px;
    height: 25px;
    background-color: var(--clr-azami-violet);
    border-radius: 50%;
    display: block;
    color: var(--clr-white);
    font-size: 13px;
    font-weight: bold;
    cursor: pointer;
    position: absolute;
    text-align: center;
    line-height: 20px;
    /* z-index: 600; */
    /* padding-bottom: 0px; */
    border: 2px solid var(--clr-white);
    /* transform: translate(0, -50%); */
}

.map__details-panel {
    width: 400px;
    height: 360px;
    background-color: var(--clr-white);
    border-radius: 5px;
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 1000;
    padding: 20px;
}

    .map__details-panel__header {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .map__details-panel__header__title {
        flex-grow: 1;
        font-size: 16px;
    }

    .map__details-panel__header__close {
        background-color: transparent;
        font-size: 24px;
        border: none;
        cursor: pointer;
    }

    .map__details-panel__tabs {
        width: 100%;
        border-bottom: 1px solid var(--clr-grey);
    }
    
        .map__details-panel__tabs ul {
            display: flex;
            flex-direction: row;
            margin: 10px 0;
            gap: 20px;
        }

            .map__details-panel__tabs ul li {
                list-style: none;
                cursor: pointer;
                font-size: 14px;
                font-weight: 500;
            }

            .map__details-panel__tabs ul li.active {
                color: var(--clr-azami-violet);
            }

        .map__details-panel__content-panel {
            width: 100%;
            height: 230px;
            overflow-y: auto;
            display: none;
            margin-top: 20px;
            font-size: 14px;
            font-weight: 300;
            line-height: 20px;
        }

        .map__details-panel__content-panel.active {
            display: block;
        }

    .map__details-panel__content-panel__case-list {

    }

        .map__details-panel__content-panel__case-list li {
            padding: 5px 0;
            position: relative;
            padding-left: 12px;
        }

        .map__details-panel__content-panel__case-list li::before {
            display: block;
            content: '';
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background-color: var(--clr-azami-violet);
            position: absolute;
            left: 0;
            top: 12px;
        }

        .map__details-panel__content-panel__load-more {
            width: 100%;
            text-align: center;
            margin-top: 10px;
            cursor: pointer;
            color: var(--clr-white);
            font-weight: 800;
            background-color: var(--clr-azami-violet);
            border-radius: 5px;
            padding: 10px 0;
            border: none;
        }