.translation-requests-main {
    padding: 15px 30px 0 30px;
    width: 100%;
}
    .translation-requests__header {
        margin: 10px 0 15px;
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
        .translation-requests__header__title {
            font-size: 16px;
            font-weight: 500;
            color: var(--clr-darkgrey);
        }

        .translation-requests__body {
            margin: 0 0 30px;
        }

        .translation-requests__body form {
            display: flex;
            flex-direction: column;
        }

            .translation-requests__body form .single {
                width: 49%;
            }

            .translation-requests__body form .row {
                display: flex;
                justify-content: space-between;
            }

                .translation-requests__body form .row > * {
                    width: 49%;
                }

.translation-requirements__sidebar {
    width: 300px;
    padding: 0 0 115px 15px;
    height: 100%;
    position: fixed;
    overflow-y: auto;
}
    .translation-requirements__sidebar__header {
        color: var(--clr-midgrey);
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 500;
        margin: 10px 0 15px;
    }

    .translation-requirements__sidebar .form-tag-select__tag-list, .form-tag-select__tag-list:empty {
        margin: 0 0 10px;
    }

.translation-requests__footer {
    width: 100%;
    height: 55px;
    background-color: var(--clr-grey);
    border-radius: 0 0 0 var(--radius);
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    gap: 10px;
}
.translation-requests__data-table tr {
    cursor: pointer;
}
.ep-desc {
    display: flex;
    align-items: center;
}