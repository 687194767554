.reciprocity-overrides-rules {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 15px;
    min-height: 350px;
}

    .reciprocity-overrides-rules__section-header {
        width: 100%;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 0;
    }

        .reciprocity-overrides-rules__section-header__info {
            display: flex;
            align-self: flex-end;
            gap: 8px;
            font-size: 14px;
        }

        .reciprocity-overrides-rules__section-header__info .title {
            color: var(--clr-darkgrey);
            font-style: italic;
        }

    .reciprocity-overrides-rules__section-filters {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        grid-gap: 15px;
        gap: 15px;
    }

    .reciprocity-overrides-rules__section-body {
        width: 100%;
        margin-top: 15px;
    }

        .rules-table__action-edit-btn,
        .rules-table__action-delete-btn {
            cursor: default;
            background-repeat: no-repeat;
            background-color: transparent;
            border: none;
            border-radius: 0;
            width: 19px;
            height: 17px;
            display: inline-block;
            cursor: pointer;
            margin-left: 10px
        }

        .rules-table__action-edit-btn {
            background-image: url(../../../assets/images/icons/ico_btn_edit_lightgrey.svg);
        }

        .rules-table__action-delete-btn {
            background-image: url(../../../assets/images/icons/ico_btn_delete_lightgrey.svg);
        }