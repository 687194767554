.create-agent__modal {
    min-width: 900px;
    padding: 0 20px;
    position: relative;
}

    .create-agent__modal__input-group {
        display: flex;
        align-items: center;
        gap: 20px;
        position: relative;
    }

        .create-agent__modal__input-group__spinner {
            width: fit-content;
            min-width: 50px;
            position: absolute;
            right: 0;
            top: 53%;
            transform: translateY(-50%);
        }

        .create-agent__modal-body__firm-header {
            padding: 10px 0 15px 0;
            display: block;
        }