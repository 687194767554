.error-message {
    font-size: 14px;
    background-color: var(--clr-red);
    margin-top: 15px;
    padding: 10px 18px 10px 14px;
    border-radius: var(--radius-small);
    color: var(--clr-white);
    font-weight: 500;
    display: flex;
    align-items: center;

    .icon {
        color: var(--clr-white);
        display: inline-block;
        font-size: 16px;
        border: 3px solid var(--clr-white);
        margin-right: 9px;
        border-radius: 50%;
        width: 21px;
        height: 21px;
        text-align: center;
        line-height: 15px;
        min-width: 21px;
        padding-left: 1px;
    }

    .text {
        display: inline-block;
        line-height: 21px;
    }
}