.form-checkbox {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    margin-top: 10px;
    cursor:pointer;

    &.disabled {
        background: transparent;
        border: none;
        cursor: not-allowed;
        opacity: 0.6;
        filter: grayscale(1);
    }

    img {
        margin-right: 10px;
    }

    .error-label {
        font-size: 12px;
        color: var(--theme-clr-palette-red);
        text-align: right;
        display: block;
        position: absolute;
        top: 0;
        right: 5px;
    }
    
    &.error {
        box-shadow: none;
        position: relative;

        .form-checkbox__input:not(:checked) + .checkmark {
            border-color: var(--theme-clr-palette-red);
        }
    }

    .label {
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        width: calc(100% - 30px);
        color: var(--theme-clr-base-mid5);

        &.multiline {
            text-overflow: unset;
            overflow: unset;
            white-space: initial;
        }

        &.error {
            width: calc(100% - 80px);
            color: var(--theme-clr-palette-red);
        }
    }

    .form-checkbox__input {
        display: none;

        +.checkmark {
            display: block;
            width: 15px;
            height: 15px;
            margin: 2px 10px 2px 0;
            border-radius: var(--theme-border-radius-small);
            position: relative;
            transition: all .3s ease-in-out;

            &.primary {
                border: 1px solid var(--theme-clr-primary);
            }

            &.secondary {
                border: 1px solid var(--theme-clr-secondary);
            }
        }
        
        &:checked {
            +.checkmark {

                &::after {
                    font-family: Arial, Helvetica, sans-serif;
                    content: 'Г';
                    display: block;
                    color: var(--theme-clr-base-light);
                    rotate: 215deg;
                    font-size: 11px;
                    font-weight: 900;
                    position: absolute;
                    left: 3.5px;
                    top: -1px;
                }

                &.primary {
                    background-color: var(--theme-clr-primary);
                }

                &.secondary {
                    background-color: var(--theme-clr-secondary);
                }
            }
        }

    }

    &.no-margin {
        margin: 0;
    };
}