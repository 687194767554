.app-footer {
    padding: 50px 0 50px 0;
    text-align: center;
    width: 100%;
    display: flex;
    width: 100%;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
}

.app-footer.auth-box {
    text-align: center;
}

    .app-footer span {
        color: var(--clr-midgrey);
        margin-right: 10px;
        font-size: 12px;
        display: inline-block;
        font-weight: bold;
    }

    .app-footer nav {
        display: flex;
    }

    .app-footer a {
        color: var(--clr-midgrey);
        font-size: 12px;
        display: inline-block;
        margin-right: 10px;
    }