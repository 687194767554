.social-box {
    background-color: var(--clr-white);
    border-radius: var(--radius-small);
    height: 40px;
    display: flex;
    padding: 10px;
    align-items: center;
}

    .social-box .icon {
        height: 15px;
        width: 20px;
        object-fit: contain;
        margin-right: 10px;
    }

    .social-box .network-title {
        font-size: 14px;
        font-weight: bold;
        flex-grow: 1;
    }

.social-box .button-container {
    display: flex;
    gap: 4px;
    justify-content: space-between;
}

    .social-box .button-container .btn-edit {
        width: 24px;
        height: 24px;
        border-radius: var(--radius-small);
        border:none;
        outline: none;
        background-color: var(--clr-lightblue);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .social-box .button-container .btn-link {
        width: 24px;
        height: 24px;
        border-radius: var(--radius-small);
        border:none;
        outline: none;
        background-color: var(--clr-darkblue);
        display: flex;
        align-items: center;
        justify-content: center;
    }

.social-box .input-container {
    display: flex;
    padding-left: 10px;
}

    .social-box .input-container input {
        height: 26px;
        width: 100%;
        border-radius: var(--radius-small);
        border: 1px solid var(--clr-grey);
        outline: none;
        padding: 0 5px;
        color: var(--clr-darkgrey);
        text-align: right;
    }

    .social-box .input-container input:focus {
        box-shadow: var(--shadow-blue);
    }