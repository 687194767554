.cases-sidebar{
    width: 300px;
    height: 100%;
    padding: 0 0 115px 15px;
    /* position: fixed; */
    overflow-y: auto;
}

.cases-sidebar__header {
    color: var(--clr-midgrey);
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 15px;
}
 
    .cases-sidebar__header img {
        margin-right: 5px;
    }

.cases-list__data-table tr {
    cursor: pointer;
}



