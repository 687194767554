.quote-details__section-container {
    margin-top: 15px;
    min-height: 100px;
}

    .quote-details__section-container-services-top {
        display: flex;
    }

    .quote-details__section-container-services-top-left {
        max-width: 55%;
    }

    .quote-details__section-container-services-top .quote-details__service-list li {
        margin: 0;
    }

    .quote-details__section-container-services-top .quote-details__service-list li label{
        margin: 10px 0 10px 0;
    }

.quote-details__subtitle {
    font-size: 14px;
    font-weight: bold;
    margin: 20px 0;
}

.quote-details__service-list {
    display: flex;
    flex-wrap: wrap;
}

    .quote-details__service-list-technologies-checkbox .label {
        color: var(--theme-clr-base-dark);
    }

    .quote-details__service-list li {
        width: 180px;
        margin-bottom: 20px;
    }

    .quote-details__service-list-techologies-required{
        padding-left: 5px;
        vertical-align: middle;
        color: var(--clr-azami-violet);
        cursor: pointer;
        line-height: 0px;
        font-size:18px;
    }
.quote-details__subtitle.section-title-error {
    color: var(--clr-azami-violet);
}

.quote-details__content-section-details-container {
    display: flex;
    grid-gap: 20px;
    gap: 20px;
}

    .quote-details__section-container-services-top-right {
        min-width: 45%;
    }
.quote-region-picker {
    position: relative;
}

.quote-details__input-number-of-claims .form-input__description {
    color: var(--clr-red) !important;
}

.associate-recommender-table {
    border-collapse: collapse;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.associate-recommender-table tr td {
    padding: 15px 10px;
    border: none;
}

.associate-recommender-table tr.bundle-region-row,
.associate-recommender-table tr.no-agents-region-row {
    border-top: 1px solid rgba(0, 0, 0, .1);
}

.associate-recommender-table tr.inactive td {
    background-color: var(--clr-greyed-out-background);
} 

.associate-recommender-table tr.favorite td {
    background-color: var(--clr-highlight-background);
}

.associate-recommender-table tr.bundle-region-row td:nth-child(1),
.associate-recommender-table tr.bundle-region-row td:nth-child(2),
.associate-recommender-table tr.bundle-region-row td:last-child {
    background-color: transparent !important;
} 

.associate-recommender-table tr td .form-radio {
    margin: 0;
}

.associate-row-icon-container {
    cursor: pointer;
    display: flex;
    flex-direction: column;
}

.associate-row-icon-container img {
    padding: 5px;
}

.total-prices-container {
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin: 10px;
    background-color: var(--clr-greyed-out-background);
    border-radius: var(--radius-small);
    margin-top: 15px;
    margin-bottom: 15px;
}

    .total-prices-container__first {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

        .total-prices-container div{
            display: flex;
            flex-direction: row;
            padding: 5px;
        }

    .total-prices-container__checkbox {
        width: 650px;
    }
    
    .total-prices-container__checkbox .form-checkbox {
        padding-left: 10px;
        padding-top: 5px;
    }

        .total-prices-container__checkbox .form-checkbox__input + .form-checkbox__checkmark {
            width: 17px;
            height: 17px;
            background-size: contain;
        }
    
        .total-prices-container__checkbox .form-checkbox__label.multiline {
            line-height: 1.5;
        }

        .total-prices-container__checkbox:before{
            content: '';
            position: absolute;
            display: block;
            height: 1.8px;
            width: 620px;
            background: var(--clr-black);
            margin-left: 10px;
          }

.total-prices {
    display: flex;
    flex-direction: row-reverse;
}

.total-prices-calc {
    font-weight: bold;
}

    .total-prices-calc span {
        font-weight: normal;
        margin-right: 5px;
    }
    
.total-prices-span-first {
    width: 400px;
}

.total-prices-span-header span {
    color: var(--clr-azami-blue);
    font-weight: bold;
    padding-right: 5px;
}

.discount-rule-switch {
    margin-top: 10px;
    margin-bottom: 15px;
}

.quote-details__info-attachments__checkbox__box-wrapper {
    background-color: var(--clr-greyed-out-background);
    padding: 10px;
}



