/* *************************************************************************************************************** */
/* ******************************************** Banner List ****************************************************** */
/* *************************************************************************************************************** */

.system-settings__banner-list__header {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
    
    .system-settings__banner-list__header span {
        font-weight: bold;
    }

.system-settings__banner-list__body {
    width: 100%;
}   

    .system-settings__banner-list__body__edit-btn {
        cursor: default;
        background-image: url(../../../assets/images/icons/ico_btn_edit_lightgrey.svg);
        background-repeat: no-repeat;
        background-color: transparent;
        border: none;
        border-radius: 0;
        width: 19px;
        height: 17px;
        cursor: pointer;
    }

    .system-settings__banner-list__body__delete-btn {
        cursor: default;
        background-image: url(../../../assets/images/icons/ico_btn_delete_lightgrey.svg);
        background-repeat: no-repeat;
        background-color: transparent;
        border: none;
        border-radius: 0;
        width: 19px;
        height: 17px;
        cursor: pointer;
        margin-left: 10px
    }

    .system-settings__banner-list__body__table__info {
        display: block;
        width: 18px;
        height: 18px;
        border-radius: 9px;
        background-color: var(--clr-blue-info);
        color: var(--clr-white);
        font-weight: 900;
        text-align: center;
        line-height: 18px;
        margin-right: 8px;
    }

    .system-settings__banner-list__body__table__time-date img {
        margin-right: 5px;
    }

    .system-settings__banner-list__body__table__time-date span {
        margin-right: 8px;
    }

    
/* *************************************************************************************************************** */
/* ******************************************** Banner Create **************************************************** */
/* *************************************************************************************************************** */

.system-settings__banner-create__header {
    margin-bottom: 25px;
    width: 100%;
}

.system-settings__banner-create__body {
    align-self: flex-start;
    width: 40%;
}

    .system-settings__banner-create__body__date-time-wrapper {
        display: flex;
        gap:30px;
        margin: 25px 0 10px 0;
    }

    .system-settings__banner-create__body__date-time-wrapper label {
        width: 100%;
    }

    .system-settings__banner-create__body textarea {
        width: 100%;
        min-height: 250px;
        margin-bottom: 25px;
    }
    
    .system-settings__banner-footer {
        position: absolute;
        width: 100%;
        bottom: 0;
        width: 100%;
        height: 55px;
        background-color: var(--clr-grey);
        border-radius: 0 0 0 var(--radius);
        padding: 0 15px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-direction: row;
    }

    .system-settings__banner-footer-actions {
        display: flex;
        gap: 5px;
    }
    
    .system-settings__banner-footer__cancel-btn {
        margin-right: 10px;
    }

    input[type="datetime-local"]::-webkit-calendar-picker-indicator {
        cursor: pointer;
        background: url('../../../assets/images/icons/ico_date.svg');
        background-repeat: no-repeat;
    }

/* *************************************************************************************************************** */
/* ******************************************** Banner *********************************************************** */
/* *************************************************************************************************************** */

.banner-container__item {
    padding: 20px;
    background-color: #d7edfb;
    width: 100%;
    border-bottom: 1px solid var(--clr-lightgrey);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 25px;
}

    .banner-container__item-info {
        font-size: 14px;
        color: #404040;
        font-family: 'Roboto';
        display: flex;
        align-items: center;
        width: 70%;
        line-height: 1.5;
    }

        .banner-container__item-info__info-icon {
            margin-right: 15px;
        }

        .banner-container__item-info__content  a {
            color: #026eb3;
        }

    .banner-container__item-actions {
        text-align: end;
        font-size: 14px;
        color: #404040;
        font-family: 'Roboto';
    }

        .banner-container__item-actions span {
            display: block;
            margin-bottom: 7px;
        }

        .banner-container__item-actions button,
        .banner-container__item__close-btn {
            border: none;
            background: transparent;
            padding-right: 15px;
            cursor: pointer;
            font-size: 16px;
        }

       
        button.banner-container__item__cancel-btn {
            padding-right: 0px;
            font-weight: 300;
        }

 