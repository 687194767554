.system-settings-firm-families__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    align-items: center;
    padding: 15px 30px 0 30px;
    width: 100%;
}

    .system-settings-firm-families__header__title {
        font-size: 16px;
        font-weight: 500;
        color: var(--clr-darkgrey);
    }

    .system-settings-table-wrapper {
        display: block;
    }

        .firm-family-insert-row td {
            background-color: var(--clr-lightblue);
            border-bottom: none !important;
        }

        .firm-family-insert-row td:first-of-type {
            border-radius: var(--radius-small) 0 0 var(--radius-small);
        }

        .firm-family-insert-row td:last-of-type {
            border-radius: 0 var(--radius-small) var(--radius-small) 0;
            text-align: right;
        }

            .firm-family-insert-row td .form-input {
                margin: 0;
            }

            .firm-family-insert-row td .form-input__label {
                margin: 0;
            }

        .firm-family-row-spacer td {
            padding: 0px !important;
            height: 2px;
            border-bottom: none !important;
        }

.firm-families__action-ok {
    background-image: url(../../../assets/images/icons/ico-table-row-ok.svg);
    background-repeat: no-repeat;
    background-color: transparent;
    border: none;
    border-radius: 0;
    width: 19px;
    height: 17px;
    display: inline-block;
    cursor: pointer;
    margin-left: 10px;
    margin-top: 3px;
}

.firm-families__action-cancel {
    background-image: url(../../../assets/images/icons/ico-table-row-cancel.svg);
    background-repeat: no-repeat;
    background-color: transparent;
    border: none;
    border-radius: 0;
    width: 19px;
    height: 17px;
    display: inline-block;
    cursor: pointer;
    margin-left: 10px;
    margin-top: 3px;
}
