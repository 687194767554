

ul.direction-list {
    display: flex;
    margin: 0 auto 0 30px;
}

ul.direction-list li{
    margin: 0 5px;
    color: rgb(185,188,182);
    text-decoration: none;
    font-size: 14px;
    cursor: pointer;
    margin: 0 5px;
}

ul.direction-list li img {
    vertical-align: middle;
    margin: 0 5px;
}

ul.direction-list li.active {
    color: var(--clr-azami-blue);
    font-weight: bold;
}

ul.direction-list span {
    display: inline-block;
    text-transform: lowercase;
}

ul.direction-list span::first-letter {
    text-transform: capitalize;
}
