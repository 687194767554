.customer-support {
    position: relative;
    margin-top: 10px;
}

.customer-support.external {
    margin-right: 15px;
}

    .customer-support__img {
        cursor: pointer;
    }

    .customer-support__tooltip {
        position: absolute;
        width: 250px;
        padding: 20px;
        border-radius: var(--radius);
        background: var(--clr-white);
        box-shadow: var(--shadow-center);
        visibility: hidden;
        cursor: pointer;
        top: 28px;
        right: -34px;
        z-index: 1;
    }

    .customer-support__tooltip::before {
        background: var(--clr-white);
        content: "";
        height: 8px;
        width: 8px;
        position: absolute;
        top: -5px;
        right: 40px;
        transform: rotate(225deg);
        z-index: -1;
        box-shadow: var(--shadow-center);
    }

    .customer-support__tooltip.hovered {
        visibility: visible;
    }

        .customer-support__tooltip-header {
            display: flex;
            align-items: flex-end;
            gap: 5px;
        }

            .customer-support__tooltip-header-img {
                width: 18px;
            }

            .customer-support__tooltip-header-title {
                font-size: 15px;
                font-weight: 400;
            }
        
        .customer-support__tooltip-content {
            margin-top: 12px;
            display: flex;
            flex-direction: column;
            gap: 12px;
        }

            .customer-support__tooltip-content > * {
                font-size: 14px;
                font-weight: 300;
            }

/* CUSTOMER SUPPORT MODAL STYLING */

.customer-support-modal {
    width: 600px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding-bottom: 30px;
}

    .customer-support-modal__group {
        display: flex;
        gap: 30px;
    }

        .customer-support-modal__group > * {
            width: 47%;
        }

        .customer-support-modal__textarea textarea {
            height: 150px;
        }