.agents-sidebar {
    width: 300px;
    padding: 0 0 115px 15px;
    height: 100%;
    overflow-y: auto;
}

.agents-sidebar__header {
    color: var(--clr-midgrey);
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 15px;
}

    .agents-sidebar__header img {
        margin-right: 5px;
    }

.agents-sidebar_actions {
    padding-top: 25px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
}

.agents-list {
    display: flex;
    width: 100%;
    flex-direction: column;
}
    .agents_bulk-upload.form-button {
        font-size: 14px;
        margin-top: 5px;
        display: flex;
        align-items: center;
        gap: 5px;
    }

    .agents_add-agent.form-button {
        font-size: 14px;
        font-weight: 500;
        margin: 5px 0 0 10px;
    }

    .agents-list-body table tr.inactive {
        background-color: var(--clr-greyed-out-background);
    } 

    .agents-list-body table tr.favorite {
        background-color: var(--clr-highlight-background);
    } 

    .agents-list-body .associate_user .form-select__input {
        z-index: auto;
    }

    .agents-list-body table tr td ul.table-cell-actions-menu__list {
        width: 170px;
    }
        .agents-list-body table tr td ul.table-cell-actions-menu__list button:hover {
            cursor: pointer;
        }

    .agents-table-cell__technology-service {
        display: flex;
        flex-direction: column;
    }

        .agents-table-cell__technology-service-info {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
        }

            .agents-table-cell__technology-service-info .name {
                border-radius: 5px;
                font-size: 12px;
                background-color: var(--clr-midlightgrey);
                color: var(--clr-azami-blue);
                /* margin-bottom: 0; */
                font-weight: bold;
                padding: 5px 7px;
            }
