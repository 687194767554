.document-uploader {
    display: flex;
    flex-direction: column;
    width: 100%;

    .title {
        font-size: 14px;
        color: var(--theme-clr-base-mid3);
        font-weight: bold;
        text-transform: uppercase;
        line-height: 14px;
        display: flex;
        width: 100%;
        gap: 10px;
        margin-bottom: 15px;
    }
    
    .upload-box {
        border: 1px dashed var(--theme-clr-base-mid2);
        border-radius: var(--theme-input-border-radius);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
        position: relative;

        .label {
            display: flex !important;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 20px 30px;
            cursor: pointer;

            input {
                visibility: hidden;
                position: absolute;
                width: 100%;
                height: 100%;
            }

           

            .input-icon {
                width: 35px;
            }

            .input-label {
                font-size: 14px;
                color: var(--theme-clr-base-mid3);
                margin-top: 5px;
            }
        }

        &.empty {
            border-color: var(--theme-clr-palette-red);
        }

        &.drag-enter {
            border: 1px dashed var(--theme-clr-primary-light);
        }
    }
    
    .message-container {
        text-align: center;
        color: var(--theme-clr-palette-red);
        margin-bottom: 15px;
    }
    
    .uploaded-documents-list {
        background-color: var(--theme-clr-base-mid1);
        border-radius: var(--theme-input-border-radius);
        padding: 15px;
        overflow-y: scroll;
        max-height: 25vh;
        
        li {
            background-color: var(--theme-clr-base-light);
            border-radius: var(--theme-border-radius-small);
            height: 40px;
            margin-bottom: 10px;
            display: flex;
            padding: 10px;
            align-items: center;
            
            &:last-of-type {
                margin-bottom: 0;
            }
            
            .icon-paperclip {
                height: 15px;
                width: 20px;
                object-fit: contain;
            }

            a {
                flex-grow: 1;
                font-size: 14px;
                color: var(--theme-clr-palette-blue);
                text-decoration: none;
                margin: 0 8px;

                &:hover {
                    text-decoration: underline;
                }
            }

            button {
                outline: none;
                border: none;
                width: 25px;
                height: 25px;
                background-color: var(--theme-clr-palette-red);
                border-radius: var(--theme-border-radius-small);
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }
        }
    }

    .edit-box {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--theme-clr-base-mid1);
        padding: 15px;
        border-radius: 5px;

        .content {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--theme-clr-base-light);
            border-radius: 5px;
            padding: 20px 0;
            width: 100%;

            .icon-paperclip {
                height: 15px;
                width: 20px;
                object-fit: contain;
            }

            a {
                text-decoration: none;
                color: var(--theme-clr-base-dark);
            }
        }


    }
}
