.data-table tbody tr.prefs_assoc__section-body__add-form,
.data-table tbody tr.prefs_assoc__section-body__add-form:hover {
    background-color: var(--clr-lightblue);
}

.data-table tbody tr.prefs_assoc__section-body__add-form td {
    padding: 0 15px;
}   

tr.prefs_assoc__section-body__add-form .form-select__label {
    margin-bottom: 15px;
}

.prefs_assoc__section-body__add-form-submit.form-button {
    min-width: 70px;
}

.prefs_assoc__section-body__add-form-cancel.form-button,
.prefs_assoc__section-body__add-form-cancel.form-button:hover {
    background: none transparent;
    border: none;
    min-width: 20px;
    padding: 0;
    margin-left: 10px;
} 