 .register-container__actions {
    display: flex;
    justify-content: flex-end;
}

.register-container__fields {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.register-container__fields::after {
    display: block;
	content: '';
	width: 31%;
}

.register-container__input {
    width: 31%;
}

.register-container__checkbox {
    width: 100%;
}


/*************** AZAMI ***************/

.register-container-azami {
    width: 800px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

/*************** IPEER ***************/

.register-container-ipeer {
    width: 800px;
    height: 90%;
}

    .register-container-ipeer__firm-check {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: 5px 15px;
        border-radius: var(--radius-small);
        border: 1px solid var(--clr-ipeer-blue);
        background-color: #2B3FFD33;
    }
    
    .register-container-ipeer h3 {
        font-weight: 500;
        margin: 20px 0;
    }

.register-container__checkbox-checkmark .checkmark {
    line-height: 1;
}

    
