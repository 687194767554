.stat-table-card {
    background-color: var(--clr-white);
    box-shadow: var(--shadow);
    border-radius: 5px;
    width: 100%;
}
    .stat-table-card__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px;
        gap: 8px;
        border-bottom: 1px solid var(--clr-grey);
    }
        .stat-table-card__header-icon {
            width: 15px;
            height: 15px;
        }

        .stat-table-card__header-left {
            display: flex;
            align-items: center;
            gap: 8px;
        }
            .stat-table-card__header-left__title {
                font-size: 17px;
            }

            .stat-table-card__header-left__amount {
                height: 23px;
                padding: 4px;
                border-radius: 5px;
                text-align: center;
                color: var(--clr-white);
                font-size: 14px;
                background-color: var(--clr-azami-blurple);
            }

        a {
            color: var(--clr-azami-blurple);
            font-size: 15px;
            font-weight: 500;
        }

    .stat-table-card__main {
        width: 100%;
        margin-top: 25px;
        border-spacing: 0;
        padding: 0 12px;
    }

.no-data {
    width: 100%;
	height: 300px;
	text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 35px;
}
    .no-data__icon {
        width: 130px;
        height: 130px;
        margin-left: 10px;
    }
    .no-data__title {
        color: var(--clr-midgrey);
        font-size: 14px;
        margin: 20px 0 0 20px;
    }
    .no-data__subtitle {
        color: var(--clr-midlightgrey);
        font-size: 12px;
        margin: 20px 0 0 20px;
        font-weight: 500;
    }

/* SKELETON LOADER */

@keyframes stat-table-card-loading {  
    to {
        background-position: 315px 0, 0 0, 0 190px, 50px 195px;
    }
}

.stat-table-card__animated-fake-content {
    display: block;
    width: 100%;
    height: 18px; 
    margin: 7px 0;
    cursor: progress; 
    background: 
      linear-gradient(0.25turn, transparent, #fff, transparent),
      linear-gradient(#eee, #eee),
      radial-gradient(38px circle at 19px 19px, #eee 50%, transparent 51%),
      linear-gradient(#eee, #eee);  
    background-repeat: no-repeat;
    background-size: 315px 250px, 315px 180px, 100px 100px, 225px 30px; 
    background-position: -315px 0, 0 0, 0px 190px, 50px 195px; 
    animation: stat-table-card-loading 1.5s infinite;
}