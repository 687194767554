.firm-management-sidebar {
    width: 300px;
    padding: 0 0 115px 15px;
    height: 100%;
    /* top: 0px; */
    position: fixed;
    overflow-y: auto;
}

.firm-management-sidebar__header {
    color: var(--clr-midgrey);
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 15px;
}

    .firm-management-sidebar__header img {
        margin-right: 5px;
    }

.firm-management-sidebar_actions {
    padding-top: 25px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
}

.firm-management__data-table tr {
    cursor: pointer;
}
