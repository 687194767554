.system-settings-reciprocity-groups {
    width: 100%
}
    .system-settings-reciprocity-groups__header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        align-items: center;
    }
        .system-settings-reciprocity-groups__header__title {
            font-size: 16px;
            font-weight: 500;
            color: var(--clr-darkgrey);
        }
        .system-settings-reciprocity-groups__header__create-group-btn {
            font-size: 16px;
            font-weight: 500;
            color: var(--clr-darkgrey);
        }
    .system-settings-reciprocity-groups__main-screen {
        
    }
        .system-settings-reciprocity-groups__main-screen__groups-table {
            
        }
            .groups-table__action-edit-btn {
                cursor: default;
                background-image: url(../../../assets/images/icons/ico_social_btn_edit.svg);
                background-repeat: no-repeat;
                background-color: transparent;
                border: none;
                border-radius: 0;
                width: 19px;
                height: 17px;
                display: inline-block;
                cursor: pointer;
                margin-left: 10px
            }
            .groups-table__action-delete-btn {
                cursor: default;
                background-image: url(../../../assets/images/icons/ico_btn_delete_darkblue.svg);
                background-repeat: no-repeat;
                background-color: transparent;
                border: none;
                border-radius: 0;
                width: 19px;
                height: 17px;
                display: inline-block;
                cursor: pointer;
                margin-left: 10px
            }

/* *************************************************************************************************************** */
/* ************************************** Create & Edit Group Modal ********************************************** */
/* *************************************************************************************************************** */

.selected-regions-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    max-height: 300px;
    max-width: 900px;
    overflow-y: auto;
}

.selected-region-item {
    display: flex;
    border: 1px solid;
    border-radius: var(--radius-small);
    background-color: var(--clr-azami-blue);
    padding: 4px 6px;
    margin: 3px 3px 0 0;
    color: var(--clr-white);
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
}

.selected-region-remove-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid;
    border-radius: 50%;
    background-color: var(--clr-white);
    color: var(--clr-blue);
    margin-left: 7px;
    width: 14px;
    height: 14px;
    cursor: pointer;
}

/* *************************************************************************************************************** */
/* ************************************** Points ********************************************** */
/* *************************************************************************************************************** */

.system-settings-reciprocity-ratios {
    width: 100%
}
    
    .system-settings-reciprocity-ratios__header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 5px;
    }

    .system-settings-reciprocity-ratios__header__left{
        display: flex;
        width: 38.5%;
    }

    .system-settings-reciprocity-ratios__header__left .form-input{
        margin-right: 15px;
    }

    .system-settings-reciprocity-ratios__main-screen__summary{
        border-radius: 5px;
        background-color: var( --clr-background);
        color: var( --clr-darkgrey);
        height: 100%;
        display: flex;
        align-items: center;
        font: bold 16px Roboto;
        min-height: 34px;
        margin-bottom: 7px;
        padding-left: 26px;
    }

    .system-settings-reciprocity-ratios__main-screen__details{
        position: relative;
    }

    .system-settings-reciprocity-ratios__main-screen__details[open] summary {
        background-color: #2b5374;
        color: var(--clr-white);
    }

    .system-settings-reciprocity-ratios__main-screen__details[open]::before{
        content: '';
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 6px solid;
        border-bottom-color: var(--clr-white);;
        position: absolute;
        top: 12px;
        left: 10px;
    }

    .system-settings-reciprocity-ratios__main-screen__details[open] summary::before{
        content: '';
        display: none;
    }

    .system-settings-reciprocity-ratios__main-screen__details summary::before {
        content: '';
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 6px solid;
        border-top-color: var( --clr-darkgrey);
        position: absolute;
        top: 15px;
        left: 10px;
    }