.rates-list {
    width: 100%;
    min-width: 680px;
}

    .rates-list-client {

    }

        .rates-list-client__header {
            background-color: var(--clr-azami-blue);
            display: flex;
            padding: 5px 7px 5px 10px;
            align-items: center;
            border-radius: var(--radius-small);
            height: 34px;
            cursor: pointer;
            gap: 5px;
        }

            .rates-list-client__header__title {
                flex-grow: 1;
                font-size: 14px;
                font-weight: bold;
                text-transform: uppercase;
                color: var(--clr-white);
            }

    .rates-list-item {
        margin-bottom: 10px;
        margin-top: 10px;
    }

        .rates-list-item__header {
            display: flex;
            background-color: var(--clr-background);
            padding: 5px 7px 5px 10px;
            align-items: center;
            border-radius: var(--radius-small);
            height: 34px;
            cursor: pointer;
        }

        .rates-list-item.active .rates-list-item__header {
            background-color: var(--clr-azami-blue-light);
        }

            .rates-list-item__header img {
                margin-right: 5px;
            }

            .rates-list-item__header__title {
                flex-grow: 1;
                font-size: 14px;
                font-weight: bold;
                text-transform: uppercase;
                color: var(--clr-azami-blurple);
            }

            .rates-list-item.active .rates-list-item__header__title {
                color: var(--clr-azami-blurple);
            }

            .rates-list-item__header select {
                border: none;
                border-radius: var(--radius-small);
                outline: none;
                padding: 4px 30px 4px 8px;
                appearance: none;
                font-weight: bold;
                color: var(--clr-azami-blurple);
                background-image: url(../../../assets/images/icons/ico_angle_arrow_down_blurple.svg);
                background-repeat: no-repeat;
                background-position: calc(100% - 7px) 8px;
                
            }

            .rates-list-item__header select.grey {
                background-color: var(--clr-midgrey);    
                color: transparent;
            }

                .rates-list-item__header select option {
                    color: var(--clr-midgrey);
                }

            .rates-list-item.active .rates-list-item__header select {
                color: var(--clr-azami-blurple);
                background-color: rgba(255, 255, 255, .2);
                background-image: url(../../../assets/images/icons/ico_angle_arrow_blurple.svg);
            }

            .rates-list-item.active .rates-list-item__header select.grey {
                background-color: var(--clr-midgrey);
                color: transparent;
            }

                .rates-list-item.active .rates-list-item__header select option {
                    color: var(--clr-darkgrey);
                }

               .rates-list-item__header select.grey option {
                    display: none;
                }

        .rates-list-item__body {
            margin-top: 15px;
            padding: 0 10px;
        }

            .rates-list-item__body__archived {
                margin-bottom: 15px;
                display: flex;
                align-items: center;
            }
                .rates-list-item__body__archived span{
                    color: var(--clr-darkgrey);
                    font-size: 14px;
                    margin-left: 8px;
                }

            .rates-list-item__body table {
                width: 100%;
                border-collapse: collapse;
                border-spacing: 0;
            }
                .rates-list-item__body table.rates-list-item__body__table__grey {
                    opacity: 0.5;
                }

                .rates-list-item__body th {
                    background-color: var(--clr-background);
                    text-align: left;
                    padding: 8px 10px;
                    font-size: 13px;
                    text-transform: uppercase;
                    color: var(--clr-darkgrey);
                }

                .rates-list-item__body th:first-of-type {
                    border-radius: var(--radius-small) 0 0 var(--radius-small);
                }

                .rates-list-item__body th:last-of-type {
                    border-radius: 0 var(--radius-small) var(--radius-small) 0;
                }

                .rates-list-item__body td {
                    padding: 8px 10px;
                    font-size: 14px;
                    vertical-align: middle;
                    border-bottom: 1px solid var(--clr-grey);
                }

                    .rates-list-item__body__cell-flex-wrapper {
                        display: flex;
                        justify-content: space-between;
                    }

                    .rates-list-item__custom-input {
                        margin-bottom: 8px;
                    }
                    
                    .rates-list-item__custom-input-half {
                        width: 48%;
                        margin-top: 8px;
                    }

                    .rates-list-item__custom-select-half {
                        width: 48%;
                        margin-top: 8px;
                    }

                    .rates-list-item__custom-select {
                        margin-top: 8px;
                    }

                    .rates-list-item__custom-input .form-input__label {
                        margin-bottom: 0;
                    }

/* MODAL */

.rates-list__modal {
    max-width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

    .rates-list__modal span {
        font-weight: bold;
    }
