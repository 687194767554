.region-picker-container {
    display: flex;
}

    .region-picker-container__left-filters {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
        font-size: 14px;
        color: var(--clr-azami-blurple);
        font-weight: bold;
    }

    .region-picker-container__left-filters-left span {
        margin-right: 15px;
        padding: 5px;
    }

    .region-picker-container__right {
        padding: 10px;
    }

    .region-picker-container__right.border {
        width: 450px;
        border-right: 1px solid var(--clr-midgrey);
    }

        .region-picker-container__right-counter {
            padding: 5px;
            color: var(--clr-midgrey);
            font-size: 14px;
        }

    .region-picker-container__left {
        padding-right: 10px;
        border-right: 1px solid var(--clr-midgrey);
        width: 450px;
        min-width: 450px;
    }

    .region-picker-container__left-list {
        height: 350px;
        overflow: auto;
    }
        .region-picker-container__left-list li {
            display: flex;
            align-items: center;
            padding: 5px 10px;
            border-radius: var(--radius-small);
            color: var(--clr-darkgrey);
            font-size: 14px;
        }

        .region-picker-container__left-list li label {
            margin-bottom: 0;
        }

            .region-picker-container__left-list li img {
                height: 10px;
                margin-right: 5px;
            }

        .region-picker-container__left-list-code {
            white-space: nowrap;
            color: var(--clr-midgrey);
        }

    .region-picker-container__right-selected {
        height: 430px;
        padding: 10px;
        overflow: auto;
        align-content: flex-start;
        flex-wrap: wrap;
        display: flex;
        gap: 15px;
    }

        .region-picker-container__right-selected li{
            height: 30px;
            background-color: var(--clr-azami-blurple);
            border-radius: 15px;
            display: flex;
            align-items: center;
            padding: 5px 10px;
            color: var(--clr-white);
        }

        .region-picker-container__right-selected li span {
            margin-bottom: 0;
        }

        .region-picker-container__right-selected li img {
            height: 10px;
            margin-right: 5px;
        }
    
        .region-picker-container__right-selected li button {
            box-sizing: border-box;
            vertical-align:middle;
            height: 13px;
            width: 13px;
            border: none;
            outline: none;
            color: var(--clr-white);
            border-radius: 50%;
            font-size: 12px;
            margin-left: 5px;
            font-weight: bolder;
            cursor: pointer;
            color: var(--clr-azami-blurple);
        }

        .region-picker-container__selected ul{
            height: 200px;
            overflow: auto;
            align-content: flex-start;
            width: 100%; 
            display: flex;
            flex-wrap: wrap;
            gap: 15px;
        }

    .region-picker-container__knowledgebase {
        padding: 15px;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
        .region-picker-container__knowledgebase-header {
            color: var(--clr-midgrey);
            font-size: 14px;
        }

        .region-picker-container__knowledgebase-notice {
            font-size: 16px;
        }

        .region-picker-container__knowledgebase-list {
            display: flex;
            flex-direction: column;
            gap: 10px;
            height: 350px;
            overflow: auto;
        }

            .region-picker-container__knowledgebase-list-item {
                display: flex;
                align-items: center;
                gap: 5px;
            }

    