.spinner {
    width: 100%;
    text-align: center;

    & > div {
        border-radius: 100%;
        display: inline-block;
        -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
        animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    }

    /* COLORS */
    &.primary > div {
        background-color: var(--theme-clr-primary);
    }

    &.secondary > div {
        background-color: var(--theme-clr-secondary);
    }

    &.light > div {
        background-color: var(--theme-clr-base-light);
    }

    /* SIZES */
    &.small > div {
        width: 10px;
        height: 10px;
    }

    &.medium > div {
        width: 20px;
        height: 20px;
    }

    &.large > div {
        width: 30px;
        height: 30px;
    }

    &.xlarge > div {
        width: 40px;
        height: 40px;
    }

    /* ANIMATIONS */
    & .bounce1 {
        -webkit-animation-delay: -0.32s;
        animation-delay: -0.32s;
    }

    & .bounce2 {
        -webkit-animation-delay: -0.16s;
        animation-delay: -0.16s;
    }
}

@-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% { -webkit-transform: scale(0) }
    40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
    0%, 80%, 100% { 
        -webkit-transform: scale(0);
        transform: scale(0);
    } 40% { 
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
    }
}