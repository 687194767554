.reciprocity-system-table {
    width: 100%;
    border-collapse:separate;
    border-spacing: 8px; 
}

    .reciprocity-system-table th {
        color: var(--clr-darkgrey);
        font-size: 14px;
        text-transform: uppercase;
        border-spacing: 0 25px;
    }

    .reciprocity-system-table__ratio {
        text-align: center;
        color: #A2A5A9;
        font-size: 18px;
        font-weight: bold;
    }

    .reciprocity-system-table__ratio span{
        color: #2B5374;
        margin: 0 6px;
    }

    .reciprocity-system-table__base-points-title, 
    .reciprocity-system-table__reward-points-title{
        height: 27px;
        border-radius: 5px;
        background-color: var(--clr-lightestblue);
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        padding-left: 10px;
    }


    .reciprocity-system-table__base-points-title span:nth-child(1),
    .reciprocity-system-table__reward-points-title span:nth-child(1){
        color: #2B5374;
    }

    .reciprocity-system-table__base-points-title span:nth-child(2),
    .reciprocity-system-table__reward-points-title span:nth-child(2){
        color: var(--clr-midgrey);
    }

    .reciprocity-system-table__base-points,
    .reciprocity-system-table__reward-points{
        width: 80%;
    }

    .reciprocity-system-table__base-points 
    .input {
        font-size: 14px;
        color: var( --clr-priority-1);
    }

    .reciprocity-system-table__reward-points 
    .input {
        font-size: 14px;
        color: #27AE60;
    }

