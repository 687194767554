.reports-sidebar {
    width: 300px;
    padding: 0 0 115px 15px;
    height: 100%;
    /* top: 0px; */
    /* position: fixed; */
    overflow-y: auto;
}

.reports-sidebar__header {
    color: var(--clr-midgrey);
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 15px;
}

    .reports-sidebar__header img {
        margin-right: 5px;
    }

.reports-sidebar__firms-without-tags-switch,
.reports-sidebar__vendor-switch{
    margin-bottom: 15px;
}

.reports-sidebar_actions {
    padding-top: 25px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid var(--clr-midgrey);
}

.reports-sidebar_hgroup {
    display: flex;
    justify-content: space-between;
}

.reports-sidebar_hgroup label:first-child, 
.reports-sidebar_hgroup label:last-child {
    width: 48%;
}

.table-cell.color-green {
    color: var(--clr-green);
}
    
.table-cell.color-yellow {
    color: var(--clr-darkyellow);
}

.table-cell.color-red {
    color: var(--clr-red);
}

.table-cell.firm-column {
    display: flex;
    flex-flow: column;
    row-gap: 5px;
}

    .table-cell.firm-column .firm-account {
        color: var(--clr-midgrey);
    }

    .table-cell.firm-column ul.tags {
        display: flex;
        gap: 7px;
    }

.table-cell.service-technology-column {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    row-gap: 5px;
}

    .service-technology-column__service-info {
        display: flex;
        border-radius: 5px;
        font-size: 12px;
        background-color: var(--clr-midlightgrey);
        color: var(--clr-azami-blue);
        margin-bottom: 0;
        font-weight: bold;
    }

        .service-technology-column__service-info .name {
            display: flex;
            text-transform: none;
            padding: 5px 7px;
        }
        
        .service-technology-column__service-info  .owed-cases {
            display: flex;
            background-color: rgba(255, 255, 255, .4);
            padding: 5px 7px;
            text-align: center;
            align-items: center;
        }