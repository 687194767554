.system-settings-exchange-rates__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    align-items: center;
    padding: 15px 30px 0 30px;
    width: 100%;
}

    .system-settings-exchange-rates_header__title {
        font-size: 16px;
        font-weight: 500;
        color: var(--clr-darkgrey);
    }

    .system-settings-exchange-rates-table-wrapper {
        display: block;
    }

        .system-settings-exchange-rates-table-wrapper .data-table thead th,
        .system-settings-exchange-rates-table-wrapper .data-table tbody td {
            width: 25%;
        }

    .system-settings-exchange-rates__section-header__directions {
        display: flex;
        margin: 0 auto 0 30px;
    }
    
    .system-settings-exchange-rates__section-header__directions .dir-filter {
        margin: 0 5px;
        color: rgb(185,188,182);
        text-decoration: none;
        font-size: 14px;
        cursor: pointer;
    }
    
    .system-settings-exchange-rates__section-header__directions .dir-filter.active {
        color: var(--clr-darkblue);
        font-weight: bold;
    }

    .system-settings-exchange-rates__header__left{
        display: flex;
        width: 38.5%;
    }

        .system-settings-exchange-rates__header__left-input-wrapper {
            position: relative;
        }

        .system-settings-exchange-rates__header__left-asterisk {
            font-size: 20px;
            padding-top: 2px;
            color: var(--clr-azami-violet);
            position: absolute;
            right: 10px;
            top: -6px;
            cursor: pointer;
        }

    .system-settings-exchange-rates__info {
        width: 100%;
        padding: 0 30px 10px 30px;
        font-size: 14px;
        color: var(--clr-darkgrey);
        font-style: italic;
    }

        .system-settings-exchange-rates__info img{
            margin-right: 5px;
        }

    .system-settings-exchange-rates__header__left .form-input {
        margin-left: 15px;
    }

    .data-table tbody tr.system-settings-exchange-rates__section-body__add-form {
        background-color: var(--clr-azami-blue-light);
    }

    .data-table tbody tr.pricing-levels__section-body__add-form:hover {
        background-color: var(--clr-lightblue);
    }
    
    .data-table tbody tr.system-settings-exchange-rates__section-body__add-form td {
        padding: 0 15px;
    }   
    
    tr.system-settings-exchange-rates__section-body__add-form .form-input__label {
        margin-bottom: 15px;
    }
    
    .system-settings-exchange-rates__section-body__add-form-submit.form-button {
        min-width: 70px;
    }
    
    .system-settings-exchange-rates__section-body__add-form-cancel.form-button,
    .system-settings-exchange-rates__section-body__add-form-cancel.form-button:hover {
        background: none transparent;
        border: none;
        min-width: 20px;
        padding: 0;
        margin-left: 10px;
    }
