.settings-colapsible__header.error {
    background-color: var(--clr-red);
}

.quote-details__subtitle.error {
    color: var(--clr-red);
}

.main-screen-section.quote-details {
    min-height: calc(100% - 105px);
}

.main-screen-section__details-content__body.quote-details {
    height: 100%;
    padding-top: 5px;
    min-height: calc(100vh - 60px);
    /* overflow-y: scroll; */
}

.quote-details__input-column {
    width: 300px;
}

    .quote-details__input-column label.form-switch{
        margin-bottom: 5px;
    }

.quote-details__subtitle {
    font-size: 14px;
    font-weight: bold;
    margin: 20px 0;
}

.quote-details__service-list {
    display: flex;
    flex-wrap: wrap;
}

    .quote-details__service-list li {
        width: 180px;
        margin-bottom: 20px;
    }

.quote-details__info-attachments {
    /* display: flex; */
}

    .quote-details__info-attachments__inputs
    .quote-details__info-attachments__inputs-top {
        width: 620px;
    }

        .quote-details__info-attachments__inputs p {
            color: var(--clr-darkgrey) !important;
            /* margin: 0 0 30px 0 !important; */
        }

        .quote-details__info-attachments__inputs__textarea textarea {
            height: 150px;
        }

        .quote-details__info-attachments__inputs__checkbox {
            color: var(--clr-darkgrey);
        }

.quote-details__footer-switch-wrapper {
    flex-grow: 1;
}

.quote-details__footer__btn-margin {
    margin-right: 15px;
}

.quote-details__dropdown {
    width: 300px;
}

.quote-details__duplicates-modal_content, .quote-details__china-extension-modal_content {
    display: flex;
    flex-direction: column;
}

    .quote-details__duplicates-modal_content, .quote-details__echina-extension-modal_content span {
        margin-bottom: 5px;
    }
    
.application_number_field {
    display: flex;
    justify-content: space-between;
}   

.application_number_field .form-input {
    margin-right: 10px;
}

.get-wipo-button  {
    height: 37px;
    margin: 22px auto 0;
}

.get-wipo-button.form-button.medium {
    padding: 0;
}

.identifiers-container,
.new-quote-wizard__content-section-identifiers-container {
    justify-content: space-between;
    display: flex;
    width: 100%;
}

.identifiers-container__col,
.new-quote-wizard__content-section-identifiers-container__col {
    width: 31%;
}

.firm-notes {
    background-color: #f1f1f1;
    padding: 15px;
    font-size: 14px;
    font-weight: 300;
    margin: 5px;
    overflow: auto;
    white-space: pre-line;
}
.firm-notes .firm-notes__title {
    color: var(--clr-azami-blurple);
    font-weight: 600;
    margin-bottom: 12px;
}

.quote-details__service-list-techologies-required{
    padding-left: 5px;
    vertical-align: middle;
    color: var(--clr-azami-violet);
    cursor: pointer;
    line-height: 0px;
    font-size:18px;
}

.override-wipo-modal {
    background-color: var(--clr-white);
    overflow: hidden;
    border-radius: 10px;
    box-shadow: var(--shadow);
}

    .override-wipo-modal__header {
        padding: 10px;
    }

    .override-wipo-modal-data {
        color: var(--clr-azami-violet);
        font-weight: bold;
    }

    .override-wipo-modal__body {
        padding: 0 10px 0 10px;
        color: var(--clr-darkgrey);
        max-height: 400px;
        overflow-y: auto;
        font-size: 14px;
        width: 700px;
        position: relative;
    }

        .override-wipo-modal__body table td{
            padding: 5px 10px;
        }
.quote-details__service-list-service {
    align-items: start;
}

.quote-details__service-list-service .form-radio__checkmark {
    min-width: 13px;
}

.quote-details__appnumber-format-modal_content {
    display: flex;
    flex-direction: column;
}
    .quote-details__appnumber-format-modal_content span {
        margin-bottom: 5px;
    }
    .quote-details__appnumber-format-modal_content-footer-note {
        margin-top: 15px;
    }

.settings-colapsible__body.services-technologies {
    position: relative;
}

.services_technologies-loader {
    top: 0;
    left: 0;
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 100%;
    background-color: rgb(249 249 249 / 69%);
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    border-radius: 10px 0 0 10px;
    display: flex;
    justify-content: center;
}

.services_technologies-loader__positioner {
    height: calc(50% + 20px);
    max-height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.new-quote-wizard__header {
    width: 100%;
    background-color: var(--clr-azami-blue-glass);
    border-radius: 20px;
    display: flex;
}

    .new-quote-wizard__nav-pill {
        color: var(--clr-azami-blurple);
        border-radius: 20px;
        padding: 10px 15px;
        width: 20%;
        display: flex;
        justify-content: center;
    }

        .new-quote-wizard__nav-pill-text-wrapper {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            pointer-events: none;
        }

        .new-quote-wizard__nav-pill-text-wrapper.current-active {
            font-weight: 500;
        }

        .new-quote-wizard__nav-pill-error-indicator {
            background-color: var(--clr-orange);
            height: 8px;
            width: 8px;
            min-width: 8px;
            border-radius: 50%;
            pointer-events: none;
            margin-left: 2px;
            visibility: hidden;
        }

        .new-quote-wizard__nav-pill-error-indicator.show-error {
            visibility: visible;
        }

        .new-quote-wizard__nav-pill-number {
            margin-right: 5px;
            background-color: var(--clr-white);
            border-radius: 50%;
            height: 18px;
            width: 18px;
            display: inline-flex;
            font-size: 14px;
            align-items: center; 
            justify-content: center;
            font-weight: 500;
        }

        .new-quote-wizard__nav-pill-number.active {
            background-color: var(--clr-azami-dark-blue);
            color: var(--clr-white);

        }

        .new-quote-wizard__nav-pill-number.current-active {
            background-color: var(--clr-white);
            color: var(--clr-azami-blurple);
        }

        .new-quote-wizard__nav-pill-active {
            color: var(--clr-white);
            background-color: var(--clr-azami-blurple);
        }

        .new-quote-wizard__nav-pill-active.no-radius-left {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        .new-quote-wizard__nav-pill-active.no-radius-right {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

    .new-quote-wizard__nav-pill:hover {
        cursor: pointer;
    }

.new-quote-wizard__content {
    padding: 20px;
    min-height: 50vh;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}

    .new-quote-wizard__content-section {
        margin-top: 15px;
        min-height: 100px;
        display: none;
    }

        .new-quote-wizard__content-section.show-section {
            display: block;
        }

        .new-quote-wizard__content-section-title {
            color: var(--clr-azami-blurple);
            padding: 10px 0;
            display: none;
        }

        .new-quote-wizard__content-section-title.show-section-title {
            display: block;
        }

        .new-quote-wizard__content-section-title.section-title-error {
            color: var(--clr-azami-violet);
        }

        .new-quote-wizard__content-section-services-technologies-container {
            display: flex;
        }

            .new-quote-wizard__content-section-services-technologies-container .quote-details__service-list li {
                margin: 0;
            }

            .new-quote-wizard__content-section-services-technologies-container .quote-details__service-list li label{
                margin: 10px 0 10px 0;
            }

        .new-quote-wizard__content-subsection.services{
            max-width: 55%;
        }

        .new-quote-wizard__content-subsection-title {
            font-size: 14px;
            font-weight: bold;
            margin: 20px 0;
        }

.new-quote-wizard__content-attachements-top {
    display: flex;
    width: 100% !important;
    margin-bottom: 30px;
}

    .new-quote-wizard__content-attachements-left {
        max-width: 50%;
        padding-right: 25px;
    }

        .new-quote-wizard__content-attachements-left-client-contact {
            display: flex;
            padding: 5px;
            gap: 10px;
            flex-wrap: wrap;
        }

            .new-quote-wizard__content-attachements-left-client-contact__input .form-input__label {
                text-transform: unset;
            }

        .new-quote-wizard__content-attachements-left-client-contact div {
            width: 40%;
        }

        .discount-rule-switch {
            margin-top: 10px;
            margin-bottom: 15px;
        }

    .new-quote-wizard__content-attachements-right {
        width: 50%;
        padding-left: 25px;
    }

.right-separator {
    border-right: 1px solid gray;
    margin-right: 20px;
}

.quote-details-view_quote-info {
    margin-bottom: 15px;
    display: flex;
    font-style: italic;
    font-size: 14px;
    gap: 15px;
}

    .quote-details-view_quote-info-container {
        display: flex;
        gap: 2px;
    }

        .quote-details-view_quote-info-icon {
            width: 16px;
            height: 16px;
        }


       